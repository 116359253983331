import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { REFRESH_ICON, CAMERA_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import MaterialTable from "material-table";
import Tooltip from '@material-ui/core/Tooltip'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Utils from "lib/utils";
import ShowToast from 'components/ShowToast';
import ReadingListTable from 'views/report/ReportComponent/ReadingListTable';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import GraphLoader from "components/GraphLoader";
import { LineChart, lineDataOne, LineChartFlowrate, LineChartTotalizer, lineDataTwo, lineDataThree, LineChartTwo, lineDataFour, LevellineChart, WrappedMap,PressurelineChart } from "lib/utils"
import { defaultFormatDateTime, validateBitStatusAlarms, validateIsoDateFormat, defaultFormatDate } from "lib/utils"

export class MeterReadingPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            meterData,
            metadata: {},
            meterSiteData: {},
            customerData: {},
            readingList: [],
            readingExcelList: [],
            readingRadarList: [],
            pressureExcelGenerateValue: "" ,
            pressureExcelReadings: [],
            pressureGraphList: [],
            totalizerGraphList: [],
            flowrateGraphList: [],
            readingTotalizerExcelList: [],
            readingFlowrateExcelList: [],
            onnileIconClass: "enable-icon",
            toggleClass: true,
            routeName: "",
            serialNumber: "",
            location: "",
            zone: "",
            subZone: "",
            meterApplicationType: null,
            startDate: moment().startOf('day').toDate(),
            endDate: moment().endOf('day').toDate(),
            dailyStartDate : moment().subtract(7, 'days').startOf('day').toDate(),
            dailyEndDate :moment().endOf('day').toDate(),
            weeklyStartDate : moment().subtract(7, 'weeks').startOf('day').toDate(),
            weeklyEndDate :moment().endOf('day').toDate(),
            monthlyStartDate : moment().subtract(7, 'months').startOf('day').toDate(),
            monthlyEndDate :moment().endOf('day').toDate(),
            maxEndDate: new Date(),
            isSyncVisible: true,
            totalizerGenerateValue: "",
            flowrateGenerateValue: "",
            graphGenerateValue: "",
            levelExcelGenerateValue: "",
            flowTotalizerGraphLoading: false,
            flowFlowrateGraphLoading: false,
            excelAndGraphGenerateOptionList: [
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Monthly", value: "monthly" },
                { label: "Custom", value: "custom" }
            ],
            totalizerDefaultGenerateValue: "daily",
            flowrateDefaultGenerateValue: "daily",
            pressureDefaultGenerateValue: "daily",
            levelGraphGenerateValue: "daily",
            levelGraphList: [],
            error: {}
        }

        this.toggleClassInterval = null;
        this.readingInterval = null;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const readingData = await this.getReadingData();
        this.toggleClassInterval = setInterval(() => {
            this.setState({
                toggleClass: !this.state.toggleClass,
                onnileIconClass: this.state.toggleClass ? "enable-icon" : "disable-icon"
            })
        }, 1000);
        if (this.state.meterData.pageName === "Flow Readings") {
            const totalizerGraphReadingsData = await this.getTotalizerGraphReadingsData();
            const flowrateGraphReadingsData = await this.getFlowrateGraphReadingsData()
            const transformedTotalizerData = totalizerGraphReadingsData.data.result.map(item => ({
                x: item.date,
                y: item.readings
            }));
            const transformedFlowrateData = flowrateGraphReadingsData.data.result.map(item => ({
                x: item.date,
                y: item.readings
            }));

            const totalizerlineData = [{
                x: transformedTotalizerData.map(d => d.x),
                y: transformedTotalizerData.map(d => d.y),
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: '#1f77b4' },
                line: { color: '#1f77b4' },
                borderRadius: '45%',
            }];
            const flowratelineData = [{
                x: transformedFlowrateData.map(d => d.x),
                y: transformedFlowrateData.map(d => d.y),
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: '#1f77b4' },
                line: { color: '#1f77b4' },
                borderRadius: '45%',
            }];
            this.setState({
                totalizerGraphList: totalizerlineData,
                flowrateGraphList: flowratelineData,
            })
        }
        if (this.state.meterData.pageName === "Pressure Readings") {
            const pressureGraphReadingsData = await this.getPressuerGraphReadingsData()
            console.log("pressureGraphReadingsData:",pressureGraphReadingsData)
         
            const transformedPressureData = pressureGraphReadingsData.data.result.map(item => {
                const date = moment(item.date); 
                return {
                    x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                    y: item.readings 
                };
            });

            const pressurelineData = [{
                x: transformedPressureData.map(d => d.x),
                y: transformedPressureData.map(d => d.y),
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: '#1f77b4' },
                line: { color: '#1f77b4' },
                borderRadius: '45%',
            }];
            this.setState({
                pressureGraphList: pressurelineData,
            })
        }
        if (this.state.meterData.pageName === "Level Readings") {
            const levelGraphReadingsList = await this.getLevelGraphReadings()
            const transformedLevelData = levelGraphReadingsList.data.result.map(item => ({
                x: item.date,
                y: item.readings
            }));
            const levellineData = [{
                x: transformedLevelData.map(d => d.x),
                y: transformedLevelData.map(d => d.y),
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: '#1f77b4' },
                line: { color: '#1f77b4' },
                borderRadius: '45%',
            }];

            this.setState({
                levelGraphList: levellineData,
            })
        }

        this.readingInterval = setInterval(async () => {
            const readingData = await this.getReadingData();
            this.setState({
                readingList: readingData.data.result.readingData,
                readingRadarList: readingData.data.result.readingRadarData ? readingData.data.result.readingRadarData : [],
                routeName: readingData.data.result.route_name,
                serialNumber: readingData.data.result.meter_serial_no,
                location: readingData.data.result.location ? readingData.data.result.location : "NA",
                zone: readingData.data.result.zone ? readingData.data.result.zone : "NA",
                subZone: readingData.data.result.subZone ? readingData.data.result.subZone : "NA",

            })
        }, 5000);

        this.setState({
            metadata: readingData.data.result,
            readingList: readingData.data.result.readingData,
            readingRadarList: readingData.data.result.readingRadarData ? readingData.data.result.readingRadarData : [],
            serialNumber: readingData.data.result.meter_serial_no,
            location: readingData.data.result.location ? readingData.data.result.location : "NA",
            zone: readingData.data.result.zone ? readingData.data.result.zone : "NA",
            subZone: readingData.data.result.subZone ? readingData.data.result.subZone : "NA",
            meterApplicationType: readingData.data.result.meter_application_type ? readingData.data.result.meter_application_type : "null",
            isLoading: false
        },() =>{
            console.log("readingList::",this.state.readingList)
        });
    }

    componentWillUnmount() {
        clearInterval(this.toggleClassInterval);
        clearInterval(this.readingInterval);
        this.toggleClassInterval = null;
        this.readingInterval = null;
    }

    getReadingData = async () => {
        if (this.state.meterData.pageName === "Meter") {
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}/${this.state.meterData.rowItem.route_id}`);
        }
        else if (this.state.meterData.pageName === "AMI Readings") {
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}`);
        }
        else if (this.state.meterData.pageName === "Flow Readings") {
            return await axios(`${API_BASE_URL}/flow/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}`);
        }
        else if (this.state.meterData.pageName === "Level Readings") {
            return await axios(`${API_BASE_URL}/level/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}`);
        }
        else if (this.state.meterData.pageName === "Pressure Readings") {
            return await axios(`${API_BASE_URL}/pressure/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}`);
        }
        else if (this.state.meterData.pageName1 === "Route Meter Mapping") {
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.routeItem.serial_number}/${this.state.meterData.routeItem.route_id}`);
        }
        else {
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.serial_number}`);
        }
    }

    getTotalizerGraphReadingsData = async () => {
        if (this.state.meterData.pageName === "Flow Readings") {
            return await axios(`${API_BASE_URL}/flow/readings/totalizer/graph/${this.state.meterData.rowItem.serial_number}/${this.state.totalizerDefaultGenerateValue}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`)
        }
    }

    getFlowrateGraphReadingsData = async () => {
        if (this.state.meterData.pageName === "Flow Readings") {
            return await axios(`${API_BASE_URL}/flow/readings/flowrate/graph/${this.state.meterData.rowItem.serial_number}/${this.state.flowrateDefaultGenerateValue}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`)
        }
    }

    getLevelGraphReadings = async () => {
        if (this.state.meterData.pageName === "Level Readings") {
            return await axios(`${API_BASE_URL}/level/readings/graph/${this.state.meterData.rowItem.serial_number}/${this.state.levelGraphGenerateValue}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
        }
    }

    getPressuerGraphReadingsData = async () => {
        if (this.state.meterData.pageName === "Pressure Readings") {
            return await axios(`${API_BASE_URL}/pressure/readings/daily/graph/${this.state.meterData.rowItem.serial_number}/${this.state.pressureDefaultGenerateValue}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`)
        }
    }


    renderStatus = (item) => {
        if (this.state.meterData.pageName === "Meter") {
            if (this.state.meterData.rowItem.serial_number === item) {
                return this.state.meterData.rowItem.status;
            }
        }
        else if (this.state.meterData.pageName1 === "Route Meter Mapping") {
            if (this.state.meterData.routeItem.serial_number === item) {
                return this.state.meterData.routeItem.status;
            }
        }
        else if (this.state.meterData.serial_number === item) {
            return this.state.meterData.status;
        }
    }

    routeMeterMapping = (item) => {
        const rowItem = {
            _id: item.routeItem.route_id,
            site_id: item.routeItem.site_id,
            route_name: this.state.routeName,
            street: item.routeItem.street,
            city: item.routeItem.city,
            pin_code: item.routeItem.pin_code,
        }
        if (this.props.history.location.state.pageName === "Route Master") {
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: { rowItem, isVisibleAddMeter: true, pageName: "Route Master", pathName: "master-route-page", pageName1: "Route Meter Mapping" }
            });
        }
        else if (this.props.history.location.state.pageName === "Route Transaction") {
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: { rowItem, isVisibleAddMeter: true, pageName: "Route Transaction", pathName: "route-transaction-page", pageName1: "Route Meter Mapping" }
            });
        }
        else if (this.props.history.location.state.pageName === "Route Collection History") {
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: { rowItem, isVisibleAddMeter: true, pageName: "Route History", pathName: "route-history", pageName1: "Route Meter Mapping" }
            });
        }
    }

    isFormTotalizerValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.totalizerGenerateValue) {
            isValid = false;
            error.totalizerGenerateValue = "Please select report generate date";
        }
        this.setState({
            error
        });

        return isValid;
    }

    isFormFlowrateValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.flowrateGenerateValue) {
            isValid = false;
            error.flowrateGenerateValue = "Please select report generate date";
        }
        this.setState({
            error
        });

        return isValid;
    }

    isFormLevelValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.levelExcelGenerateValue) {
            isValid = false;
            error.levelExcelGenerateValue = "Please select report generate date";
        }
        this.setState({
            error
        });

        return isValid;
    }

    isFormPressureValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.pressureExcelGenerateValue) {
            isValid = false;
            error.pressureExcelGenerateValue = "Please select report generate date";
        }
        this.setState({
            error
        });

        return isValid;
    }

    getTotalizerReportReadings = async () => {
        if (this.isFormTotalizerValid()) {
            try {
                this.setState({
                    readingTotalizerExcelList: [],
                    isSyncVisible: false,
                    flowTotalizerGraphLoading: true
                });
                let interval = 10000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                }
                else {
                    interval = 0;
                }

                setTimeout(async () => {
                    // Utils.showLoading("Fetching report data...");
                    const readingData = await axios(`${API_BASE_URL}/flow/readings/totalizer/${this.state.meterData.rowItem.serial_number}/${this.state.totalizerGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    const graphReadingsData = await axios(`${API_BASE_URL}/flow/readings/totalizer/graph/${this.state.meterData.rowItem.serial_number}/${this.state.totalizerGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    Utils.hideLoading();
                    if (readingData.data.result.length > 0) {
                        ShowToast(`Report created successfully ${this.state.metadata.meter_serial_no}`, TYPE_SUCCESS, INTERVAL, null, "");
                        var excelDownloadBtn = document.getElementById("download-excel-button-totalizer");
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }

                        const transformedData = graphReadingsData.data.result.map(item => {
                            const date = moment(item.date); 
                            return {
                                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                                y: item.readings 
                            };
                        });

                        const lineData = [{
                            x: transformedData.map(d => d.x), 
                            y: transformedData.map(d => d.y), 
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: '#1f77b4' },
                            line: { color: '#1f77b4' },
                            borderRadius: '45%',
                        }];
                        this.setState({
                            readingTotalizerExcelList: readingData.data.result,
                            totalizerGraphList: lineData,
                            isSyncVisible: true,
                            flowTotalizerGraphLoading: false
                        });

                    }
                    else {
                        ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
                    }
                }, interval);
            } catch (error) {
                ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    }
    getFlowrateReportReadings = async () => {
        if (this.isFormFlowrateValid()) {
            try {
                this.setState({
                    readingFlowrateExcelList: [],
                    isSyncVisible: false,
                    flowFlowrateGraphLoading: true,
                });
                let interval = 10000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                }
                else {
                    interval = 0;
                }

                setTimeout(async () => {
                    const readingData = await axios(`${API_BASE_URL}/flow/readings/flowrate/${this.state.meterData.rowItem.serial_number}/${this.state.flowrateGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    const graphReadingsData = await axios(`${API_BASE_URL}/flow/readings/flowrate/graph/${this.state.meterData.rowItem.serial_number}/${this.state.flowrateGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    Utils.hideLoading();
                    if (readingData.data.result.length > 0) {
                        ShowToast(`Report created successfully ${this.state.metadata.meter_serial_no}`, TYPE_SUCCESS, INTERVAL, null, "");
                        var excelDownloadBtn = document.getElementById("download-excel-button-flowrate");
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }

                        const transformedData = graphReadingsData.data.result.map(item => {
                            const date = moment(item.date); 
                            return {
                                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                                y: item.readings 
                            };
                        });

                       
                        const lineData = [{
                            x: transformedData.map(d => d.x), 
                            y: transformedData.map(d => d.y), 
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: '#1f77b4' },
                            line: { color: '#1f77b4' },
                            borderRadius: '45%',
                        }];
                        this.setState({
                            readingFlowrateExcelList: readingData.data.result,
                            flowrateGraphList: lineData,
                            isSyncVisible: true,
                            flowFlowrateGraphLoading: false
                        });

                    }
                    else {
                        ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
                    }
                }, interval);
            } catch (error) {
                ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    }

    totalizerGenerateChangeHandler = (event) => {
        this.setState({
            totalizerGenerateValue: { label: event.label, value: event.value },
        })
        var excelDownloadBtn = document.getElementById("download-excel-button-totalizer");
        excelDownloadBtn.classList.remove("mystyle");
    }

    flowrateGenerateChangeHandler = (event) => {
        this.setState({
            flowrateGenerateValue: { label: event.label, value: event.value },
        })
        var excelDownloadBtn = document.getElementById("download-excel-button-flowrate");
        excelDownloadBtn.classList.remove("mystyle");
    }

    levelGenerateChangeHandler = (event) => {
        this.setState({
            levelExcelGenerateValue: { label: event.label, value: event.value },
        })
    }

    pressureGenerateChangeHandler = (event) => {
        this.setState({
            pressureExcelGenerateValue: { label: event.label, value: event.value },
        })
    }

    getAMIReportReadings = async () => {
        try {
            this.setState({
                readingList: [],
                isSyncVisible: false
            });
            let interval = 5000;

            if (this.toggleClassInterval && this.toggleClassInterval) {
                Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                clearInterval(this.toggleClassInterval);
                clearInterval(this.readingInterval);
                this.toggleClassInterval = null;
                this.readingInterval = null;
            }
            else {
                interval = 0;
            }

            setTimeout(async () => {
                //  Utils.showLoading("Fetching report data...");
                const readingData = await axios(`${API_BASE_URL}/readings/ami/${this.state.meterData.rowItem.serial_number}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                Utils.hideLoading();
                if (readingData.data.result.length > 0) {
                    ShowToast(`Report created successfully ${this.state.metadata.meter_serial_no}`, TYPE_SUCCESS, INTERVAL, null, "");
                    var excelDownloadBtn = document.getElementById("download-excel-button");
                    if (excelDownloadBtn) {
                        excelDownloadBtn.classList.add("mystyle");
                    }
                    this.setState({
                        readingList: readingData.data.result
                    });
                }
                else {
                    ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
                }
            }, interval);
        } catch (error) {
            ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, "");
            Utils.hideLoading();
        }
    }

    exportToXLSXAmiReadings = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Web Receive Time",
                "Route",
                "Gateway",
                "Reader",
                "Unit",
                "Meter Status",
                "Alarm Type",
                "Meter Time",
                "Readings",
                "Photo",
                "RSSI",
                "SSR"
            ];

            const headerDataMapping = {
                "Serial Number": "meter_serial_no",
                "Web Receive Time": "created_date_time",
                "Route": "route_name",
                "Gateway": "gway_device_id",
                "Reader": "assigned_user_id",
                "Unit": "unit",
                "Meter Status": "meter_status",
                "Alarm Type": "alarm_type",
                "Meter Time": "receive_date_time",
                "Readings": "meter_reading",
                "Photo": "reading_image",
                "RSSI": "field_strength_rssi",
                "SSR": "snr"
            };

            const dateFormatArray = ["created_date_time"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.readingList.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `AMI-meter-report-${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
            var excelDownloadBtn = document.getElementById("download-excel-button");
            excelDownloadBtn.classList.remove("mystyle");
            this.setState({
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
            })
        } catch (error) {
            console.error(error);
        }
    };

    getlevelReportReadings = async () => {
        if (this.isFormLevelValid()) {
            try {
                this.setState({
                    levelExcelReadings: [],
                    isSyncVisible: false,
                });
                let interval = 10000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                } else {
                    interval = 0;
                }

                setTimeout(async () => {
                    Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                    const readinglevelData = await axios(`${API_BASE_URL}/level/readings/excel/${this.state.meterData.rowItem.serial_number}/${this.state.levelExcelGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    const graphReadingsData = await axios(`${API_BASE_URL}/level/readings/graph/${this.state.meterData.rowItem.serial_number}/${this.state.levelExcelGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    Utils.hideLoading();
                    if (readinglevelData.data.result.length > 0) {
                        ShowToast(
                            "Report created successfully",
                            TYPE_SUCCESS,
                            INTERVAL,
                            null,
                            ""
                        );
                        var excelDownloadBtn = document.getElementById(
                            "download-excel-button"
                        );
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }
                        const transformedLevelData = graphReadingsData.data.result.map(item => ({
                            x: item.date,
                            y: item.readings
                        }));
                        const levellineData = [{
                            x: transformedLevelData.map(d => d.x),
                            y: transformedLevelData.map(d => d.y),
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: '#1f77b4' },
                            line: { color: '#1f77b4' },
                            borderRadius: '45%',
                        }];
                        this.setState(
                            {
                                levelExcelReadings: readinglevelData.data.result,
                                levelGraphList: levellineData,
                            },
                            () => {
                                console.log(
                                    "levelExcelReadings::",
                                    this.state.levelExcelReadings
                                );
                            }
                        );
                    } else {
                        ShowToast(
                            "There is no data to fetch!",
                            TYPE_SUCCESS,
                            INTERVAL,
                            null,
                            ""
                        );
                    }
                }, interval);
            } catch (error) {
                ShowToast("Something went wrong.", TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    };


    getPressureReportReadings = async () => {
        if (this.isFormPressureValid()) {
            try {
                this.setState({
                    pressureExcelReadings: [],
                    pressureGraphList: [],
                    isSyncVisible: false,
                });
                let interval = 10000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                } else {
                    interval = 0;
                }

                setTimeout(async () => {
                    Utils.showLoading(`Fetching ${this.state.metadata.meter_serial_no} report data...`);
                    const readinglevelData = await axios(`${API_BASE_URL}/pressure/readings/excel/${this.state.meterData.rowItem.serial_number}/${this.state.pressureExcelGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    const graphReadingsData = await axios(`${API_BASE_URL}/pressure/readings/graph/${this.state.meterData.rowItem.serial_number}/${this.state.pressureExcelGenerateValue.value}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                    Utils.hideLoading();
                    if (readinglevelData.data.result.length > 0) {
                        ShowToast(
                            "Report created successfully",
                            TYPE_SUCCESS,
                            INTERVAL,
                            null,
                            ""
                        );
                        var excelDownloadBtn = document.getElementById(
                            "download-excel-button"
                        );
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }

                        const transformedPressureData = graphReadingsData.data.result.map(item => {
                            const date = moment(item.date); 
                            return {
                                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                                y: item.readings 
                            };
                        });

                        const pressurelineData = [{
                            x: transformedPressureData.map(d => d.x),
                            y: transformedPressureData.map(d => d.y),
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: '#1f77b4' },
                            line: { color: '#1f77b4' },
                            borderRadius: '45%',
                        }];
                        this.setState(
                            {
                                pressureExcelReadings: readinglevelData.data.result,
                                pressureGraphList: pressurelineData,
                            },
                            () => {
                                console.log(
                                    "PressureExcelReadings::",
                                    this.state.pressureExcelReadings
                                );
                            }
                        );
                    } else {
                        ShowToast(
                            "There is no data to fetch!",
                            TYPE_SUCCESS,
                            INTERVAL,
                            null,
                            ""
                        );
                        this.setState({pressureExcelGenerateValue:""})
                    }
                }, interval);
            } catch (error) {
                ShowToast("Something went wrong.", TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    };


    totalizerExportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Meter Date And Time",
                "Totalizer",
                "Flowrate",
                "Network Timestamp",
                "Status",
            ];

            const headerDataMapping = {
                "Serial Number": "serialNumber",
                "Meter Date And Time": "startTimeStamp",
                "Totalizer": "totalizer",
                "Flowrate": "flowRate",
                "Network Timestamp": "networkTimeStamp",
                "Status": "status",
            };

            const dateFormatArray = ["timeStamp","startTimeStamp","networkTimeStamp"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.readingTotalizerExcelList.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${this.state.meterData.pageName === "Flow Readings" ? "Flow" : "Readings"}-meter-readings-report-totalizer-${this.state.metadata.meter_serial_no}-${this.state.totalizerGenerateValue.value === "custom" ? `From ${defaultFormatDate(this.state.startDate)} To ${defaultFormatDate(this.state.endDate)}` : this.state.totalizerGenerateValue.value} ${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
            this.setState({ totalizerGenerateValue: "", onnileIconClass: "enable-icon" })
            const readingData = await this.getReadingData();
            this.setState({ readingList: readingData.data.result.readingData, totalizerGenerateValue: "" })
            var excelDownloadBtn = document.getElementById("download-excel-button-totalizer");
            excelDownloadBtn.classList.remove("mystyle");
        } catch (error) {
            console.error(error);
        }
    };
    flowrateExportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Meter Date And Time",
                "Totalizer",
                "Flowrate",
                "Network Timestamp",
                "Status",
            ];

            const headerDataMapping = {
                "Serial Number": "serialNumber",
                "Meter Date And Time": "startTimeStamp",
                "Totalizer": "totalizer",
                "Flowrate": "flowRate",
                "Network Timestamp": "networkTimeStamp",
                "Status": "status",
            };

            const dateFormatArray = ["timeStamp","startTimeStamp","networkTimeStamp"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.readingFlowrateExcelList.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${this.state.meterData.pageName === "Flow Readings" ? "Flow" : "Readings"}-meter-readings-report-flowrate-${this.state.metadata.meter_serial_no}-${this.state.flowrateGenerateValue.value === "custom" ? `From ${defaultFormatDate(this.state.startDate)} To ${defaultFormatDate(this.state.endDate)}` : this.state.flowrateGenerateValue.value} ${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
            this.setState({ flowrateGenerateValue: "", onnileIconClass: "enable-icon" })
            const readingData = await this.getReadingData();
            this.setState({ readingList: readingData.data.result.readingData, flowrateGenerateValue: ""})
            var excelDownloadBtn = document.getElementById("download-excel-button-flowrate");
            excelDownloadBtn.classList.remove("mystyle");
        } catch (error) {
            console.error(error);
        }
    };


    levelExportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Date and Time",
                "Level",
                "Status",
            ];

            const headerDataMapping = {
                "Serial Number": "meter_serial_no",
                "Date and Time": "created_date_time",
                "Level": "level_distance_reading",
                "Status": "High Flow",
            };

            const dateFormatArray = ["created_date_time"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.levelExcelReadings.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${this.state.meterData.pageName === "Level Readings" ? "Level" : "Readings"}-meter-readings-report-${this.state.metadata.meter_serial_no}-${this.state.levelExcelGenerateValue.value === "custom" ? `From ${defaultFormatDate(this.state.startDate)} To ${defaultFormatDate(this.state.endDate)}` : this.state.levelExcelGenerateValue.value} ${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
            this.setState({ totalizerGenerateValue: "", onnileIconClass: "enable-icon" })
            const readingData = await this.getReadingData();
            this.setState({ readingList: readingData.data.result.readingData, levelExcelGenerateValue: "", flowrateGraphList: "" })
            var excelDownloadBtn = document.getElementById("download-excel-button-flowrate");
            excelDownloadBtn.classList.remove("mystyle");
        } catch (error) {
            console.error(error);
        }
    };

    pressureExportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Meter Date and Time",
                "Pressure",
                "Network Date and Time",
                "Status",
            ];

            const headerDataMapping = {
                "Serial Number": "meter_serial_no",
                "Meter Date and Time": "start_time_stamp",
                "Pressure": "pressure_value",
                "Network Date and Time": "network_time_stamp",
                "Status": "High Flow",
            };

            const dateFormatArray = ["created_date_time","start_time_stamp","network_time_stamp"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.pressureExcelReadings.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${this.state.meterData.pageName === "Pressure Readings" ? "Pressure" : "Readings"}-meter-readings-report-${this.state.metadata.meter_serial_no}-${this.state.levelExcelGenerateValue.value === "custom" ? `From ${defaultFormatDate(this.state.startDate)} To ${defaultFormatDate(this.state.endDate)}` : this.state.pressureExcelGenerateValue.value} ${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
            this.setState({ totalizerGenerateValue: "", onnileIconClass: "enable-icon" })
            const readingData = await this.getReadingData();
            this.setState({ readingList: readingData.data.result.readingData, levelExcelGenerateValue: "", flowrateGraphList: "" })
            var excelDownloadBtn = document.getElementById("download-excel-button-flowrate");
            excelDownloadBtn.classList.remove("mystyle");
        } catch (error) {
            console.error(error);
        }
    };




    handleStartDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleEndDateChange = (date) => {
        this.setState({
            endDate: date
        })
    }

    viewImage = (image) => {
        if (image !== "" && image !== undefined) {
            this.setState({ viewImage: true, imgSrc: image })
        }
        else {
            ShowToast("Image not uploaded yet.", TYPE_FAIL, INTERVAL, null, "");
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            {
                                this.state.meterData.pageName === "Meter" ? (
                                    <>
                                        <li><a href={this.props.history.location.state.pathName}>{this.props.history.location.state.pageName}</a></li>
                                        <li>Meter Reading</li>
                                    </>
                                ) : (
                                    this.state.meterData.pageName === "Flow Readings" || this.state.meterData.pageName === "Pressure Readings" || this.state.meterData.pageName === "AMI Readings" || this.state.meterData.pageName === "Level Readings" ? (
                                        <>
                                            <li><a href={this.props.history.location.state.pathName} >{this.props.history.location.state.pageName}</a></li>
                                            <li>Meter Reading</li>
                                        </>
                                    ) : (
                                        <>
                                            <li><a href={this.props.history.location.state.pathName} >{this.props.history.location.state.pageName}</a></li>
                                            <li><a href={this.props.history.location.state.pathName1} onClick={() => this.routeMeterMapping(this.props.history.location.state)}>{this.props.history.location.state.pageName1}</a></li>
                                            <li>Meter Reading</li>
                                        </>
                                    )
                                )
                            }
                        </ul>
                    </div>
                    <div className="reading-info-outer border">
                        <div className="flow-image-meter-readings">
                            {this.state.meterData.pageName === "Flow Readings" ? (
                                <img src="/images/flow-meter.png" alt="Flow Meter" />
                            ) : this.state.meterData.pageName === "Level Readings" ? (
                                <img src="/images/level-meter.png" alt="Level Meter" />
                            ) : this.state.meterData.pageName === "Pressure Readings" ? (
                                <img src="/images/pressure-meter.png" alt="Pressure Meter" />
                             ): null}
                        </div>
                        <div className="reading-wrapper">
                            <div className="site-wrapper">
                                <div className="row">
                                    <div className="site-info">
                                        <div className="site-content">
                                            <label className="meter-data-header" >Meter Serial No :  </label>
                                            <label className="ml-3">{this.state.serialNumber}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label className="meter-data-header" >Meter Location :  </label>
                                            <label className="ml-3">{`${this.state.location}`}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="customer-wrapper">
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label className="meter-data-header" >Meter Zone Name :  </label>
                                            {
                                                this.state.meterData.pageName1 === "Route Meter Mapping" ? (
                                                    <label className="ml-3">{this.state.meterData.routeItem.customer_name != null ? this.state.meterData.routeItem.customer_name : "NA"}</label>
                                                ) : (
                                                    this.state.meterData.pageName === "Meter" ? (
                                                        <label className="ml-3">{this.state.meterData.rowItem.customer_name != null ? this.state.meterData.rowItem.customer_name : "NA"}</label>
                                                    ) : (
                                                        this.state.meterData.pageName === "Flow Readings" ||  this.state.meterData.pageName === "Pressure Readings" || this.state.meterData.pageName === "AMI Readings" || this.state.meterData.pageName === "Level Readings" ? (
                                                            <label className="ml-3">{this.state.zone}</label>
                                                        ) : (
                                                            <label className="ml-3">{"NA"}</label>
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label className="meter-data-header" >Meter Subzone Name :  </label>
                                            {
                                                this.state.meterData.pageName1 === "Route Meter Mapping" ? (
                                                    <label className="ml-3">{this.state.meterData.routeItem.customer_address1 !== null ? (`${this.state.meterData.routeItem.customer_address1}, ${this.state.meterData.routeItem.customer_address2},${this.state.meterData.routeItem.street}, ${this.state.meterData.routeItem.city}, ${this.state.meterData.routeItem.pin_code}`) : "NA"}</label>

                                                ) : (
                                                    this.state.meterData.pageName === "Meter" ? (
                                                        <label className="ml-3">{this.state.meterData.rowItem.customer_address1 !== null ? (`${this.state.meterData.rowItem.customer_address1}, ${this.state.meterData.rowItem.customer_address2}, ${this.state.meterData.rowItem.street}, ${this.state.meterData.rowItem.city}, ${this.state.meterData.rowItem.pin_code}`) : "NA"}</label>
                                                    ) : (
                                                        this.state.meterData.pageName === "Flow Readings"  ||  this.state.meterData.pageName === "Pressure Readings" || this.state.meterData.pageName === "AMI Readings" || this.state.meterData.pageName === "Level Readings" ? (
                                                            <label className="ml-3">{this.state.subZone}</label>
                                                        ) : (
                                                            <label className="ml-3">{"NA"}</label>
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div></div>
                        </div>
                        <div className="mr-5 sync-wrapper sync-responsive">
                            {
                                this.state.isSyncVisible ? (
                                    <>
                                        <div className="ml-3">
                                            <i className={`${REFRESH_ICON} ${this.state.onnileIconClass} text-center mr-4 rotate`} />
                                        </div>
                                        <label className={`${this.state.onnileIconClass}`}>Syncing</label>
                                    </>
                                ) : (
                                    <label className={`disable-icon`}>Pause...</label>
                                )
                            }

                        </div>
                    </div>
                    <div className="mt-4 border list-table-div" style={{ marginBottom: "10%" }}>
                        {
                            this.state.meterData.pageName === "AMI Readings" && (
                                <div>
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleStartDateChange}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.endDate}
                                                        onChange={this.handleEndDateChange}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addButton">
                                            <button id="button" className="brand-button report-button" onClick={this.getAMIReportReadings}>Get Report</button>
                                            <button
                                                id="download-excel-button"
                                                className="download-table-xls-button brandxls-button download-button"
                                                onClick={this.exportToXLSXAmiReadings}>Download as XLS
                                    </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.readingList.length > 0 || this.state.readingRadarList.length > 0 ? (
                                this.state.meterData.pageName === "Flow Readings" ||   this.state.meterData.pageName === "Pressure Readings" || this.state.meterData.pageName === "AMI Readings" || this.state.meterData.pageName === "Level Readings" ? (
                                    <ReadingListTable readingList={this.state.readingList} meterApplicationType={this.state.meterApplicationType} readingRadarList={this.state.readingRadarList} pageName={this.state.meterData.pageName} />
                                ) : (
                                    <MaterialTable
                                        columns={[
                                            // {
                                            //     title: "Serial Number", field: "meter_serial_no",
                                            //     cellStyle: { cellWidth: '18%', fontWeight: 'bolder', color: '#000000', fontSize: 14 }
                                            // },
                                            {
                                                title: "Route", field: "route_name1",
                                                cellStyle: { cellWidth: '20%', color: '#808080', fontSize: 14, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 },
                                                sorting: false, filtering: true
                                            },
                                            {
                                                title: "Aqualink Device Id", field: "sird_device_id",
                                                cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 },
                                                sorting: false
                                            },
                                            {
                                                title: "Reader", field: "assigned_user_id",
                                                cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Unit", field: "unit",
                                                cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Meter Status", field: "status",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Reading Status", field: "meter_status",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Alarm Type", field: "alarm_type1",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 }, filtering: true
                                            },
                                            {
                                                title: "Date", field: "receive_date_time",
                                                cellStyle: {
                                                    cellWidth: '14%', color: '#808080', fontSize: 14,
                                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100
                                                }
                                            },
                                            {
                                                title: "Reading", field: "meter_reading",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Photo", field: "photo",
                                                cellStyle: { cellWidth: '5%', color: '#808080', fontSize: 14 }, filtering: false
                                            }
                                        ]}
                                        data={
                                            this.state.readingList.map((item, index) => {
                                                let readingStatusClass = "unread";
                                                let meterStatusClass = "text-success";

                                                if (item.meter_status === "Manual") {
                                                    readingStatusClass = "manual";
                                                }
                                                else if (item.meter_status === "Auto+Alarm") {
                                                    readingStatusClass = "alarm";
                                                }
                                                else if (item.meter_status === "Auto Read") {
                                                    readingStatusClass = "read";
                                                }

                                                return (
                                                    {
                                                        meter_serial_no: item.meter_serial_no,
                                                        route_name1: <Tooltip placement="bottom-start" title={<span style={{ fontSize: "12px", color: "white" }}>{this.state.routeName} </span>}><span>{this.state.routeName}</span></Tooltip>,
                                                        route_name: this.state.routeName,
                                                        sird_device_id: item.sird_device_id,
                                                        assigned_user_id: item.assigned_user_id,
                                                        unit: <span style={{ textTransform: 'none' }}>{item.unit !== null ? (item.unit === "0" || item.unit === "m3" ? "m3" : item.unit === "1" || item.unit === "L" ? "L" : item.unit === "2" || item.unit === "GAL" ? "GAL" : item.unit === "3" || item.unit === "ft3" ? "ft3" : "NA") : "NA"}</span>,
                                                        status: <span className={`${meterStatusClass}`}>{this.renderStatus(item.meter_serial_no)}</span>,
                                                        meter_status: <span className={`${readingStatusClass}`}>{item.meter_status}</span>,
                                                        alarm_type1: <Tooltip placement="bottom-start" title={<span style={{ fontSize: "12px", color: "white" }}>{validateBitStatusAlarms(item.alarm_type)} </span>}><span>{validateBitStatusAlarms(item.alarm_type)}</span></Tooltip>,
                                                        alarm_type: item.alarm_type ? item.alarm_type : "None",
                                                        receive_date_time: <Tooltip placement="bottom-start" title={<span style={{ fontSize: "12px", color: "white" }}>{validateIsoDateFormat(item.receive_date_time) ? defaultFormatDateTime(item.receive_date_time) : item.receive_date_time} </span>}><span>{validateIsoDateFormat(item.receive_date_time) ? defaultFormatDateTime(item.receive_date_time) : item.receive_date_time}</span></Tooltip>,
                                                        meter_reading: item.meter_reading ? item.meter_reading : "NA",
                                                        _id: item._id,
                                                        photo: <i className={(item.reading_image !== "" && item.reading_image !== undefined) ? `${CAMERA_ICON} camera-icon` : `${CAMERA_ICON} gray-icon`} title="View Photo" onClick={() => this.viewImage(item.reading_image)}></i>
                                                    }
                                                )
                                            })
                                        }
                                        options={{
                                            actionsColumnIndex: -1,
                                            pageSize: 25,
                                            pageSizeOptions: [25, 50, 75, 100],
                                            headerStyle: {
                                                color: '#999998',
                                                fontWeight: 'bolder',
                                                fontSize: 15
                                            },
                                            filtering: true,
                                            sorting: true,
                                            exportButton: true,
                                            exportAllData: true,
                                            exportFileName: "Meter Reading Data"
                                        }}
                                    />
                                )
                            ) : (
                                <div className="text-center pt-2"> No Records Found!</div>
                            )
                        }
                        {
                            this.state.viewImage && (
                                <>
                                    <div className="custom_modal">
                                        <div className="modal_body">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h4 className="modal_title pagetitle">View Meter Image</h4>
                                                </div>
                                                <div className="col-md-2">
                                                    <button type="button" className="close text-right" style={{ fontSize: 20 }} onClick={(e) => this.setState({ viewImage: false })}>&times;</button>
                                                </div>
                                                {
                                                    (this.state.imgSrc.includes("png") || this.state.imgSrc.includes("jpg") || this.state.imgSrc.includes("jpeg")) ? (
                                                        <embed src={this.state.imgSrc} title="photo" width="100%" height="auto" />
                                                    ) : (
                                                        <embed style={{ height: '400px' }} src={this.state.imgSrc} title="photo" width="100%" height="auto" />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    {
                        this.state.meterData.pageName === "Flow Readings" && (
                            <div >
                                <div className="report-section"><span>READINGS REPORT AND GRAPH GENERATE</span></div>
                                <div className="totalizer-and-flowrate-header">
                                    <div className="report-section-one">TOTALIZER</div>
                                    <div className="report-section-two">FLOWRATE</div>
                                </div>

                                {/* Parent container for the two flex items */}
                                <div className="live-sync-icon-and-text-container totalizer-and-flowrate">
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div totalizer-date-div ">
                                        <div className="row">
                                            <div className="addUser ">
                                                <label className="form-label">Select Date <span className="text-danger">*</span></label>
                                                <Select
                                                    className="site-select-dropdown"
                                                    value={this.state.totalizerGenerateValue}
                                                    placeholder="Select Date"
                                                    options={this.state.excelAndGraphGenerateOptionList}
                                                    onChange={this.totalizerGenerateChangeHandler}
                                                    menuPlacement="bottom"
                                                />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.totalizerGenerateValue}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.totalizerGenerateValue.value === "daily" && (
                                                <div className="row">
                                                    <div className="addUser">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.dailyStartDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.dailyEndDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.totalizerGenerateValue.value === "weekly" && (
                                                <div className="row">
                                                    <div className="addUser">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.weeklyStartDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.weeklyEndDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.totalizerGenerateValue.value === "monthly" && (
                                                <div className="row">
                                                    <div className="addUser">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.monthlyStartDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.monthlyEndDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.totalizerGenerateValue.value === "custom" && (
                                                <div className="row">
                                                    <div className="addUser ">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.startDate}
                                                                onChange={this.handleStartDateChange}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser ">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.endDate}
                                                                onChange={this.handleEndDateChange}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="addButton">
                                            <button id="button" className="report-button-space" onClick={this.getTotalizerReportReadings}>GET REPORT</button>
                                            <button
                                                id="download-excel-button-totalizer"
                                                className="download-table-xls-button brandxls-button download-button"
                                                onClick={this.totalizerExportToXLSX}>Download as XLS
                                         </button>
                                        </div>
                                    </div>
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div flowrate-date-div">
                                        <div className="row">
                                            <div className="addUser ">
                                                <label className="form-label">Select Date <span className="text-danger">*</span></label>
                                                <Select
                                                    className="site-select-dropdown"
                                                    value={this.state.flowrateGenerateValue}
                                                    placeholder="Select Date"
                                                    options={this.state.excelAndGraphGenerateOptionList}
                                                    onChange={this.flowrateGenerateChangeHandler}
                                                    menuPlacement="bottom"
                                                />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.flowrateGenerateValue}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.flowrateGenerateValue.value === "daily" && (
                                                <div className="row">
                                                    <div className="addUser">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.dailyStartDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.dailyEndDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                          {
                                            this.state.flowrateGenerateValue.value === "weekly" && (
                                                <div className="row">
                                                    <div className="addUser">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.weeklyStartDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.weeklyEndDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                          {
                                            this.state.flowrateGenerateValue.value === "monthly" && (
                                                <div className="row">
                                                    <div className="addUser">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.monthlyStartDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.monthlyEndDate}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.flowrateGenerateValue.value === "custom" && (
                                                <div className="row">
                                                    <div className="addUser ">
                                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.startDate}
                                                                onChange={this.handleStartDateChange}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addUser ">
                                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                        <div className="calender-div">
                                                            <span className="icon fa-calendar" />
                                                            <DatePicker className="form-control brand-font p-3"
                                                                selected={this.state.endDate}
                                                                onChange={this.handleEndDateChange}
                                                                timeIntervals={1}
                                                                timeCaption="time"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={this.state.maxEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="addButton">
                                            <button id="button" className="report-button-space" onClick={this.getFlowrateReportReadings}>GET REPORT</button>
                                            <button
                                                id="download-excel-button-flowrate"
                                                className="download-table-xls-button brandxls-button download-button"
                                                onClick={this.flowrateExportToXLSX}>Download as XLS
                                         </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {this.state.meterData.pageName === "Level Readings" && (
                        <div>
                            <div className="report-section">
                                <span>READINGS REPORT AND GRAPH GENERATE</span>
                            </div>

                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">
                                            Select Date To Generate Excel And Graph{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            className="site-select-dropdown"
                                            value={this.state.levelExcelGenerateValue}
                                            placeholder="Select Date"
                                            options={this.state.excelAndGraphGenerateOptionList}
                                            onChange={this.levelGenerateChangeHandler}
                                            menuPlacement="bottom"
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.levelExcelGenerateValue}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.levelExcelGenerateValue.value === "daily" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.levelExcelGenerateValue.value === "weekly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.levelExcelGenerateValue.value === "monthly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {this.state.levelExcelGenerateValue.value === "custom" && (
                                    <div className="row">
                                        <div className="addUser">
                                            <label className="form-label">
                                                From Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker
                                                    className="form-control brand-font p-3"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="addUser">
                                            <label className="form-label">
                                                To Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker
                                                    className="form-control brand-font p-3"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="addButton">
                                    <button
                                        id="button"
                                        className="brand-button report-button"
                                        onClick={this.getlevelReportReadings}
                                    >
                                        Get Report
                              </button>
                                    <button
                                        id="download-excel-button"
                                        className="download-table-xls-button brandxls-button download-button"
                                        onClick={this.levelExportToXLSX}
                                    >
                                        Download as XLS
                              </button>
                                </div>
                            </div>
                        </div>
                    )}
                     {this.state.meterData.pageName === "Pressure Readings" && (
                        <div>
                            <div className="report-section">
                                <span>READINGS REPORT AND GRAPH GENERATE</span>
                            </div>

                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">
                                            Select Date To Generate Excel And Graph{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            className="site-select-dropdown"
                                            value={this.state.pressureExcelGenerateValue}
                                            placeholder="Select Date"
                                            options={this.state.excelAndGraphGenerateOptionList}
                                            onChange={this.pressureGenerateChangeHandler}
                                            menuPlacement="bottom"
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.pressureExcelGenerateValue}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.pressureExcelGenerateValue.value === "daily" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.pressureExcelGenerateValue.value === "weekly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.pressureExcelGenerateValue.value === "monthly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {this.state.pressureExcelGenerateValue.value === "custom" && (
                                    <div className="row">
                                        <div className="addUser">
                                            <label className="form-label">
                                                From Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker
                                                    className="form-control brand-font p-3"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="addUser">
                                            <label className="form-label">
                                                To Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker
                                                    className="form-control brand-font p-3"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="addButton">
                                    <button
                                        id="button"
                                        className="brand-button report-button"
                                        onClick={this.getPressureReportReadings}
                                    >
                                        Get Report
                              </button>
                                    <button
                                        id="download-excel-button"
                                        className="download-table-xls-button brandxls-button download-button"
                                        onClick={this.pressureExportToXLSX}
                                    >
                                        Download as XLS
                              </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div >
                        {
                            this.state.meterData.pageName === "Flow Readings" && (
                                <>
                                    <div className="live-sync-icon-and-text-container">
                                        <div className="totalizer-graph">
                                            <div className="line-graph-header">TOTALIZER</div>
                                            {this.state.flowTotalizerGraphLoading ? (
                                                <div className="line-chart-graph-height">
                                                <GraphLoader />
                                                </div>
                                              ) :(
                                            <LineChartTotalizer data={this.state.totalizerGraphList} />
                                            )}
                                        </div>
                                        <div className="flowrate-graph">
                                            <div className="line-graph-header">FLOWRATE</div>
                                            {this.state.flowFlowrateGraphLoading ? (
                                                <div className="line-chart-graph-height">
                                                <GraphLoader />
                                                </div>
                                              ) :(
                                            <LineChartFlowrate data={this.state.flowrateGraphList} />
                                            )}
                                        </div>
                                    </div>

                                </>
                            )}

                        {
                            this.state.meterData.pageName === "Level Readings" && (
                                <div className="live-sync-icon-and-text-container-level-meter-graph  ">
                                    <div className="line-graph-header">Level</div>
                                    <div className="totalizer-graph">
                                        <LevellineChart data={this.state.levelGraphList} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.meterData.pageName === "Pressure Readings" && (
                                <div className="live-sync-icon-and-text-container-level-meter-graph  ">
                                    <div className="line-graph-header">Pressure</div>
                                    <div className="totalizer-graph">
                                        <PressurelineChart data={this.state.pressureGraphList} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}

export default MeterReadingPage