import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { REFRESH_ICON, ZONE_SUBZONE_ICON, MAP_MARKER_ICON,EXCEL_DWN_ICON } from "assets/constants/Icons";
import Spinner from "components/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import moment from "moment";
import * as Utils from "lib/utils";
import DatePicker from "react-datepicker";
import ShowToast from "components/ShowToast";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { WrappedMap } from "lib/utils"
import { validateIsoDateFormat, defaultFormatDateTime, validateBitStatusAlarms,setPressureUnit171,getAlarmType171BPT,defaultFormatDate } from "lib/utils"

export class PressureMeterReadingPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: true,
            meterPressureReadings: [],
            zoneAndSubzoneList: [],
            zone: "",
            subZone: "",
            subzonedisabaled: true,
            cancelButtondisabaled: true,
            pressureUnitSelection: "",
            pressureUnitOptionList : [  { label: "Bar", value: "bar" },  { label: "Kg/Cm²", value: "kg/Cm²" }],
            liveSyncIconClass: "enable-icon",
            excelAndGraphGenerateOptionList: [
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Monthly", value: "monthly" },
                { label: "Custom", value: "custom" },
            ],
            startDate: moment().startOf("day").toDate(),
            endDate: moment().endOf('day').toDate(),
            excelGenerateValue: "",
            dailyStartDate: moment().subtract(7, 'days').startOf('day').toDate(),
            dailyEndDate: moment().endOf('day').toDate(),
            weeklyStartDate: moment().subtract(7, 'weeks').startOf('day').toDate(),
            weeklyEndDate: moment().endOf('day').toDate(),
            monthlyStartDate: moment().subtract(7, 'months').startOf('day').toDate(),
            monthlyEndDate: moment().endOf('day').toDate(),
            maxEndDate: new Date(),
            error: {},
            pressureExcelReadings: [],
            pressureAllMeterData: [],
            toggleLiveSyncIconClass: true
        }

        this.liveSyncIconClassToggleInterval = null;
        this.meterReadingsGetInterval = null;
        this.toggleClassInterval = null;
    }


    arrangePressureReadingList = (array) => {
        const pressureMeterListArray = [...this.state.meterPressureReadings];
        if (Array.isArray(array)) {
            array.forEach((item) => {
                const index = pressureMeterListArray.findIndex(findeItem => findeItem.meter_serial_no === item.meter_serial_no);
                if (index === -1) {
                    pressureMeterListArray.push(item)
                }
                else {
                    pressureMeterListArray[index].level_distance_reading = item.meter_reading;
                    pressureMeterListArray[index].meter_status = item.meter_status;
                    pressureMeterListArray[index].receive_date_time = item.receive_date_time;
                    pressureMeterListArray[index].alarm_type = item.alarm_type;
                    pressureMeterListArray[index].created_date_time = item.created_date_time;
                    pressureMeterListArray[index].updated_date_time = item.updated_date_time;
                }
            });
            return pressureMeterListArray;
        }
        else {
            return array;
        }
    }

    async componentDidMount() {
        const meterPressureReadings = await this.getMeterPressureReadings();
        console.log("meterPressureReadings::",meterPressureReadings)
        const zoneAndSubzoneList = await this.getZoneAndSubzoneList();
        const pressureAllMeterData = await this.getAllPressureMeters();
        const updatedPressureList = this.arrangePressureReadingList(meterPressureReadings.data.result)
        this.liveSyncIconClassToggleInterval = setInterval( () => {
            this.setState({
                toggleLiveSyncIconClass: !this.state.toggleLiveSyncIconClass,
                liveSyncIconClass: this.state.toggleLiveSyncIconClass ? "enable-icon" : "disable-icon"
            });
        }, 1000);

        this.meterReadingsGetInterval = setInterval( async () => {
            const meterPressureReadings = await this.getMeterPressureReadings();
            const updatedPressureList = this.arrangePressureReadingList(meterPressureReadings.data.result)
            this.setState({
                meterPressureReadings: updatedPressureList
            });
        }, 5000);

        this.setState({
            meterPressureReadings: updatedPressureList,
            zoneAndSubzoneList: zoneAndSubzoneList.data.result,
            pressureAllMeterData: pressureAllMeterData.data.result,
            isLoading: false
        });
    }

    componentWillUnmount() {
        clearInterval(this.liveSyncIconClassToggleInterval);
        clearInterval(this.meterReadingsGetInterval);
    }

    getMeterPressureReadings = async () => {
        return await axios.get(`${API_BASE_URL}/pressure/readings/${sessionStorage.getItem('siteId')}`);
    }

    getZoneAndSubzoneList = async () => {
        return await axios.get(
            `${API_BASE_URL}/zone/subzone/${sessionStorage.getItem("siteId")}`
        );
    };

    getAllPressureMeters = async () => {
        return await axios(`${API_BASE_URL}/pressure/meter/${sessionStorage.getItem("siteId")}`);
    }  

    zonesList = (zoneList) => {
        return zoneList.map((item) => {
            return { label: item.zone_name, value: item.zone_name };
        });
    };

    subZonesList = (zoneList, zone) => {
        const subZones = zoneList
            .filter((item) => {
                return this.state.zone.value === item.zone_name;
            })
            .flatMap((item) => {
                return item.sub_zone_name.map((subZone) => ({
                    label: subZone,
                    value: subZone,
                }));
            });
        return subZones;
    };
    subZoneChangeHandler = (event) => {
        this.setState({
            subZone: { label: event.label, value: event.value },
            cancelButtondisabaled: false,
        });
    };
    zoneChangeHandler = (event) => {
        this.setState({
            zone: { label: event.label, value: event.value },
            subZone: "",
            subzonedisabaled: false,
            cancelButtondisabaled: false,
        });
    };

    handleMenuOpen = () => {
        this.setState({ isDropDown: true });
    };

    handleMenuClose = () => {
        this.setState({ isDropDown: false });
    };

    filterMeterReadings = () => {
        const { meterPressureReadings, zone, subZone } = this.state;
        if (zone || subZone) {
            return meterPressureReadings.filter((reading) => {
                const matchesZone = zone ? reading.meterData.zone === zone.value : true;
                const matchesSubZone = subZone ? reading.meterData.subZone === subZone.value : true;
                return matchesZone && matchesSubZone;
            });
        }
        return meterPressureReadings;
    };

    cancelHandler = (event) => {
        this.setState({
            zone: "",
            subZone: "",
            subzonedisabaled: true,
            cancelButtondisabaled: true,
        });
    };

    showMeterReadingHandler = (item) => {
        if(item){
            const rowItem = {
                serial_number:item.meter_serial_no,
                ...item
            }
            this.props.history.push({
                pathname: "/meter-reading",
                state: {rowItem, pageName:"Pressure Readings", pathName: "pressure-readings-page"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/meter-reading",
                state: null
            });
        }
    }

    handleStartDateChange = (date) => {
        this.setState({
            startDate: date,
        });
    };
    handleEndDateChange = (date) => {
        this.setState({
            endDate: date,
        });
    };

    isFormExcelValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.excelGenerateValue) {
            isValid = false;
            error.excelGenerateValue = "Please select date";
        }

        this.setState({
            error,
        });

        return isValid;
    };

    getPressureReportReadings = async () => {
        if (this.isFormExcelValid()) {
            try {
                this.setState({
                    pressureExcelReadings: [],
                    isSyncVisible: false,
                });
                let interval = 100000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading("Fetching all level meters report data...");
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                } else {
                    interval = 0;
                }

                setTimeout(async () => {
                    Utils.showLoading("Fetching all pressure meters report data...");
                    const readingPressureData = await axios(`${API_BASE_URL}/pressure/excel/readings/${sessionStorage.getItem("siteId")}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`);
                    console.log("readingPressureData::",readingPressureData)
                    Utils.hideLoading();
                    if (readingPressureData.data.result.length > 0) {
                        ShowToast("Report created successfully", TYPE_SUCCESS, INTERVAL, null, "");
                        var excelDownloadBtn = document.getElementById("download-excel-button");
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }
                        this.setState(
                            {
                                pressureExcelReadings: readingPressureData.data.result,
                            },
                            () => {
                                console.log( "pressureExcelReadings::", this.state.pressureExcelReadings);
                            }
                        );
                    } else {
                        ShowToast("There is no data to fetch!", TYPE_SUCCESS, INTERVAL, null, "");
                        this.setState({excelGenerateValue:""})
                    }
                }, interval);
            } catch (error) {
                ShowToast("Something went wrong.", TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    };

    excelGenerateChangeHandler = (event) => {
        this.setState({
            excelGenerateValue: { label: event.label, value: event.value },
        });
    };

        exportToXLSX = async () => {
            try {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet("Report Data");
    
                const headersToUse = [
                    "Serial Number",
                    "Zone",
                    "Subzone",
                    "Location",
                    "Pressure",
                    "Time Stamp",
                    "Status",
                ];
    
                const headerDataMapping = {
                    "Serial Number": "meter_serial_no",
                    "Zone": "zone",
                    "Subzone": "subZone",
                    "Location": "location",
                    "Pressure": "pressure_value",
                    "Time Stamp": "created_date_time",
                    "Status": "alarm_type",
                };
    
                const dateFormatArray = ["created_date_time"];
                function unitConversion(value) {
                    if (value === "0" || value === "m3") {
                        return "m3";
                    } else if (value === "1" || value === "L") {
                        return "L";
                    } else if (value === "2" || value === "GAL") {
                        return "GAL";
                    } else if (value === "3" || value === "ft3") {
                        return "ft3";
                    } else {
                        return "NA";
                    }
                }
    
                const headerRow = worksheet.addRow(headersToUse);
                headerRow.eachCell((cell) => {
                    cell.font = { bold: true, color: "red" };
                });
    
                this.state.pressureExcelReadings.forEach((row) => {
                    const rowData = headersToUse.map((header) => {
                        const dataProperty = headerDataMapping[header];
                        if (dateFormatArray.includes(dataProperty)) {
                            const formattedDate = defaultFormatDateTime(row[dataProperty]);
                            return formattedDate !== "Invalid date" ? formattedDate : "NA";
                        } else if (dataProperty === "unit") {
                            return unitConversion(row[dataProperty]);
                        } else {
                            return row[dataProperty] || "NA";
                        }
                    });
                    worksheet.addRow(rowData);
                });
    
                const excelBlob = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([excelBlob], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                    `Pressure-meter-readings-report-${this.state.excelGenerateValue.value === "custom"
                        ? `From ${defaultFormatDate(
                            this.state.startDate
                        )} To ${defaultFormatDate(this.state.endDate)}`
                        : this.state.excelGenerateValue.value
                    } ${moment().format("DD-MM-YYYY hh:mm:ss a")}`
                );
                this.setState({ excelGenerateValue: "" });
                var excelDownloadBtn = document.getElementById("download-excel-button");
                excelDownloadBtn.classList.remove("mystyle");
            } catch (error) {
                console.error(error);
            }
        };


    render () {
        const { zoneAndSubzoneList } = this.state;
        if (this.state.isLoading) {
            return (
                <Spinner />
            );
        }
        else {
            const filteredlevelMeterReadings = this.filterMeterReadings();
            return (
                <div className="main-content">
                   <div className="live-sync-icon-and-text-container">
                        <div className="level-image">
                            <img src="/images/pressure-meter.png" alt="Pressure Meter" />
                        </div>

                        <div className="level-heading">PRESSURE READINGS</div>
                        <div className="live-sync-info">
                            <i
                                className={`${REFRESH_ICON} ${this.state.liveSyncIconClass} rotate`}
                            ></i>
                            <label className={`${this.state.liveSyncIconClass}`}>
                                Syncing...
                            </label>
                        </div>
                    </div>

                    <div
                        className="live-sync-icon-after-text-container filter-changes"
                        style={{
                            paddingBottom: this.state.isDropDown === true ? "13%" : "2%",
                        }}
                    >
                        {/* <label className="form-label"> ZONE  <span className="text-danger">*</span> </label> */}
                        <div className="zone-select-container">
                            <Select
                                className="site-select-dropdown"
                                value={this.state.pressureUnitSelection}
                                placeholder="Select Pressure Unit"
                                options={this.state.pressureUnitOptionList}
                                onChange={this.pressureUnitChangeHandler}
                                onMenuOpen={this.handleMenuOpen}
                                onMenuClose={this.handleMenuClose}
                                menuPlacement="bottom"
                            />
                        </div>
                        {/* <label className="form-label"> ZONE  <span className="text-danger">*</span> </label> */}
                        <div className="zone-select-container">
                            <Select
                                className="site-select-dropdown"
                                value={this.state.zone}
                                placeholder="Select Zone"
                                options={this.zonesList(zoneAndSubzoneList)}
                                onChange={this.zoneChangeHandler}
                                onMenuOpen={this.handleMenuOpen}
                                onMenuClose={this.handleMenuClose}
                                menuPlacement="bottom"
                            />
                        </div>
                        {/* <label className="form-label">
                                SUBZONE  <span className="text-danger">*</span>
                            </label> */}

                        <div className="subzone-select-container">
                            <Select
                                className="site-select-dropdown"
                                value={this.state.subZone}
                                placeholder="Select Subzone"
                                options={this.subZonesList(zoneAndSubzoneList,this.state.zone )}
                                onChange={this.subZoneChangeHandler}
                                isDisabled={this.state.subzonedisabaled}
                                onMenuOpen={this.handleMenuOpen}
                                onMenuClose={this.handleMenuClose}
                            />
                        </div>

                        <button className="brand-button report-button" disabled={this.state.cancelButtondisabaled} onClick={this.cancelHandler}>
                            CLEAR
                        </button>
                    </div>
                    
                    <div className="border list-table-div" style={{marginBottom:50}}>
                        {filteredlevelMeterReadings.length > 0 ? (
                            <MaterialTable
                                columns={[
                                    // {
                                    //     title: "Sn",
                                    //     field: "sn",
                                    //     cellStyle: { color: "#0070C0", fontSize: 14 },
                                    // },
                                    {
                                        title: "Zone",
                                        field: "zone",
                                        cellStyle: { color: "#808080", fontSize: 14 },
                                    },
                                    {
                                        title: "Subzone",
                                        field: "subzone",
                                        cellStyle: { color: "#808080", fontSize: 14 },
                                    },
                                    {
                                        title: "Location",
                                        field: "location",
                                        cellStyle: { color: "#808080", fontSize: 14 },
                                    },
                                    {
                                        title: "Serial Number",
                                        field: "meter_serial_no",
                                        cellStyle: { color: "#000000", fontSize: 14 },
                                    },
                                    {
                                        title: "Pressure",
                                        field: "pressure",
                                        cellStyle: { color: "#000000", fontSize: 14 },
                                    },
                                    {
                                        title: "Time Stamp",
                                        field: "receive_date_time",
                                        cellStyle: { color: "#808080", fontSize: 14 },
                                    },
                                    {
                                        title: "Status",
                                        field: "status",
                                        cellStyle: { color: "#808080", fontSize: 14 },
                                    },
                                ]}
                                data={[
                                    ...filteredlevelMeterReadings.map((element, index) => {
                                        return {
                                            sn: index + 1,
                                            zone: element.meterData.zone,
                                            subzone: element.meterData.subZone,
                                            location: element.meterData.location,
                                            meter_serial_no: element.meter_serial_no,
                                            pressure: setPressureUnit171(element.pressure_value, element.pressure_unit),
                                            receive_date_time: validateIsoDateFormat(
                                                element.created_date_time
                                            )
                                                ? defaultFormatDateTime(element.created_date_time)
                                                : element.created_date_time,
                                            status: element.alarm_type ?  getAlarmType171BPT(element.alarm_typ) :"NA",
                                        };
                                    }),
                                ]}
                                onRowClick={(event, rowData) => {
                                    this.showMeterReadingHandler(rowData);
                                }}
                                options={{
                                    headerStyle: {
                                        color: "#0070C0",
                                        fontWeight: "bold",
                                        fontSize: 15,
                                    },
                                    columnsButton: true,
                                    filtering: true,
                                    pageSize: 5,
                                    pageSizeOptions: [25, 50, 75, 100],
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: "Reading Data",
                                }}
                            />
                        ) : (
                            <div className="text-center pt-2">
                                <label>Records not found!</label>
                            </div>
                        )}
                    </div>
                    <div className="report-section">
                        <i className={`${EXCEL_DWN_ICON} icon-space`}></i>{" "}
                        <span>READINGS REPORT GENERATE</span>
                    </div>
                    <div>
                        <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                            <div className="row">
                                <div className="addUser">
                                    <label className="form-label">
                                        Select Date To Generate Excel 
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        className="site-select-dropdown"
                                        value={this.state.excelGenerateValue}
                                        placeholder="Select Date"
                                        options={this.state.excelAndGraphGenerateOptionList}
                                        onChange={this.excelGenerateChangeHandler}
                                        menuPlacement="bottom"
                                    />
                                    <div className="text-danger error-msg">
                                        {this.state.error.excelGenerateValue}
                                    </div>
                                </div>
                            </div>
                            {
                                    this.state.excelGenerateValue.value === "daily" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                  {
                                    this.state.excelGenerateValue.value === "weekly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                  {
                                    this.state.excelGenerateValue.value === "monthly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            {this.state.excelGenerateValue.value === "custom" && (
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">
                                            From Date <span className="text-danger">*</span>
                                        </label>
                                        <div className="calender-div">
                                            <span className="icon fa-calendar" />
                                            <DatePicker
                                                className="form-control brand-font p-3"
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDateChange}
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxEndDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="addUser">
                                        <label className="form-label">
                                            To Date <span className="text-danger">*</span>
                                        </label>
                                        <div className="calender-div">
                                            <span className="icon fa-calendar" />
                                            <DatePicker
                                                className="form-control brand-font p-3"
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDateChange}
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxEndDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="addButton">
                                <button
                                    id="button"
                                    className="brand-button report-button"
                                    onClick={this.getPressureReportReadings}
                                >
                                    Get Report
                                </button>
                                <button
                                    id="download-excel-button"
                                    className="download-table-xls-button brandxls-button download-button"
                                    onClick={this.exportToXLSX}
                                >
                                    Download as XLS
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="report-section"><i className={`${MAP_MARKER_ICON} icon-space`}></i><span>METERS MAP VIEW</span></div>
                        <div>
                            <WrappedMap meterMapReadings={this.state.pressureAllMeterData} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default PressureMeterReadingPage;