import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL, } from "assets/constants/Constants";
import { REFRESH_ICON, ZONE_SUBZONE_ICON, MAP_MARKER_ICON } from "assets/constants/Icons";
import Spinner from "components/Spinner";
import moment from "moment";
import * as Utils from "lib/utils";
import ShowToast from "components/ShowToast";
import MaterialTable from "material-table";
import Select from "react-select";
import { LineChart, lineDataOne, WrappedMap } from "lib/utils";
import DatePicker from "react-datepicker";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { validateIsoDateFormat, defaultFormatDateTime, validateBitStatusAlarms, defaultFormatDate, LevellineChart } from "lib/utils";

export class LevelMeterReadingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            meterLevelReadings: [],
            zone: "",
            subZone: "",
            subzonedisabaled: true,
            cancelButtondisabaled: true,
            startDate: moment().startOf("day").toDate(),
            excelGenerateValue: "",
            endDate: moment().endOf("day").toDate(),
            maxEndDate: new Date(),
            excelAndGraphGenerateOptionList: [
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Monthly", value: "monthly" },
                { label: "Custom", value: "custom" },
            ],
            levelAndRadarOptionList: [
                { label: "Level", value: "level" },
                { label: "Distance", value: "distance" },
            ],
            levelDistanceSelect: { label: "Level", value: "level" },
            error: {},
            graphGenerateValue: "daily",
            levelGraphReadingList: [],
            zoneAndSubzoneList: [],
            liveSyncIconClass: "enable-icon",
            toggleLiveSyncIconClass: true,
            levelAllMetersData: [],
            selectedOption: 'level',
        };
        this.mainContentRef = React.createRef();
        this.liveSyncIconClassToggleInterval = null;
        this.meterReadingsGetInterval = null;
    }

    arrangeLevelReadingList = (array) => {
        const levelMeterListArray = [...this.state.meterLevelReadings];
        if (Array.isArray(array)) {
            array.forEach((item) => {
                const index = levelMeterListArray.findIndex(
                    (findeItem) => findeItem.meter_serial_no === item.meter_serial_no
                );
                if (index === -1) {
                    levelMeterListArray.push(item);
                } else {
                    levelMeterListArray[index].level_distance_reading =
                        item.level_distance_reading;
                    levelMeterListArray[index].meter_status = item.meter_status;
                    levelMeterListArray[index].receive_date_time =
                        item.network_time_stamp;
                    levelMeterListArray[index].alarm_type = item.alarm_type;
                    levelMeterListArray[index].created_date_time = item.created_date_time;
                    levelMeterListArray[index].updated_date_time = item.updated_date_time;
                }
            });
            return levelMeterListArray;
        } else {
            return array;
        }
    };
    zonesList = (zoneList) => {
        return zoneList.map((item) => {
            return { label: item.zone_name, value: item.zone_name };
        });
    };

    subZonesList = (zoneList, zone) => {
        const subZones = zoneList
            .filter((item) => {
                return this.state.zone.value === item.zone_name;
            })
            .flatMap((item) => {
                return item.sub_zone_name.map((subZone) => ({
                    label: subZone,
                    value: subZone,
                }));
            });
        return subZones;
    };
    subZoneChangeHandler = (event) => {
        this.setState({
            subZone: { label: event.label, value: event.value },
            cancelButtondisabaled: false,
        });
    };
    zoneChangeHandler = (event) => {
        this.setState({
            zone: { label: event.label, value: event.value },
            subZone: "",
            subzonedisabaled: false,
            cancelButtondisabaled: false,
        });
    };
    handleStartDateChange = (date) => {
        this.setState({
            startDate: date,
        });
    };
    handleEndDateChange = (date) => {
        this.setState({
            endDate: date,
        });
    };

    levelAndRadarChangeHandler = (event) => {
        this.setState({
            levelDistanceSelect: { label: event.label, value: event.value },
        })
    }

    cancelHandler = (event) => {
        this.setState({
            zone: "",
            subZone: "",
            subzonedisabaled: true,
            cancelButtondisabaled: true,
        });
    };
    excelGenerateChangeHandler = (event) => {
        this.setState({
            excelGenerateValue: { label: event.label, value: event.value },
        });
    };
    isFormExcelValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.excelGenerateValue) {
            isValid = false;
            error.excelGenerateValue = "Please select date";
        }

        this.setState({
            error,
        });

        return isValid;
    };
    getlevelReportReadings = async () => {
        if (this.isFormExcelValid()) {
            try {
                this.setState({
                    levelExcelReadings: [],
                    isSyncVisible: false,
                });
                let interval = 100000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading("Fetching all level meters report data...");
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                } else {
                    interval = 0;
                }

                setTimeout(async () => {
                    Utils.showLoading("Fetching all level meters report data...");
                    const readinglevelData = await axios(`${API_BASE_URL}/level/excel/readings/${sessionStorage.getItem("siteId")}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`);
                    const readingGraphlevelData = await axios(`${API_BASE_URL}/level/graph/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`)
                    Utils.hideLoading();
                    if (readinglevelData.data.result.length > 0) {
                        ShowToast("Report created successfully", TYPE_SUCCESS, INTERVAL, null, "");
                        var excelDownloadBtn = document.getElementById("download-excel-button");
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }
                        const transformedLevelGraphData = readingGraphlevelData.data.result.map(item => {
                            const reading = parseFloat(item.readings);
                            return {
                                x: item.date,
                                y: isNaN(reading) ? 0 : reading.toFixed(2)
                            };
                        });
                
                        // Prepare the data for Plotly
                        const levelLineData = [{
                            x: transformedLevelGraphData.map(d => d.x), // Extract x values
                            y: transformedLevelGraphData.map(d => d.y), // Extract y values
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: '#1f77b4' },
                            line: { color: '#1f77b4' },
                            borderRadius: '45%',
                        }];
                        this.setState(
                            {
                                levelExcelReadings: readinglevelData.data.result,
                                levelGraphReadingList: levelLineData
                            },
                            () => {
                                console.log( "levelExcelReadings::", this.state.levelExcelReadings);
                            }
                        );
                    } else {
                        ShowToast(
                            "There is no data to fetch!", TYPE_SUCCESS, INTERVAL, null, "");
                    }
                }, interval);
            } catch (error) {
                ShowToast("Something went wrong.", TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    };
    async componentDidMount() {
        const meterLevelReadings = await this.getMeterLevelReadings();
        const zoneAndSubzoneList = await this.getZoneAndSubzoneList();
        const levelGraphRedaingsList = await this.getLevelRedaingsGraphDataList();
        const levelAllMetersData = await this.getAllLevelMeters()
        const updatedLevelList = this.arrangeLevelReadingList(
            meterLevelReadings.data.result
        );
        this.liveSyncIconClassToggleInterval = setInterval(() => {
            this.setState({
                toggleLiveSyncIconClass: !this.state.toggleLiveSyncIconClass,
                zoneAndSubzoneList: zoneAndSubzoneList.data.result,
                liveSyncIconClass: this.state.toggleLiveSyncIconClass
                    ? "enable-icon"
                    : "disable-icon",
            });
        }, 1000);

        this.meterReadingsGetInterval = setInterval(async () => {
            const meterLevelReadings = await this.getMeterLevelReadings();
            const updatedLevelList = this.arrangeLevelReadingList(
                meterLevelReadings.data.result
            );
            this.setState({
                meterLevelReadings: updatedLevelList,
            });
        }, 5000);
        const transformedLevelGraphData = levelGraphRedaingsList.data.result.map(item => {
            const reading = parseFloat(item.readings);
            return {
                x: item.date,
                y: isNaN(reading) ? 0 : reading.toFixed(2)
            };
        });

        // Prepare the data for Plotly
        const levelLineData = [{
            x: transformedLevelGraphData.map(d => d.x), // Extract x values
            y: transformedLevelGraphData.map(d => d.y), // Extract y values
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: '#1f77b4' },
            line: { color: '#1f77b4' },
            borderRadius: '45%',
        }];

        this.setState({
            meterLevelReadings: updatedLevelList,
            levelGraphReadingList: levelLineData,
            levelAllMetersData: levelAllMetersData.data.result,
            isLoading: false,
        });
    }

    componentWillUnmount() {
        clearInterval(this.liveSyncIconClassToggleInterval);
        clearInterval(this.meterReadingsGetInterval);
    }

    handleMenuOpen = () => {
        this.setState({ isDropDown: true });
    };
    handleMenuClose = () => {
        this.setState({ isDropDown: false });
    };

    getAllLevelMeters = async () => {
        return await axios(`${API_BASE_URL}/level/meter/${sessionStorage.getItem("siteId")}`);
    }  

    getLevelRedaingsGraphDataList = async () => {
        return await axios(`${API_BASE_URL}/level/graph/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.graphGenerateValue}`)
    }

    filterMeterReadings = () => {
        const { meterLevelReadings, zone, subZone } = this.state;
        if (zone || subZone) {
            return meterLevelReadings.filter((reading) => {
                const matchesZone = zone ? reading.meterData.zone === zone.value : true;
                const matchesSubZone = subZone ? reading.meterData.subZone === subZone.value : true;
                return matchesZone && matchesSubZone;
            });
        }
        return meterLevelReadings;
    };
    scrollToTop = () => {
        this.mainContentRef.current.scrollIntoView({ behavior: "smooth" });
    };

    showMeterReadingHandler = (item) => {
        if (item) {
            const rowItem = {
                serial_number: item.meter_serial_no,
                ...item,
            };
            this.props.history.push({
                pathname: "/meter-reading",
                state: {
                    rowItem,
                    pageName: "Level Readings",
                    pathName: "level-readings-page",
                },
            });
        } else {
            this.props.history.push({
                pathname: "/meter-reading",
                state: null,
            });
        }
    };

    handleLevelChange = (event) => {
        this.setState({ selectedOption: event.target.value });
    };

    renderCustomHeader = () => {
        return (
            <select onChange={this.handleLevelChange} value={this.state.selectedOption}>
                <option value="level">Level</option>
                <option value="distance">Distance</option>
            </select>
        );
    };



    getZoneAndSubzoneList = async () => {
        return await axios.get(
            `${API_BASE_URL}/zone/subzone/${sessionStorage.getItem("siteId")}`
        );
    };

    getMeterLevelReadings = async () => {
        return await axios.get(`${API_BASE_URL}/level/readings/${sessionStorage.getItem('siteId')}`);
        }
    exportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Report Data");

            const headersToUse = [
                "Serial Number",
                "Zone",
                "Subzone",
                "Location",
                "Level",
                "Time Stamp",
                "Status",
            ];

            const headerDataMapping = {
                "Serial Number": "meter_serial_no",
                "Zone": "zone",
                "Subzone": "subZone",
                "Location": "location",
                "Level": "level_distance_reading",
                "Time Stamp": "created_date_time",
                "Status": "status",
            };

            const dateFormatArray = ["created_date_time"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                } else if (value === "1" || value === "L") {
                    return "L";
                } else if (value === "2" || value === "GAL") {
                    return "GAL";
                } else if (value === "3" || value === "ft3") {
                    return "ft3";
                } else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.levelExcelReadings.forEach((row) => {
                const rowData = headersToUse.map((header) => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(
                new Blob([excelBlob], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                `Level-meter-readings-report-${this.state.excelGenerateValue.value === "custom"
                    ? `From ${defaultFormatDate(
                        this.state.startDate
                    )} To ${defaultFormatDate(this.state.endDate)}`
                    : this.state.excelGenerateValue.value
                } ${moment().format("DD-MM-YYYY hh:mm:ss a")}`
            );
            this.setState({ excelGenerateValue: "" });
            var excelDownloadBtn = document.getElementById("download-excel-button");
            excelDownloadBtn.classList.remove("mystyle");
        } catch (error) {
            console.error(error);
        }
    };
    render() {
        const { zoneAndSubzoneList, selectedOption } = this.state;
        if (this.state.isLoading) {
            return <Spinner />;
        } else {
            const filteredlevelMeterReadings = this.filterMeterReadings();
            return (
                <div ref={this.mainContentRef}>
                    <div className="main-content">
                        <div className="live-sync-icon-and-text-container">
                            <div className="level-image">
                                <img src="/images/level-meter.png" alt="level Meter" />
                            </div>

                            <div className="level-heading">LEVEL READINGS</div>
                            <div className="live-sync-info">
                                <i
                                    className={`${REFRESH_ICON} ${this.state.liveSyncIconClass} rotate`}
                                ></i>
                                <label className={`${this.state.liveSyncIconClass}`}>
                                    Syncing...
                </label>
                            </div>
                        </div>

                        <div
                            className="live-sync-icon-after-text-container filter-changes"
                            style={{
                                paddingBottom: this.state.isDropDown === true ? "13%" : "2%",
                            }}
                        >
                            {/* <label className="form-label"> ZONE  <span className="text-danger">*</span> </label> */}
                            <div className="zone-select-container">
                                <Select
                                    className="site-select-dropdown"
                                    value={this.state.levelDistanceSelect}
                                    placeholder="Select Level / Distance"
                                    options={this.state.levelAndRadarOptionList}
                                    onChange={this.levelAndRadarChangeHandler}
                                    onMenuOpen={this.handleMenuOpen}
                                    onMenuClose={this.handleMenuClose}
                                    menuPlacement="bottom"
                                />
                            </div>
                            {/* <label className="form-label"> ZONE  <span className="text-danger">*</span> </label> */}
                            <div className="zone-select-container">
                                <Select
                                    className="site-select-dropdown"
                                    value={this.state.zone}
                                    placeholder="Select Zone"
                                    options={this.zonesList(zoneAndSubzoneList)}
                                    onChange={this.zoneChangeHandler}
                                    onMenuOpen={this.handleMenuOpen}
                                    onMenuClose={this.handleMenuClose}
                                    menuPlacement="bottom"
                                />
                            </div>
                            {/* <label className="form-label">
                                SUBZONE  <span className="text-danger">*</span>
                            </label> */}

                            <div className="subzone-select-container">
                                <Select
                                    className="site-select-dropdown"
                                    value={this.state.subZone}
                                    placeholder="Select Subzone"
                                    options={this.subZonesList(
                                        zoneAndSubzoneList,
                                        this.state.zone
                                    )}
                                    onChange={this.subZoneChangeHandler}
                                    isDisabled={this.state.subzonedisabaled}
                                    onMenuOpen={this.handleMenuOpen}
                                    onMenuClose={this.handleMenuClose}
                                />
                            </div>

                            <button
                                className="brand-button report-button"
                                disabled={this.state.cancelButtondisabaled}
                                onClick={this.cancelHandler}
                            >
                                CLEAR
              </button>
                        </div>

                        <div className="border list-table-div" style={{ marginBottom: 50 }}>
                            {filteredlevelMeterReadings.length > 0 ? (
                                <MaterialTable
                                    columns={[
                                        // {
                                        //     title: "Sn",
                                        //     field: "sn",
                                        //     cellStyle: { color: "#0070C0", fontSize: 14 },
                                        // },
                                        {
                                            title: "Zone",
                                            field: "zone",
                                            cellStyle: { color: "#808080", fontSize: 14 },
                                        },
                                        {
                                            title: "Subzone",
                                            field: "subzone",
                                            cellStyle: { color: "#808080", fontSize: 14 },
                                        },
                                        {
                                            title: "Location",
                                            field: "location",
                                            cellStyle: { color: "#808080", fontSize: 14 },
                                        },
                                        {
                                            title: "Serial Number",
                                            field: "meter_serial_no",
                                            cellStyle: { color: "#000000", fontSize: 14 },
                                        },
                                        {
                                            title: this.state.levelDistanceSelect.value === "level" ? "Level" : "Distance",
                                            field: "level_distance",
                                            cellStyle: { color: "#808080", fontSize: 14 },
                                        },
                                        {
                                            title: "Time Stamp",
                                            field: "receive_date_time",
                                            cellStyle: { color: "#808080", fontSize: 14 },
                                        },
                                        {
                                            title: "Status",
                                            field: "status",
                                            cellStyle: { color: "#808080", fontSize: 14 },
                                        },
                                    ]}
                                    data={[
                                        ...filteredlevelMeterReadings.map((element, index) => {
                                            return {
                                                sn: index + 1,
                                                zone: element.meterData.zone,
                                                subzone: element.meterData.subZone,
                                                location: element.meterData.location,
                                                meter_serial_no: element.meter_serial_no,
                                                level_distance: element.level_distance_reading,
                                                receive_date_time: validateIsoDateFormat(
                                                    element.created_date_time
                                                )
                                                    ? defaultFormatDateTime(element.created_date_time)
                                                    : element.created_date_time,
                                                status: "High",
                                            };
                                        }),
                                    ]}
                                    onRowClick={(event, rowData) => {
                                        this.showMeterReadingHandler(rowData);
                                    }}
                                    options={{
                                        headerStyle: {
                                            color: "#0070C0",
                                            fontWeight: "bold",
                                            fontSize: 15,
                                        },
                                        columnsButton: true,
                                        filtering: true,
                                        pageSize: 5,
                                        pageSizeOptions: [25, 50, 75, 100],
                                        exportButton: true,
                                        exportAllData: true,
                                        exportFileName: "Reading Data",
                                    }}
                                />
                                // <MaterialTable
                                //     columns={[
                                //         {
                                //             title: "Sn",
                                //             field: "sn",
                                //             cellStyle: { color: "#0070C0", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Zone",
                                //             field: "zone",
                                //             cellStyle: { color: "#808080", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Subzone",
                                //             field: "subzone",
                                //             cellStyle: { color: "#808080", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Location",
                                //             field: "location",
                                //             cellStyle: { color: "#808080", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Serial Number",
                                //             field: "meter_serial_no",
                                //             cellStyle: { color: "#000000", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Level",
                                //             field: "level",
                                //             title: "Distance",
                                //             field: "distance",
                                //             cellStyle: { color: "#808080", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Time Stamp",
                                //             field: "receive_date_time",
                                //             cellStyle: { color: "#808080", fontSize: 14 },
                                //         },
                                //         {
                                //             title: "Status",
                                //             field: "status",
                                //             cellStyle: { color: "#808080", fontSize: 14 },
                                //         },
                                //     ]}
                                //     data={[
                                //         ...filteredlevelMeterReadings.map((element, index) => {
                                //             return {
                                //                 sn: index + 1,
                                //                 zone: element.meterData.zone,
                                //                 subzone: element.meterData.subZone,
                                //                 location: element.meterData.location,
                                //                 meter_serial_no: element.meter_serial_no,
                                //                 level: element.level_distance_reading,
                                //                 receive_date_time: validateIsoDateFormat(
                                //                     element.created_date_time
                                //                 )
                                //                     ? defaultFormatDateTime(element.created_date_time)
                                //                     : element.created_date_time,
                                //                 status: "High",
                                //             };
                                //         }),
                                //     ]}
                                //     onRowClick={(event, rowData) => {
                                //         this.showMeterReadingHandler(rowData);
                                //     }}
                                //     options={{
                                //         headerStyle: {
                                //             color: "#0070C0",
                                //             fontWeight: "bold",
                                //             fontSize: 15,
                                //         },
                                //         columnsButton: true,
                                //         filtering: true,
                                //         pageSize: 5,
                                //         pageSizeOptions: [25, 50, 75, 100],
                                //         exportButton: true,
                                //         exportAllData: true,
                                //         exportFileName: "Reading Data",
                                //     }}
                                // />
                            ) : (
                                <div className="text-center pt-2">
                                    <label>Records not found!</label>
                                </div>
                            )}
                        </div>
                        <div className="report-section">
                            <i className={`${ZONE_SUBZONE_ICON} icon-space`}></i>{" "}
                            <span>READINGS REPORT GENERATE AND GRAPH GENERATE</span>
                        </div>
                        <div>
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">
                                            Select Date To Generate Excel And Graph{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            className="site-select-dropdown"
                                            value={this.state.excelGenerateValue}
                                            placeholder="Select Date"
                                            options={this.state.excelAndGraphGenerateOptionList}
                                            onChange={this.excelGenerateChangeHandler}
                                            menuPlacement="bottom"
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.excelGenerateValue}
                                        </div>
                                    </div>
                                </div>
                                {this.state.excelGenerateValue.value === "custom" && (
                                    <div className="row">
                                        <div className="addUser">
                                            <label className="form-label">
                                                From Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker
                                                    className="form-control brand-font p-3"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="addUser">
                                            <label className="form-label">
                                                To Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker
                                                    className="form-control brand-font p-3"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="addButton">
                                    <button
                                        id="button"
                                        className="brand-button report-button"
                                        onClick={this.getlevelReportReadings}
                                    >
                                        Get Report
                  </button>
                                    <button
                                        id="download-excel-button"
                                        className="download-table-xls-button brandxls-button download-button"
                                        onClick={this.exportToXLSX}
                                    >
                                        Download as XLS
                  </button>
                                </div>
                            </div>
                        </div>
                        <div className="live-sync-icon-after-text-container">
                            <div className="line-chart">
                                <LevellineChart data={this.state.levelGraphReadingList} />
                            </div>
                        </div>
                        <div>
                            <div className="report-section">
                                <i className={`${MAP_MARKER_ICON} icon-space`}></i>
                                <span>METERS MAP VIEW</span>
                            </div>
                            <div>
                                <WrappedMap meterMapReadings={this.state.levelAllMetersData} />
                            </div>
                        </div>
                        <div className="sticky-icon" onClick={this.scrollToTop}>
                            ↑
            </div>
                    </div>
                </div>
            );
        }
    }
}

export default LevelMeterReadingPage;
