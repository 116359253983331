import moment from "moment";
import Toast from 'light-toast';
import Plot from 'react-plotly.js';
import React, { useState } from 'react';
import { GOOGLE_MAP_API_KEY } from 'assets/constants/Constants';
import { useHistory  } from  "react-router-dom";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView} from 'react-google-maps';

export const onSortColumn = (dataArray, column, orderFlag) => {
    let sortedData = [];
    if (orderFlag === "asc") {
        sortedData = dataArray.sort((a, b) => {
            if (a[column] < b[column]) return -1;
            if (a[column] > b[column]) return 1;
            if (a[column] === b[column]) return 1;
            return 1;
        });
    } else {
        sortedData = dataArray.sort((a, b) => {
            if (a[column] > b[column]) return -1;
            if (a[column] < b[column]) return 1;
            if (a[column] === b[column]) return 1;
            return 1;
        });
    }
    return sortedData;
}

export const defaultFormatDate = (item) => {
    let formatedDate = null;
    formatedDate = moment(item).format("DD-MM-YY"); 
    return formatedDate;
}
export const defaultFormatDateTime = (item) => {
    let formatedDate = null;
    formatedDate = moment(item).format("DD/MM/YY hh:mm A"); 
    return formatedDate;
}

export const showLoading = (message = "Please wait...") => {
    Toast.loading(message, () => {});
}

export const hideLoading = () => {
    Toast.hide();
}

export const validateIsoDateFormat = (item) => {
    const IsoPattern = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[+-]\d{2}:\d{2}))$/;
    return IsoPattern.test(item);
}

export const totalizerUnit = (item) => { 
  console.log("item",item)
  if (item === null) {
    return null; 
  }
  const unitMap = {
    "0": "m3",
    "1": "L",
    "2": "GAL",
    "3": "ft3",
    "4": "ft3"
  };

  return unitMap[item.totalizer_unit] || null;
};

export const  setTotalizerUnit151 = (forwardTotalizer, totalizerUnit) =>{
  return  forwardTotalizer + " "+ (totalizerUnit === 0 ? "m3" : totalizerUnit === 1 ? "m3" : totalizerUnit === 2 ? "m3" : totalizerUnit === 3 ? "m3": totalizerUnit === 4 ? "lit" : totalizerUnit === 5 ? "lit" : totalizerUnit === 6 ? "lit" :  totalizerUnit === 7 ? "lit"  : " ")
}

export const setFlowUnit151 = (flowRate, flowRateUnit) =>{
  return  flowRate + " "+ (flowRateUnit === 0 ? "L/hr" : flowRateUnit === 1 ? "L/min" : flowRateUnit === 2 ? "L/sec" : flowRateUnit === 3 ? "m3/hr": flowRateUnit === 4 ? "m3/min" : flowRateUnit === 5 ? "m3/sec" : "")
}

export const setTotalizerUnit153 = (forwardTotalizer, totalizerUnit) =>{
  return  forwardTotalizer + " "+ (totalizerUnit === 0 ? "m3" : totalizerUnit === 1 ? "lit" : totalizerUnit === 2 ? "GAL" : totalizerUnit === 3 ? "ft3": "")
}

export const setFlowUnit153 = (flowRate, flowRateUnit) =>{
  return  flowRate + " "+ (flowRateUnit === 0 ? "m3/hr" : flowRateUnit === 1 ? "L/hr" : flowRateUnit === 2 ? "GPM" : flowRateUnit === 3 ? "LPS": "")
} 

export const validationFor151 = (totalizerUnit) =>{
  return  totalizerUnit === 0 ? "m3" : totalizerUnit === 1 ? "m3" : totalizerUnit === 2 ? "m3" : totalizerUnit === 3 ? "m3": totalizerUnit === 4 ? "lit" : totalizerUnit === 5 ? "lit" : totalizerUnit === 6 ? "lit" :  totalizerUnit === 7 ? "lit"  : " "
} 

export const validationFor153 = (totalizerUnit) =>{
  return  totalizerUnit === 0 ? "m3" : totalizerUnit === 1 ? "lit" : totalizerUnit === 2 ? "GAL" : totalizerUnit === 3 ? "ft3": ""
} 

export const setPressureUnit171 = (pressureValue, pressureUnit) => {
  if (!pressureValue) {
    pressureValue = "0.00";
  }

  return pressureValue + " " + 
    (pressureUnit === 1 ? "KPa" :
     pressureUnit === 2 ? "x10KPa" :
     pressureUnit === 3 ? "x100KPa" :
     pressureUnit === 4 ? "MPa" :
     pressureUnit === 5 ? "x10MPa" :
     pressureUnit === 6 ? "x100MPa" :
     pressureUnit === 7 ? "Bar" :
     pressureUnit === 8 ? "Kg/cm²" :
     pressureUnit === 9 ? "MWC" :
     pressureUnit === 10 ? "MMWC" :
     "");
};



const allAlarms153 = [
  "INVALID CAPTURE",
  "Valve_on_off_status",
  "Reserved3 Alarm",
  "Pipe Broken Alarm",
  "Reverse Flow Alarm", 
  "Empty Pipe Alarm",
  "Leak detected Alarm",
  "Low Battery Alarm",
  "LOG_MEMORY_FULL",
  "Data Logger comm. Failed",
  "Data Loss Error",
  "Config Para Update Failed",
  "IP Comm. Fail Error",
  "RWM configuration Mode",
  "BUP Retry Enable / (Commissioned bit)",
  "RWM AM /AMR Mode"
];

const bpt171Alarms = [
  "Coil Alarm",
  "Reserved",
  "Reserved",
  "Reserved",
  "Reserved",
  "Reserved",
  "Pressure Sensor Open",
  "Pressure Sensor Short",
  "Pressure Over Range",
  "Pressure Under Range",
  "High Pressure",
  "Low Pressure",
  "Reserved"
];

export const getAlarmType153RWM = (decAlarmFlag) => {
  let alarmFlagArray = [];
  console.log("decAlarmFlag-rwm::",decAlarmFlag)
  for (let index = 0; index < allAlarms153.length; index++) {
      if (isBitSet(decAlarmFlag, index)) {
          alarmFlagArray.push(allAlarms153[index]);
      }
  }
  return  alarmFlagArray.length === 0 ? "No Alarms" : alarmFlagArray.join(", ");;
 
}

export const getAlarmType171BPT = (decAlarmFlag) => {
  let alarmFlagArray = [];
  for (let index = 0; index < bpt171Alarms.length; index++) {
      if (isBitSet(decAlarmFlag, index)) {
          alarmFlagArray.push(bpt171Alarms[index]);
      }
  }
  return  alarmFlagArray.length === 0 ? "No Alarms" : alarmFlagArray.join(", ");;
 
}

const allAlarms151 = [
  "Coil Alarm",
  "Empty pipe Alarm",
  "Negative flow alarm",
  "High flow alarm",
  "Low flow alarm", 
  "Low flow cut off",
  "Pressure Sensor Open",
  "Pressure sensor Short",
  "Pressure over range",
  "Pressure under range",
  "Reserved",
];

export const getAlarmTypeFor151BO = (decAlarmFlag) => {
  let alarmFlagArray = [];

  for (let index = 0; index < allAlarms151.length; index++) {
      if (isBitSet(decAlarmFlag, index)) {
          alarmFlagArray.push(allAlarms151[index]);
      }
  }

  return   alarmFlagArray.length === 0 ? ["No Alarms"] : alarmFlagArray;

}

const isBitSet = (no, index) => {
  return ((no >> index) & 0x1) === 1;
}
export const flowUnit = (item) => { 
  console.log("item",item)
  if (item === null) {
    return null; 
  }
  if(item === "0"){
    return  "m3/hr";
  }
  if(item === "1"){
    return  "L/hr";
  }
  if(item === "2"){
    return  "GPM";
  }
  if(item === "3"){
    return  "LPS";
  }
};

const bitAlaramsMapping = {
    0: "INVALID CAPTURE",
    1: "Reserved2 Alarm",
    2: "Reserved3 Alarm",
    3: "Pipe Broken Alarm",
    4: "Reverse flow alarm",
    5: "Empty Pipe Alarm (No Echo)",
    6: "Leak detected Alarm",
    7: "Low Battery Alarm",
    8: "LOG_MEMORY_FULL",
    9: "Data Logger comm. Failed",
    10: "Data Loss Error",
    11: "Config Para Update Failed",
    12: "IP Comm. Fail Error",
    13: "RWM configuration Mode",
    14: "BUP Retry Enable /(Commissioned bit)",
    15: "RWM AM /AMR Mode"
};

export const validateBitStatusAlarms = (alarmType) => {
    if (alarmType === "" || alarmType === undefined ||  alarmType === null ) {
        return "None";
    }
    
    if (typeof alarmType === 'string' && !isNaN(alarmType)) {
        alarmType = Number(alarmType);
    }
    
    if (typeof alarmType === 'number') {
        let binaryString = alarmType.toString(2);
        const setBitsCount = binaryString.split('1').length - 1;
        let activeAlarms = [];
        console.log("setBitsCount", setBitsCount);
        activeAlarms.push(bitAlaramsMapping[setBitsCount]);
        return activeAlarms.length > 0 ? activeAlarms : "None";
    }
    return alarmType;
};

export const PieChart = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 200,
        width: 230,
        margin: { t: 2, b: 2, l: 0, r: 0 },
        showlegend: false,
        paper_bgcolor: 'transparent',
      }}
    />
  );

  export const LineChart = ({ data,unit }) => (
    <Plot
      data={data}
      layout={{
        height: 500,
        width: '100%',
        xaxis: {
          title: 'Date and time'
        },
        yaxis: {
          title: `Consumption`,
          ticksuffix: unit ? ` ${unit}` : '',
        },
        margin: { t: 20, b: 50, l: 100, r: 10 },
        borderRadius: '15%',
        overflow: 'hidden',
      }}
    />
  );

  export const LineChartAllMeters = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 220,
        width: 220,
        yaxis: {
          title: 'Meters'
        },
        margin: { t: 20, b: 50, l: 55, r: 20 },
        borderRadius: '15%',
        showlegend: false,
        overflow: 'hidden',
      }}
    />
  );

  export const LineChartAllMetersBig = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 350,
        width: 650,
        yaxis: {
          title: 'Meters'
        },
        margin: { t: 20, b: 50, l: 55, r: 20 },
        borderRadius: '15%',
        overflow: 'hidden',
      }}
    />
  );

  export const LineChartTotalizer = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 400,
        width: 550,
        xaxis: {
          title: 'Date and time'
        },
        yaxis: {
          title: 'Totalizer'
        },
        margin: { t: 20, b: 50, l: 55, r: 10 },
        borderRadius: '15%',
        overflow: 'hidden',
      }}
    />
  );

  export const LineChartFlowrate = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 400,
        width: 550,
        xaxis: {
          title: 'Date and time'
        },
        yaxis: {
          title: 'Flowrate'
        },
        margin: { t: 20, b: 50, l: 55, r: 10 },
        borderRadius: '15%',
        // border: '2px solid #1f77b4', // Optional: Add a border to the chart
        // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        //paper_bgcolor: 'transparent',
      }}
    />
  );

  const markers = [
    { lat: 18.516726, lng: 73.856255 }, // Pune City Center
    { lat: 18.530823, lng: 73.855202 }, // Shivajinagar
    { lat: 18.500000, lng: 73.800000 }, // Kothrud
    { lat: 18.5861, lng: 73.7490 },     // Hinjewadi IT Park
    { lat: 18.5773, lng: 73.9190 },     // Pune International Airport
    { lat: 18.4092, lng: 73.8300 },     // Sinhagad Fort
    { lat: 18.5300, lng: 73.8950 },     // Aga Khan Palace
    { lat: 18.5584, lng: 73.9250 }      // Viman Nagar
];
  const markerss = [
    { lat: -24.397, lng: 150.644 },
  ];

  const Tooltip = ({ text, position, hovered, status }) => {
    console.log("text::", text);
    console.log("position::", position);
  
    return (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -(height + 10),
        })}
      >
        <div style={{
          background: 'black',
          color: 'white',
          border: '1px solid black',
          padding: '5px',
          zIndex: 1000,
          fontSize: hovered ? '20px' : '12px', 
          transition: 'font-size 0.2s ease' 
        }}>
           {`Meter serial no : ${text}, ${status}`}
        </div>
      </OverlayView>
    );
  };

  const MyMapComponent = withScriptjs(withGoogleMap(({ meterMapReadings }) => {
    console.log("meterMapReadings::",meterMapReadings)
    const [tooltip, setTooltip] = useState({ visible: false, text: '', position: {}, hovered: false, status: '' });
    const [exportBox, setExportBox] = useState({ visible: false, serialNumber: '', position: {} });
  
    const history = useHistory();
    const infoBoxCliked = (serialNumber, meterType) => {
      if (window.confirm("Are you sure you want to see details of the selected meter?")) {
        const rowItem = serialNumber ? { serial_number: serialNumber } : null;
  
        history.push({
          pathname: "/meter-reading",
          state: { rowItem, pageName: meterType === "Flow" ? "Flow Readings" : meterType === "Level" ? "Level Readings" : meterType === "Pressure" ? "Pressure Readings" : "Flow Readings", pathName: meterType === "Flow" ? "flow-readings-page" : meterType === "Level" ? "level-readings-page" : meterType === "Pressure" ? "pressure-readings-page" : "flow-readings-page" },
        });
      }
    };
  
    const MapViewReadings = meterMapReadings.meterMapReadings
      .map((marker) => ({
        latitude: parseFloat(marker.gps_latitude),
        longitude: parseFloat(marker.gps_longitude),
        serialNumber: marker.serial_number,
        meterType: marker.meter_type,
        meterStatus: marker.status
      }))
      .filter((item) => !isNaN(item.latitude) && !isNaN(item.longitude));
  
    const defaultCenter = MapViewReadings.length > 0
      ? { lat: MapViewReadings[0].latitude, lng: MapViewReadings[0].longitude }
      : { lat: 22.00, lng: 77.00 };
    return (
      <GoogleMap defaultZoom={defaultCenter.lat === 22.00 ? 4 : 10} defaultCenter={defaultCenter}>
        {MapViewReadings.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.latitude, lng: marker.longitude }}
            onMouseOver={() => setTooltip({
              visible: true,
              status: marker.meterStatus,
              text: marker.serialNumber,
              meterType: marker.meterType,
              position: { lat: marker.latitude, lng: marker.longitude },
              hovered: true
            })}
            onMouseOut={() => setTooltip({ visible: false, text: '', position: {}, status: '' })}
            onClick={() => setExportBox({
              visible: true,
              serialNumber: marker.serialNumber,
              meterType: marker.meterType,
              position: { lat: marker.latitude, lng: marker.longitude }
            })}
          />
        ))}
  
        {tooltip.visible && (
          <OverlayView
            position={tooltip.position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid black", borderRadius: "5px" }}>
              <strong>Serial No:</strong> {tooltip.text} <br />
              <strong>Status:</strong> {tooltip.status}
            </div>
          </OverlayView>
        )}
  
        {exportBox.visible && (
          <OverlayView
            position={exportBox.position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              style={{
                background: '#fff',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                position: "relative",
                transform: "translate(-50%, -100%)",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
              }}
            >
              <button onClick={() => infoBoxCliked(exportBox.serialNumber, exportBox.meterType)}>Export Data</button>
              <p>Meter Serial: {exportBox.serialNumber}</p>
              <button onClick={() => setExportBox({ visible: false })}>Close</button>
            </div>
          </OverlayView>
        )}
      </GoogleMap>
    );
  }));
  
  export const WrappedMap = (meterMapReadings) => (
    <MyMapComponent
      meterMapReadings={meterMapReadings}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '500px', width: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  );

  export const pieDataOne = [{
    values: [55, 19, 10],
    labels: ['Active', 'Inactive', 'Faulty'],
    type: 'pie',
    textinfo: 'label+value',
    hoverinfo: 'label+value',
    marker: {
      colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
      line: {
        color: '#aaa',
        width: 1
      }
    }
  }];

  export const pieDataTwo = [{
    values: [25, 15, 9],
    labels: ['Active', 'Inactive', 'Faulty'],
    type: 'pie',
    textinfo: 'label+value',
    hoverinfo: 'label+value',
    marker: {
      colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
      line: {
        color: '#aaa',
        width: 1
      }
    }
  }];


  export const pieDataThree = [{
    values: [45, 19, 12],
    labels: ['Active', 'Inactive', 'Faulty'],
    type: 'pie',
    textinfo: 'label+value',
    hoverinfo: 'label+value',
    marker: {
      colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
      line: {
        color: '#aaa',
        width: 1
      }
    }
  }];


  export const pieDataFour = [{
    values: [25, 9, 3],
    labels: ['Active', 'Inactive', 'Faulty'],
    type: 'pie',
    textinfo: 'label+value',
    hoverinfo: 'label+value',
    marker: {
      colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
      line: {
        color: '#aaa',
        width: 1
      }
    }
  }];

  
export const lineDataOne = [{
  x: ['2025-01-23', '2025-01-24', '2025-01-25', '2025-01-26', '2025-01-27', '2025-01-28', '2025-01-29'],
  y: [10, 15, 13, 17, 34, 50, 70, 100],
  mode: 'lines+markers',
  marker: { color: "1f77b4" },
  line: { color: '#1f77b4' },
  borderRadius: '45%',
  name: "Active"
}, {
  x: ['2025-01-23', '2025-01-24', '2025-01-25', '2025-01-26', '2025-01-27', '2025-01-28', '2025-01-29'],
  y: [10, 15, 13, 15, 32, 40, 80, 100],
  mode: 'lines+markers',
  marker: { color: 'red' },
  line: { color: 'red' },
  borderRadius: '45%',
  name: "Inactive"
}, {
  x:['2025-01-23', '2025-01-24', '2025-01-25', '2025-01-26', '2025-01-27', '2025-01-28', '2025-01-29'],
  y: [0, 1, 1, 1, 1, 1, 1, 1],
  mode: 'lines+markers',
  marker: { color: 'orange' },
  line: { color: 'orange' },
  borderRadius: '45%',
  name: "Faulty"
}];

export const lineDataTwo = [{
  x: ['2024-11-01', '2024-11-02', '2024-11-03', '2024-11-04'],
  y: [20, 35, 43, 55],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: '#1f77b4' },
  line: { color: '#1f77b4' },
  borderRadius: '45%',
  name: "Active"
},
{
  x: ['2024-11-01', '2024-11-02', '2024-11-03', '2024-11-04'],
  y: [20, 30, 40, 50],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'red' },
  line: { color: 'red' },
  borderRadius: '45%',
  name: "Inactive"
},
{
  x: ['2024-11-01', '2024-11-02', '2024-11-03', '2024-11-04'],
  y: [0, 1, 2, 5],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'orange' },
  line: { color: 'orange' },
  borderRadius: '45%',
  name: "Faulty"
}];

export const lineDataThree = [{
  x: ['2024-10-01', '2024-10-12', '2024-10-23', '2024-10-28'],
  y: [20, 35, 45, 58],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'red' },
  line: { color: 'red' },
  borderRadius: '45%',
  name: "Active"
},
{
  x: ['2024-10-01', '2024-10-12', '2024-10-23', '2024-10-28'],
  y: [10, 25, 40, 57],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: '#1f77b4' },
  line: { color: '#1f77b4' },
  borderRadius: '45%',
  name: "Inactive"
},
{
  x: ['2024-10-01', '2024-10-12', '2024-10-23', '2024-10-28'],
  y: [0, 3, 3, 7],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'orange' },
  line: { color: 'orange' },
  borderRadius: '45%',
  name: "Faulty",
}];
export const lineDataFour = [{
  x: ['2024-10-01', '2024-10-02', '2024-10-03', '2024-10-04'],
  y: [30, 15, 19, 47],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: '#1f77b4' },
  line: { color: '#1f77b4' },
  borderRadius: '45%',
  name: "Active"
},
{
  x: ['2024-10-01', '2024-10-02', '2024-10-03', '2024-10-04'],
  y: [40, 15, 69, 87],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'red' },
  line: { color: 'red' },
  borderRadius: '45%',
  name: "Inactive"
},
{
  x: ['2024-10-01', '2024-10-02', '2024-10-03', '2024-10-04'],
  y: [0, 1, 1, 1],
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'orange' },
  line: { color: 'orange' },
  borderRadius: '45%',
  name: "Faulty"
}];

  const MyMapComponents = withScriptjs(withGoogleMap(() => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{  lat: 18.516726, lng: 73.856255}}
    >
      {markers.map((marker, index) => (
          <Marker style={{backgroundColor:"blue"}}
            key={index} 
            position={{ lat: marker.lat, lng: marker.lng }} 
          />
        ))}
        {markers.map((marker, index) => (
          <Marker style={{backgroundColor:"blue"}}
            key={index} 
            position={{ lat: marker.lat, lng: marker.lng }} 
          />
        ))}
    </GoogleMap>
  )));
  
  export const WrappedMapAllMeters = () => (
    <MyMapComponents
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '500px', width: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  );

  export const LevellineChart = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 500,
        width: 600,
        xaxis: {
          title: 'Date and time'
        },
        yaxis: {
          title: 'Level'
        },
        margin: { t: 20, b: 50, l: 55, r: 10 },
        borderRadius: '15%',
        overflow: 'hidden',
      }}
    />
  );

  export const PressurelineChart = ({ data }) => (
    <Plot
      data={data}
      layout={{
        height: 500,
        width: 600,
        xaxis: {
          title: 'Date and time'
        },
        yaxis: {
          title: 'Pressure'
        },
        margin: { t: 20, b: 50, l: 55, r: 10 },
        borderRadius: '15%',
        overflow: 'hidden',
      }}
    />
  );


  