import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, PIN_CODE_PATTERN, TYPE_SUCCESS, TYPE_FAIL, INTERVAL, WEB_APP_URL } from "assets/constants/Constants";
import { SITE_ICON, COPY_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class CreateSitePage extends Component {
    constructor(props) {
        super(props)

        const editData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            defaultBackgroundImageOrUplodedImageType: editData === null || editData.defaultImage_uploadedImage === undefined || editData.defaultImage_uploadedImage === ""  || editData.defaultImage_uploadedImage === null  ? "" :  (editData && editData.defaultImage_uploadedImage.base64Data) ? this.getFileNameFromEditData(editData.defaultImage_uploadedImage): editData.defaultImage_uploadedImage,
            backgroundImageSelectedType: editData === null ? "" : editData.background_image_selected_type,
            clientName: editData === null ? null : editData.client_name,
            clientID: editData === null ? "" : editData.client_id,
            primaryLogoImage: editData === null || editData.client_logoImage_primary === undefined ||  editData.client_logoImage_primary === "" || editData.client_logoImage_primary === null  ? "" : this.getFileNameFromEditData(editData.client_logoImage_primary),
            secondaryLogoImage: editData === null || editData.client_logoImage_secondary === undefined ||  editData.client_logoImage_secondary === "" || editData.client_logoImage_secondary === null  ? "" : this.getFileNameFromEditData(editData.client_logoImage_secondary),
            siteId: editData === null ? null : editData._id,
            siteName: editData === null ? "" : editData.site_name,
            siteStreet: editData === null ? "" : editData.street,
            siteCity: editData === null ? "" : editData.city,
            sitePincode: editData === null ? "" : editData.pincode,
            backendURL: editData === null ? "" : editData.backend_url,
            siteInactive: editData === null ? "" : editData.site_inactive,
            siteTodaysRedaingsToCheck: editData === null ? "" : editData.site_todays_readings_check,
            copiedIconClass: "",
            siteBackGroundImageSelectedList: [
                { value: "power plant", label: "Power plant" },
                { value: "hydro plant", label: "Hydro plant" },
                { value: "electricity plant", label: "Electricity plant" },
            ],
            siteBackGroundImageSelecteTypeList: [
                { value: "default images", label: "Default images" },
                { value: "upload", label: "Upload" }
            ],
            error: {},
        }
    }

    async componentDidMount() {
        const siteData = await axios(`${API_BASE_URL}/site`);
        this.setState({
            siteList: siteData.data.result,
            isLoading: false,
        });

        const editDataForDowImage = this.props.history.location.state;

        if (editDataForDowImage) {
            // Handle default uploaded image
            if (editDataForDowImage.defaultImage_uploadedImage) {
                const { base64Data, fileName } = editDataForDowImage.defaultImage_uploadedImage;

                if (base64Data) {
                    const { fileUrl } = this.base64ToFile({ base64Data, fileName });
                    this.setState({ downloadLink: fileUrl, downloadFileName: fileName, defaultBackgroundImageOrUplodedImageType: {base64Data:base64Data,fileName:fileName} });
                }
            }

            // Handle client logo image -1
            if (editDataForDowImage.client_logoImage_primary) {
                const { base64Data: base64DataLogo, fileName: fileNameLogo } = editDataForDowImage.client_logoImage_primary;

                if (base64DataLogo) {
                    const { fileUrl: fileUrlLogo } = this.base64ToFile({ base64Data: base64DataLogo, fileName: fileNameLogo });
                    this.setState({ downloadLinkLogo1: fileUrlLogo, downloadFileNameLogo1: fileNameLogo, primaryLogoImage:{base64Data: base64DataLogo, fileName: fileNameLogo} });
                }
            }

            // Handle client logo image -2
            if (editDataForDowImage.client_logoImage_secondary) {
                const { base64Data: base64DataLogo, fileName: fileNameLogo } = editDataForDowImage.client_logoImage_secondary;

                if (base64DataLogo) {
                    const { fileUrl: fileUrlLogo } = this.base64ToFile({ base64Data: base64DataLogo, fileName: fileNameLogo });
                    this.setState({ downloadLinkLogo2: fileUrlLogo, downloadFileNameLogo2: fileNameLogo, secondaryLogoImage: { base64Data: base64DataLogo, fileName: fileNameLogo } });
                }
            }
        }

    }
    base64ToFile = ({ base64Data, fileName }) => {
        const base64String = base64Data.split(',')[1];
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const fileUrl = URL.createObjectURL(blob);
        return { fileUrl, fileName };
    };

    getFileNameFromEditData = (editData) => {
        if (editData && editData.fileName) {
            return editData.fileName;
        }
        return "No file selected";
    };

    siteNameHandler = (event) => {
        this.setState({
            siteName: event.target.value
        });
    }

    siteStreetHandler = (event) => {
        this.setState({
            siteStreet: event.target.value
        });
    }

    siteCityHandler = (event) => {
        this.setState({
            siteCity: event.target.value
        });
    }

    sitePincodeHandler = (event) => {
        this.setState({
            sitePincode: event.target.value
        });
    }

    siteInactiveHandler = (event) => {
        this.setState({
            siteInactive: event.target.value
        });
    }

    siteTodaysRedaingsToCheckHandler = (event) => {
        this.setState({
            siteTodaysRedaingsToCheck: event.target.value
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.siteName) {
            isValid = false;
            error.siteName = "Please enter site name";
        }
        if (!this.state.siteStreet) {
            isValid = false;
            error.siteStreet = "Please enter site street";
        }
        if (!this.state.siteCity) {
            isValid = false;
            error.siteCity = "Please enter site city";
        }
        if (!this.state.sitePincode) {
            isValid = false;
            error.sitePincode = "Please enter site pincode";
        }
        if (this.state.sitePincode !== "undefined") {
            if (!PIN_CODE_PATTERN.test(this.state.sitePincode)) {
                isValid = false;
                error.sitePincode = "Please enter valid Pincode";
            }
        }

        if (!this.state.clientName) {
            isValid = false;
            error.clientName = "Please enter client name";
        }

        if (!this.state.clientID) {
            isValid = false;
            error.clientID = "Please enter clientID";
        }


        if (!this.state.siteInactive) {
            isValid = false;
            error.siteInactive = "Please enter time";
        }

        if (!this.state.siteTodaysRedaingsToCheck) {
            isValid = false;
            error.siteTodaysRedaingsToCheck = "Please enter time";
        }

        this.setState({
            error
        });
        return isValid;
    }

    toBase64 = async (file) => {
        if (file && file.base64Data && file.fileName) {
            this.setState({
                base64Data: file.base64Data,
                fileName: file.fileName
            }, () => {
                console.log("State updated:", this.state.base64Data, this.state.fileName);
            });
            return;
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result; 
                const fileName = file.name;
                resolve({
                    base64Data: base64Data,
                    fileName: fileName
                });
            };

            reader.onerror = (error) => {
                console.error("FileReader error:", error);
                reject(error);
            };

            reader.readAsDataURL(file); 
        });
    };

    

    cancelHandler = (event) => {
        this.props.history.push("/sites-page");
    }

    copyHandler = (copiedText, isSuccessful) => {
        if (isSuccessful) {
            console.log(`Copied: ${copiedText}`);
            this.setState({ copiedIconClass: 'copied-text' });

            setTimeout(() => {
                this.setState({ copiedIconClass: " " });
            }, 3500);
            ShowToast('Text copied!', TYPE_SUCCESS, INTERVAL, this.props, "/create-site-page");
        } else {
            console.error('Copy failed');
        }
    };

    siteBackGroundChangeHandler = (event) => {
        this.setState({
            defaultBackgroundImageOrUplodedImageType: event.target.value,
        });
    }

    siteBackGroundChangeHandlerUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileSize = file.size 
            const maxSize = 2 * 1024 * 1024; // 2 MB in bytes

            if (fileSize > maxSize) {
                alert('File size should not exceed 100KB.');
                this.setState({ defaultBackgroundImageOrUplodedImageType: "", error: 'File size exceeds 100KB.' });
                event.target.value = "";
            } else {
                this.setState({ defaultBackgroundImageOrUplodedImageType: file, error: '' });
            }
        } else {
            this.setState({ defaultBackgroundImageOrUplodedImageType: "", error: '' });
        }
    }


    siteBackGroundChangeTypesHandler = (event) => {
        this.setState({
            backgroundImageSelectedType: event.target.value,
        })
    }
    siteClinetNameHandaler = (event) => {
        this.setState({
            clientName: event.target.value
        })
    }
    siteClinetIDHandaler = (event) => {
        this.setState({
            clientID: event.target.value
        })
    }

    sitePrimaryLogoImageChangeHandler = (event) => {
        this.setState({
            primaryLogoImage: event.target.files[0],
        })
    }

    siteSecondaryLogoImageChangeHandler = (event) => {
        this.setState({
            secondaryLogoImage: event.target.files[0],
        })
    }

    renderBackGroundImageSelectTypesOptions = () => {
        if (this.state.siteBackGroundImageSelecteTypeList.length > 0) {
            return this.state.siteBackGroundImageSelecteTypeList.map((backgroundimageTypeNameSelectItem, index) => {
                let isSelected = false;
                if (backgroundimageTypeNameSelectItem.value === this.state.backgroundImageSelectedType) {
                    isSelected = true;
                }
                return (
                    <option value={backgroundimageTypeNameSelectItem.value} selected={isSelected} key={backgroundimageTypeNameSelectItem.value} className="option-text-color"> { backgroundimageTypeNameSelectItem.label} </option>
                )
            });
        }
        else {
            return <option disabled> No Data Found</option>
        }
    }

    renderBackGroundImageSelectOptions = () => {
        if (this.state.siteBackGroundImageSelectedList.length > 0) {
            return this.state.siteBackGroundImageSelectedList.map((backgroundimageNameSelectItem, index) => {
                let isSelected = false;
                if (backgroundimageNameSelectItem.value === this.state.defaultBackgroundImageOrUplodedImageType) {
                    isSelected = true;
                }
                return (
                    <option value={backgroundimageNameSelectItem.value} selected={isSelected} key={backgroundimageNameSelectItem.value} className="option-text-color"> { backgroundimageNameSelectItem.label} </option>
                )
            });
        }
        else {
            return <option disabled> No Data Found</option>
        }
    }

    addSiteHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
        this.setState({ isLoading: true, });

        const primaryLogoImage = this.state.primaryLogoImage;
        let logoImageOneBase64 = null;
        let logoImageOneFileName = null;



        if (primaryLogoImage !== "" && primaryLogoImage && typeof primaryLogoImage === "object" && primaryLogoImage.base64Data && primaryLogoImage.fileName) {
            logoImageOneBase64 = primaryLogoImage.base64Data;
            logoImageOneFileName = primaryLogoImage.fileName;
        } else if (primaryLogoImage instanceof File) {
            const result = await this.toBase64(primaryLogoImage);
            logoImageOneBase64 = result.base64Data;
            logoImageOneFileName = result.fileName;
        } else {
            logoImageOneBase64 = null;
            logoImageOneFileName = null;
        }
        if (logoImageOneBase64 && logoImageOneFileName) {

            this.setState({
                primaryLogoImage: { base64Data: logoImageOneBase64, fileName: logoImageOneFileName }
            })

        } else {
            this.setState({
                primaryLogoImage: null
            })
        }


            const secondaryLogoImage = this.state.secondaryLogoImage;
            let logoImageTwoBase64 = null;
            let logoImageTwoFileName = null;

            if (secondaryLogoImage !== "" && secondaryLogoImage && typeof secondaryLogoImage === "object" && secondaryLogoImage.base64Data && secondaryLogoImage.fileName) {
                logoImageTwoBase64 = secondaryLogoImage.base64Data;
                logoImageTwoFileName = secondaryLogoImage.fileName;
            } else if (secondaryLogoImage instanceof File) {
                const result = await this.toBase64(secondaryLogoImage);
                logoImageTwoBase64 = result.base64Data;
                logoImageTwoFileName = result.fileName;
            } else {
                logoImageTwoBase64 = null;
                logoImageTwoFileName = null;
            }
            if (logoImageTwoBase64 && logoImageTwoFileName) {

                this.setState({
                    secondaryLogoImage: { base64Data: logoImageTwoBase64, fileName: logoImageTwoFileName }
                })

            } else {
                this.setState({
                    secondaryLogoImage: null
                })
            }

        const uplodedImageAndDefaultImage = this.state.defaultBackgroundImageOrUplodedImageType !== "" && (typeof this.state.defaultBackgroundImageOrUplodedImageType === "object")
                ? await this.toBase64(this.state.defaultBackgroundImageOrUplodedImageType): this.state.defaultBackgroundImageOrUplodedImageType !== ""
                    ? this.state.defaultBackgroundImageOrUplodedImageType : null;

        const requestParameter = {
            siteName: this.state.siteName,
            siteStreet: this.state.siteStreet,
            siteCity: this.state.siteCity,
            sitePincode: this.state.sitePincode,
            defaultBackgroundImageOrUplodedImageType: uplodedImageAndDefaultImage,
            backgroundImageSelectedType: this.state.backgroundImageSelectedType,
            clientName: this.state.clientName,
            clientID: this.state.clientID,
            primaryLogoImage: this.state.primaryLogoImage,
            secondaryLogoImage: this.state.secondaryLogoImage,
            siteInactive: this.state.siteInactive,
            siteTodaysRedaingsToCheck: this.state.siteTodaysRedaingsToCheck,
            backendURL: this.state.backendURL
        }
        let siteResponse = null;
        if (this.state.siteId === null) {
            siteResponse = await axios.post(`${API_BASE_URL}/site`, requestParameter);
        }
        else {
            siteResponse = await axios.put(`${API_BASE_URL}/site/${this.state.siteId}`, requestParameter);
        }
        const tempSiteId = this.state.siteId;
        if (siteResponse.status === 200) {
            this.setState({
                siteId: null,
                siteName: "",
                siteList: "",
                siteCity: "",
                sitePincode: "",
                backendURL: ""
            }, () => {
                if (tempSiteId === null) {
                    ShowToast('Site created successfully', TYPE_SUCCESS, INTERVAL, this.props, "/sites-page");
                }
                else {
                    ShowToast('Site updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/sites-page");
                }
                this.props.history.push("/sites-page");
            });
        }
        }
    }

    checkIfClientIdAlreadyPresent = async (event) => {
        event.preventDefault();
        const clientID = event.target.value
        const siteDataBasedOnClientId = await axios(`${API_BASE_URL}/site/${clientID}`);
        if (siteDataBasedOnClientId.data.length > 0) {
            ShowToast(`This ${clientID} clientID  is already created`, TYPE_FAIL, INTERVAL, this.props, "");
            this.setState({
                clientID: ""
            })
            return;
        }
    }

    render() {
        const editData = this.props.history.location.state;
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/sites-page">Site Master</a></li>
                            {
                                this.state.siteId === null ? (
                                    <li>Add New Site</li>
                                ) : (
                                    <li>Edit Site</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-site-main-view">
                        <div className="mx-auto text-left border create-site-div">
                            <form className="pt-1">
                                <div className="site-icon-wrapper">
                                    <h4>{this.state.siteId ? "Edit Site" : "Add New Site"}</h4>
                                    <i className={`${SITE_ICON} list-table-icon`}></i>
                                </div>
                                <div className="input-label-group w-100">
                                    <label className="form-label">Site Name <span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.siteName} onChange={this.siteNameHandler} placeholder="Enter Site Name" />
                                    <div className="text-danger error-msg">
                                        {this.state.error.siteName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">Site Street <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.siteStreet} onChange={this.siteStreetHandler} placeholder="Enter Site Street" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.siteStreet}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">Site City<span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.siteCity} onChange={this.siteCityHandler} placeholder="Enter Site City" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.siteCity}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">Site Pincode <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.sitePincode} onChange={this.sitePincodeHandler} placeholder="Enter Site Pincode" maxLength="6" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.sitePincode}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6" id="custom-dropdown">
                                        <label className="form-label">Site Background image types </label>
                                        <select
                                            className="form-control brand-font p-3 placeholderColor add-meter-input"
                                            onChange={this.siteBackGroundChangeTypesHandler}
                                            value={this.state.backgroundImageSelectedType}
                                        //placeholder="Select types"
                                        >
                                            <option value="" disabled >Select types</option>
                                            {
                                                this.renderBackGroundImageSelectTypesOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.defaultBackgroundImageOrUplodedImageType}
                                        </div>
                                    </div>
                                    {
                                        this.state.backgroundImageSelectedType === "default images" ? (
                                            <div className="input-label-group col-md-6" id="custom-dropdown">
                                                <label className="form-label">Site Background image</label>
                                                <select
                                                    className="form-control brand-font p-3 placeholderColor add-meter-input"
                                                    onChange={this.siteBackGroundChangeHandler}
                                                    value={this.state.defaultBackgroundImageOrUplodedImageType}
                                                //placeholder="Select images"
                                                >
                                                    <option value="" disabled >Select images</option>
                                                    {
                                                        this.renderBackGroundImageSelectOptions()
                                                    }
                                                </select>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.backgroundImage}
                                                </div>
                                            </div>
                                        ) : (
                                            this.state.backgroundImageSelectedType === "upload" && (
                                                <div className="input-label-group col-md-6">
                                                    <label className="form-label">Upload Site Background image <span className="text-danger">(Max 2MB)</span></label>
                                                    <input
                                                        type="file"
                                                        className="form-control brand-font p-3 placeholderColor add-meter-input"
                                                        onChange={this.siteBackGroundChangeHandlerUpload}
                                                        accept=".jpg, .jpeg, .png"
                                                        disabled={this.state.isUploadDisabled}
                                                    />
                                                    <div className="text-danger error-msg">
                                                        {this.state.error.backgroundImage}
                                                    </div>
                                                    {this.state.downloadLink && (
                                                        <div style={{ paddingTop: "10px" }}>
                                                            <a
                                                                id="download"
                                                                href={this.state.downloadLink}
                                                                download={this.state.downloadFileName}
                                                                style={{ color: 'blue', textDecoration: 'underline', fontSize: "15px" }}
                                                            >
                                                                Download
                                                                </a>
                                                            <span style={{marginLeft: '10px', color: 'black', fontWeight: '200' }}>{this.state.downloadFileName}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )
                                    }
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Client ID <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.clientID} onChange={this.siteClinetIDHandaler} onBlur={this.checkIfClientIdAlreadyPresent} placeholder="Enter client name" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.clientID}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Client Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.clientName} onChange={this.siteClinetNameHandaler} placeholder="Enter client name" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.clientName}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Upload Site Primary Logo Image <span className="text-danger">(Max 2MB)</span></label>
                                        <input
                                            type="file"
                                            className="form-control brand-font p-3 placeholderColor add-meter-input"
                                            onChange={this.sitePrimaryLogoImageChangeHandler}
                                            accept=".jpg, .jpeg, .png"
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.defaultBackgroundImageType}
                                        </div>
                                        {this.state.primaryLogoImage && this.state.downloadLinkLogo1 && (
                                            <div style={{ paddingTop: "10px" }}>
                                                <a
                                                    id="download"
                                                    href={this.state.downloadLinkLogo1}
                                                    download={this.state.downloadFileNameLogo1}
                                                    style={{ color: 'blue', textDecoration: 'underline', fontSize: "15px" }}
                                                >
                                                    Download
                                                                </a>
                                                <span style={{ marginLeft: '10px', color: 'black', fontWeight: '200' }}>{this.state.downloadFileNameLogo1}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Upload Site Secondary Logo Image <span className="text-danger">(Max 2MB)</span></label>
                                        <input
                                            type="file"
                                            className="form-control brand-font p-3 placeholderColor add-meter-input"
                                            onChange={this.siteSecondaryLogoImageChangeHandler}
                                            accept=".jpg, .jpeg, .png"
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.defaultBackgroundImageType}
                                        </div>
                                        {this.state.secondaryLogoImage && this.state.downloadLinkLogo2 && (
                                            <div style={{ paddingTop: "10px" }}>
                                                <a
                                                    id="download"
                                                    href={this.state.downloadLinkLogo2}
                                                    download={this.state.downloadFileNameLogo2}
                                                    style={{ color: 'blue', textDecoration: 'underline', fontSize: "15px" }}
                                                >
                                                    Download
                                                                </a>
                                                <span style={{ marginLeft: '10px', color: 'black', fontWeight: '200' }}>{this.state.downloadFileNameLogo2}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Backend URL</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input font-style" value={this.state.backendURL} onChange={this.backendURLHandler} placeholder="Enter backend URL" />
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Mark Meter Inactive After  <span className="text-danger">(Hours)  </span><span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input font-style" value={this.state.siteInactive} onChange={this.siteInactiveHandler} placeholder="Enter Hours" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.siteInactive}
                                        </div>
                                    </div>
                                    {/* <div className="input-label-group col-md-6">
                                        <label className="form-label">Todays Consumption Readings Check After  <span className="text-danger">(Hours)  </span><span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input font-style" value={this.state.siteTodaysRedaingsToCheck} onChange={this.siteTodaysRedaingsToCheckHandler} placeholder="Enter Hours" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.siteTodaysRedaingsToCheck}
                                        </div>
                                    </div> */}
                                    {
                                        this.state.siteId && (
                                            <div className="input-label-group col-md-12">
                                                <label className="form-label">Site Login URL </label>
                                                <div className='site-url-div'>
                                                    <CopyToClipboard text={`${WEB_APP_URL}/login/${this.state.clientID}`} onCopy={this.copyHandler}>
                                                        <label className="form-label brand-font p-3 placeholderColor add-meter-input text-transform-none pl-0">
                                                            {`${WEB_APP_URL}/login/${this.state.clientID}`}
                                                            <br />
                                                            <i className={`${COPY_ICON} list-table-icon copy-icon ${this.state.copiedIconClass}`}></i>
                                                        </label>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.addSiteHandler} >Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CreateSitePage