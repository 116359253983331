import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL,  TYPE_SUCCESS, TYPE_FAIL, INTERVAL, SAMPLE_FILE_URL } from 'assets/constants/Constants';
import { EDIT_ICON, TRASH_ICON, CHECK_ICON, CLOSE_ICON} from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import { hasPermission }  from "components/Permission";
import ShowToast from 'components/ShowToast';
import MaterialTable from "material-table";
import CSVFileReader from 'components/CSVFileReader';
import FileDownloader from 'components/FileDownloader';

export class MeterPage extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            isLoading: true,
            input: {
                meterSerialNumber: "",
                meterType: "",
                meterUnit: "",
                meterAMRChannel: "",
                meterStatus: "",
                consumerNumber: ""
            },
            buttonText: "Add Meter",
            meterList: [],
            meterAssignList: [],
            importList: []
        }
    }

    async componentDidMount() {
        const meterData = await this.getAllMeters();
        const assignData =await this.getAssignMeterData();
        this.setState({
            meterList: meterData.data.result,
            meterAssignList: assignData.data.result,
            totalCount: meterData.data.totalCount,
            isLoading: false
        });
    }

    getAssignMeterData = async () => {
        return await axios(`${API_BASE_URL}/route_meter_map/meter/notinroute/${sessionStorage.getItem("siteId")}`);
    }

    getAllMeters = async () => {
        return await axios(`${API_BASE_URL}/meter/site/${sessionStorage.getItem("siteId")}?limit=${this.state.pageSize}&page=${this.state.currentPage}`);
    }

    renderAssignedStatus = (item) => {
        let assignStatus = <i className={`${CHECK_ICON} iconGreenColor`} aria-hidden="true"></i>;
        if(this.state.meterAssignList.length > 0){
            this.state.meterAssignList.forEach((meterItem) => {
                if(meterItem.serial_number === item){
                    assignStatus = <i className={`${CLOSE_ICON} iconRedColor`} aria-hidden="true"></i>
                }
            });
            return assignStatus
        }
        else{
            return assignStatus
        }   
    }

    handleInputTextChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
            input
        });
    }

    addEditUserHandler = (item) => {
        console.log("item::",item)
        if(item){
            const rowItem = {
                _id:item.meterId,
                meter_title: item.meterTitle,
                meter_type:item.meterType,
                site_id:item.siteId,
                meter_rf_address: item.meterRFAddress,
                rf_key : item.rfKey,
                zone: item.zone,
                subZone: item.subZone,
                location: item.location,
                application_type:item.applicationType,
                unit:item.unit,
                serial_number:item.serialNumber,
                status:item.meterStatus,
                flow_unit: item.flowUnit,
                flow_rate: item.flowRate,
                gps_latitude: item.gpsLatitude,
                gps_longitude: item.gpsLongitude,
                gps_accuracy: item.gpsAccuracy,
                hw_version: item.hwVersion,
                sw_version: item.swVersion,
                meter_version: item.meterVersion,
                log_enabled: item.logEnabled,
                alarm_status: item.alarmStatus,
                total_unit: item.totalUnit,
                read_rtc: item.readRTC,
                set_rtc: item.setRTC,
                sensor_factor: item.sensorFactor,
                erase_entire_data_log: item.eraseEntireDataLog,
                consumer_number:item.consumerNumber,
                meter_assign_to_cust: item.meterAssigntoCust,
                meter_receiver_type: item.receivertype,
                reason: item.reason,
                meter_on_off: item.meter_on_off,
                rate_per_liter: item.rate_per_liter,
                money_add: item.money_add,
                allowed_totalizer: item.allowed_totalizer
            }
            this.props.history.push({
                pathname: "/create-meter-page",
                state: rowItem
            });
        }
        else{
            this.props.history.push({
                pathname: "/create-meter-page",
                state: null
            });
        }
    }

    showMeterHandler = (item) => {
        if(item){
            const rowItem = {
                _id:item.meterId,
                site_id:item.siteId,
                serial_number:item.serialNumber,
                meter_type:item.meterType,
                rf_key : item.rfKey,
                meter_rf_address: item.meter_rf_address,
                unit:item.unit,
                amr_channel: item.amr_channel,
                status:item.meterStatus,
                consumer_number:item.consumerNumber,
                customer_name: item.customerName,
                customer_address1:item.customerAddress1,
                customer_address2: item.customerAddress2,
                meter_assign_to_cust: item.meterAssigntoCust,
                route_name:item.routeName,
                pin_code: item.pincode,
                street: item.street,
                city: item.city,
            }
            this.props.history.push({
                pathname: "/meter-view-page",
                state: rowItem
            });
        }
        else{
            this.props.history.push({
                pathname: "/meter-view-page",
                state: null
            });
        }
    }

    showMeterConfigDetails = (item) => {
        if(item){
            const rowItem = {
                _id:item.meterId,
                site_id:item.siteId,
                serial_number:item.serialNumber,
                meter_type:item.meterType,
                rf_key : item.rfKey,
                meter_rf_address: item.meter_rf_address,
                unit:item.unit,
                amr_channel: item.amr_channel,
                status:item.meterStatus,
                consumer_number:item.consumerNumber,
                customer_name: item.customerName,
                customer_address1:item.customerAddress1,
                customer_address2: item.customerAddress2,
                meter_assign_to_cust: item.meterAssigntoCust,
                route_name:item.routeName,
                pin_code: item.pincode,
                street: item.street,
                city: item.city,
                eraseEntireDataLog: item.eraseEntireDataLog,
                flowRate: item.flowRate,
                flowUnit: item.flowUnit,
                gpsAccuracy: item.gpsAccuracy,
                hwVersion: item.hwVersion,
                swVersion: item.swVersion,
                logEnabled: item.logEnabled,
                meterAssigntoCust: item.meterAssigntoCust,
                meterVersion: item.meterVersion,
                readRTC: item.readRTC,
                setRTC: item.setRTC,
                sensorFactor: item.sensorFactor,
                tableData: item.tableData,
                totalUnit: item.totalUnit
            }
            this.props.history.push({
                pathname: "/meter-configuration-page",
                state: rowItem
            });
        }
        else{
            this.props.history.push({
                pathname: "/meter-configuration-page",
                state: null
            });
        }
    }

    handleDelete = async (item) => {
        if(item.customer_id !== null){
            ShowToast(`This meter already assigned to customer, so you could not delete this ${item.meterType} meter!`, TYPE_FAIL, INTERVAL, this.props, ""); 
        }
        else{
            try {
                if (window.confirm(`Are you sure you want to delete this ${item.meterType} meter?`)) {
                    this.setState({isLoading: true});

                    const getMeterDetails = this.state.meterList.find((meterItem) => meterItem._id === item.meterId);
                    let SerialNumber = "";
                    if(getMeterDetails){
                        SerialNumber = getMeterDetails.serial_number;
                    }
                    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
                    const userFirstName = userDetails.first_name;
                    const userLastName = userDetails.last_name; 
                    const userEmailId = userDetails.email_id;
                    const userName = userDetails.username;
                    const userId = userDetails._id;

                    const requestData = {
                        siteId: sessionStorage.getItem("siteId"),
                        SerialNumber,
                        userFirstName,
                        userLastName,
                        userEmailId,
                        userName,
                        userId,
                        reason: `${item.meterType} Meter has been deleted`,
                    }
        
                    console.log(JSON.stringify(requestData));
                    // let deleteResponse = await axios.delete(`${ API_BASE_URL }/meter/${item.meterId}`);
                    let deleteResponse = await axios.post(`${API_BASE_URL}/meter/deleteMeter/${item.meterId}`, requestData);
                    if (deleteResponse.data.success) {
                        await axios.delete(`${API_BASE_URL}/route_meter_map/meter_delete/${item.meterId}`);
                        if (deleteResponse.status === 200) {
                            let fetchResponse = await this.getAllMeters();
                            this.setState({
                                meterList: fetchResponse.data.result,
                                isLoading: false
                            });
                            ShowToast(`${item.meterType} Meter deleted successfully`, TYPE_SUCCESS, INTERVAL, this.props, "");
                        }
                        else {
                            this.setState({
                                isLoading: false
                            });
                            ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
                        }
                    }
                    else{
                        this.setState({
                            isLoading: false
                        });
                        ShowToast(deleteResponse.data.message, TYPE_FAIL, INTERVAL, this.props, "");
                    }
                }
            } catch (error) {
                this.setState({
                    isLoading: false
                });
                console.log("Error: ", error.message);
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
            }
        }
    }

    showMeterReadingHandler = (item) => {
        if(item){
            const rowItem = {
                _id:item.meterId,
                site_id:item.siteId,
                serial_number:item.serialNumber,
                status:item.meterStatus,
                customer_name: item.customerName,
                customer_address1:item.customerAddress1,
                customer_address2: item.customerAddress2,
                route_id:item.routeId,
                pin_code: item.pincode,
                street: item.street,
                city: item.city,
                consumer_number: item.consumerNumber
            }
            this.props.history.push({
                pathname: "/meter-reading",
                state: {rowItem, pageName:"Meter", pathName: "meter-page"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/meter-reading",
                state: null
            });
        }
    }

    handleFile = async (fileData) => {
        if (fileData[0].errors && fileData[0].errors.length === 0) {
            const importPromise = fileData.map( async (importItem, index) => {
                if(index !== 0) {
                    const innerPromiseResult = await new Promise(async (resolve) => {
                        const meterData = await axios(`${API_BASE_URL}/meter/serialNo/${importItem.data[0]}`);
                        if(fileData[index].data[0] && fileData[index].data[1] && fileData[index].data[2] && fileData[index].data[3]){
                            resolve({
                                meter_serial_no: importItem.data[0],
                                meter_type: importItem.data[1],
                                application_type: importItem.data[2],
                                meter_status: importItem.data[3],
                                meter_receiver_type: importItem.data[4],
                                gps_latitude: importItem.data[5],
                                gps_longitude: importItem.data[6],
                                site_id: sessionStorage.getItem("siteId"),
                                is_meter_present: meterData.data.result !==  undefined ? true : false 
                            });
                        }
                        else{
                            resolve(null);
                        }    
                    });
                    return innerPromiseResult;
                }
            });
          
            let importResult = await Promise.all(importPromise);
            importResult =importResult.filter((mapItem) => mapItem !== null);
            importResult = importResult.filter((item) => item);
            if(importResult.length > 0){
                this.setState({
                    importList: [...importResult]
                }) 
            }
        }
        else {
            ShowToast('Something went wrong. Please try after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
        }
    };

    isFormValid = () => {
        let isValid = true;
        let isFlowMeterTypeValid = false
        let isPressureMeterTypeValid = false
        let isLevelMeterTypeValid = false

        this.state.importList.filter((item, index) => {
            if(item.is_meter_present === true){
                isValid = false;
            }
            if (item.meter_type === "Flow") {
                isFlowMeterTypeValid = true; 
            } else if (item.meter_type === "Pressure") {
                isPressureMeterTypeValid = true; 
            } else if (item.meter_type === "Level") {
                isLevelMeterTypeValid = true; 
            }
            return isValid;
        });

        if (!isFlowMeterTypeValid) {
            ShowToast('Please check "Flow" Spelling.', TYPE_FAIL, INTERVAL, this.props, ""); 
            this.setState({
                importList: this.state.importList,
                isLoading: false
            });
        }
        if (!isPressureMeterTypeValid) {
            ShowToast('Please check "Pressure" Spelling.', TYPE_FAIL, INTERVAL, this.props, ""); 
            this.setState({
                importList: this.state.importList,
                isLoading: false
            });
        }
        if (!isLevelMeterTypeValid) {
            ShowToast('Please check "Level" Spelling.', TYPE_FAIL, INTERVAL, this.props, ""); 
            this.setState({
                importList: this.state.importList,
                isLoading: false
            });
        }
    
       
        if(isValid === false){
            ShowToast('Meter already present. Please choose different meter Serial Number and resubmit.', TYPE_FAIL, INTERVAL, this.props, ""); 
            this.setState({
                importList: this.state.importList,
                isLoading: false
            });
        }

        return isValid && isFlowMeterTypeValid && isPressureMeterTypeValid && isLevelMeterTypeValid ;
    }

    importMeterHandler = async (event) => {
        let requestParameter= null;
        event.preventDefault();
        this.setState ({isLoading: true,});
        if(this.isFormValid()){
            const sitePromise = this.state.importList.map(async(item, index) => {
                return new Promise( async (resolve) => {
                    requestParameter = {
                        meterSerialNumber : item.meter_serial_no,
                        meterType : item.meter_type,
                        meterApllicationType : item.application_type,
                        gpsLatitude: item.gps_latitude,
                        gpsLongitude: item.gps_longitude,
                        meterStatus : item.meter_status,
                        meterRecieverType: item.meter_receiver_type,
                        siteId:item.site_id
                    }
                    const meterResponse = await axios.post(`${API_BASE_URL}/meter`, requestParameter);
                    resolve(meterResponse);
                })
            });
            
            const meterResponse = await Promise.all(sitePromise);
            if(meterResponse.length > 0 && meterResponse.length === this.state.importList.length){
                ShowToast('Meter created successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                const meterData = await this.getAllMeters();
                this.setState({
                    meterList: meterData.data.result,
                    importList: [],
                    isLoading: false
                });
            }
        window.location.reload();
        }
    }

    cancelHandler = (event) => {
        window.location.reload();
    }

     getTotalMetersClass = (total) => {
        if (total > 0) {
            return "text-success"; 
        } else   {
            return "text-warning"; 
        } 
    };

    renderMeterSerialNo = (item) => {
        return ((item.is_meter_present === false) ? <span className="iconGreenColor">{item.meter_serial_no + "   "}<i className={`${CHECK_ICON} iconGreenColor`} aria-hidden="true"></i></span> : <span className="iconRedColor">{item.meter_serial_no + "   "}<i className={`${CLOSE_ICON} iconRedColor`} aria-hidden="true"></i></span>);
    }

    renderMeterType = (item) => {
        return ((item.meter_type === "Flow" || item.meter_type === "Pressure" || item.meter_type === "Level" ) ? <span className="iconGreenColor">{item.meter_type + "   "}<i className={`${CHECK_ICON} iconGreenColor`} aria-hidden="true"></i></span> : <span className="iconRedColor">{item.meter_type + "   "}<i className={`${CLOSE_ICON} iconRedColor`} aria-hidden="true"></i></span>);
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            if(this.state.importList.length === 0){
                return (
                    <div className="main-content">
                        <div className="list-wrapper">
                            <div className="back">
                                <ul className="breadcrumb1">
                                    <li>Meter Master</li>
                                </ul>
                            </div>
                            <div>
                                <div className="meter-button-div">
                                {
                                    hasPermission("meter", "create") && (
                                        <>
                                        <button className="brand-button" onClick={() => this.state.selectedItem === null ? this.addEditUserHandler(null) : this.addEditUserHandler(this.state.selectedItem)}>{ this.state.buttonText }</button>
                                        <CSVFileReader handleFile={this.handleFile} />
                                        <label className="csv-link-label" onClick={() =>{FileDownloader(SAMPLE_FILE_URL+ "meter.csv" , "meter.csv")}}>
                                            Sample Import CSV
                                        </label>
                                        </>
                                    )
                                }
                                </div>
                                <div className="mt-4 border list-table-div" style={{marginBottom:50}}>
                                {
                                    hasPermission("meter", "read") ? (
                                    this.state.meterList.length > 0 ? (
                                        <MaterialTable
                                            columns={[
                                                { title: "Serial Number", field: "serialNumber", 
                                                width: null, cellStyle: { width: "15%", fontWeight:'bolder', color:'#000000', fontSize:14 },
                                                },
                                                { title: "Meter Type", field: "meter_type1", 
                                                width: null, cellStyle: { width: '15%', color:'#808080',fontSize:14 },
                                                sorting:false 
                                                },
                                                // { title: "Unit", field: "unit", 
                                                // cellStyle: {cellWidth: '2%', color:'#808080', fontSize:14 },
                                                // sorting: false
                                                // },
                                                { title: "Application Type", field: "applicationType", 
                                                width: null, cellStyle: {width: '14%', color:'#808080' }
                                                },
                                                { title: "Meter Status", field: "status", 
                                                width: null, cellStyle: { width: '12%', color:'#808080', fontSize:14 } 
                                                },
                                                // { title: "Consumer Number", field: "consumerNumber", 
                                                // width: null, cellStyle: {width: '12%', color:'#808080', fontSize:14 } 
                                                // },
                                                { title: "Zone", field: "zone", 
                                                width: null, cellStyle: {width: '14%', color:'#808080' }
                                                },
                                                { title: "Sub Zone", field: "subZone", 
                                                width: null, cellStyle: {width: '14%', color:'#808080' }
                                                },
                                                { title: "Location", field: "location", 
                                                width: null, cellStyle: {width: '14%', color:'#808080' }
                                                },
                                                { title: "Assigned", field: "assigned", 
                                                cellStyle: {cellWidth: '4%', color:'#808080', fontSize:14 }, sorting:false, filtering:false 
                                                },
                                                { title: "Receiver Type", field: "receivertype", cellStyle: (e, rowData) => {
                                                    if (rowData.receivertype === "ami") {
                                                        return { color: "blue", fontSize: 14, fontWeight: "bold"}
                                                    }
                                                    else {
                                                        return { color: "green", fontSize: 14, fontWeight: "bold"}
                                                    }
                                                } },
                                            ]}
                                            data={
                                                this.state.meterList.map((item, index) => {
                                                    let meterStatusClass = "text-success";
                                                    if(item.status === "Active" || item.status === "active"){
                                                        meterStatusClass = "text-success";
                                                    }
                                                    else if(item.status === "Inactive"){
                                                        meterStatusClass = "text-warning";
                                                    }
                                                    else if(item.status === "Faulty"){
                                                        meterStatusClass = "text-danger";
                                                    }
                                                    else{
                                                        meterStatusClass = "text-warning";
                                                    }
                                                    return (
                                                        {
                                                            meterId: item._id,
                                                            meterTitle: item.meter_type === "Flow" ? "Flow" : item.meter_type === "Pressure" ? "Pressure" : item.meter_type === "Level" ? "Level" : "NA",
                                                            meterType: item.meter_type,
                                                            meter_type1: item.meter_type,
                                                            siteId: item.site_id,
                                                            meter_rf_address: item.meter_rf_address,
                                                            rfKey: item.rf_key, 
                                                            applicationType: item.application_type,
                                                            zone: item.zone ? item.zone : "None",
                                                            subZone: item.subZone ? item.subZone : "None",
                                                            location: item.location ? item.location : "None",
                                                            meterAMRChannel1: item.amr_channel,
                                                            //unit: item.unit !== null ? (item.unit === "0" || item.unit === "m3" ? "m3" : item.unit === "1" || item.unit === "L" ? "L" : item.unit === "2" || item.unit === "GAL" ? "GAL" : item.unit === "3" || item.unit === "ft3" ? "ft3" : "NA") : "NA",
                                                            serialNumber: item.serial_number,
                                                            status: <span className={`${meterStatusClass}`}>{item.status}</span>,
                                                            meterStatus:item.status,
                                                            flowUnit: item.flow_unit,
                                                            flowRate: item.flow_rate,
                                                            gpsLatitude: item.gps_latitude,
                                                            gpsLongitude: item.gps_longitude,
                                                            gpsAccuracy: item.gps_accuracy,
                                                            hwVersion: item.hw_version,
                                                            swVersion: item.sw_version,
                                                            meterVersion: item.meter_version,
                                                            logEnabled: item.log_enabled,
                                                            alarmStatus: item.alarm_status,
                                                            totalUnit: item.total_unit,
                                                            readRTC: item.read_rtc,
                                                            setRTC: item.set_rtc,
                                                            sensorFactor: item.sensor_factor,
                                                            eraseEntireDataLog: item.erase_entire_data_log,
                                                            //consumerNumber:  item.consumer_number != null ? item.consumer_number : "none" ,
                                                            assigned: this.renderAssignedStatus(item.serial_number),
                                                            customerAddress1: item.customer_address1,
                                                            customerAddress2: item.customer_address2,
                                                            customerName:item.customer_name,
                                                            customer_id: item.customer_id,
                                                            meterAssigntoCust: item.meter_assign_to_cust,
                                                            routeName: item.route_name,
                                                            routeId: item.route_id,
                                                            pincode: item.pin_code,
                                                            street:item.street,
                                                            city: item.city,
                                                            receivertype: item.meter_receiver_type,
                                                            reason: item.reason,
                                                            meter_on_off: item.meter_on_off,
                                                            rate_per_liter: item.rate_per_liter,
                                                            money_add: item.money_add,
                                                            allowed_totalizer: item.allowed_totalizer,
                                                        }
                                                    )
                                                })
                                            }
                                          //  onRowClick={(event, rowData) => this.showMeterReadingHandler(rowData)}
                                            actions={[
                                                {
                                                    icon: () => <i className={hasPermission("meter", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit Meter"></i> ,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("meter", "update") ? this.addEditUserHandler(rowData) : ""
                                                },
                                                // {
                                                //     icon: () => <i className={hasPermission("meter", "delete") ? `${EYE_ICON}` : "not-active"} title="View"></i>,
                                                //     onClick: (event, rowData) =>
                                                //         hasPermission("meter", "delete") ? this.showMeterHandler(rowData) : ""
                                                // },
                                                // {
                                                //     icon: () => <i className={hasPermission("meter", "read") ? `${CONFIG_ICON}` : "not-active"} title="Meter Configuration Settings"></i>,
                                                //     onClick: (event, rowData) =>
                                                //         hasPermission("meter", "read") ? this.showMeterConfigDetails(rowData) : ""
                                                // },
                                                {
                                                    icon: () => <i className={hasPermission("meter", "delete") ? `${TRASH_ICON}` : "not-active"} title="Delete meter"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("meter", "delete") ? this.handleDelete(rowData) : ""
                                                }
                                            ]}
                                            options={{
                                                actionsColumnIndex: -1,
                                                pageSize:25,
                                                pageSizeOptions:[25,50,75,100],
                                                headerStyle: {
                                                    color: '#999998',
                                                    fontWeight: 'bolder',
                                                    fontSize: 15
                                                },
                                                filtering: true,
                                                sorting: true,
                                                columnsButton: true,
                                                exportButton: true,
                                                exportAllData: true, 
                                                exportFileName: "Meter Data"
                                            }}
                                        />
                                    ):(
                                        <div className="text-center pt-2">
                                            <label>Meter records not found!</label>
                                        </div>
                                    )
                                    ):(
                                        <div className="text-center pt-2">
                                            <label>You dont have permission to see list</label>
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else{
                return(
                    <>
                    <div className="meter-button-div">
                        {
                            hasPermission("meter", "create") && (
                                <>
                                <button className="brand-button" onClick={() => this.state.selectedItem === null ? this.addEditUserHandler(null) : this.addEditUserHandler(this.state.selectedItem)}>{ this.state.buttonText }</button>
                                <CSVFileReader handleFile={this.handleFile} />
                                </>
                            )
                        }
                        </div>
                    <div className="mt-4 border list-table-div big-width">
                    <MaterialTable
                        title = {<div className="csv-title">IMPORTED METER DATA</div>}
                        columns={[
                            { title: "Serial Number", field: "serialNumber", 
                            width: null, cellStyle: { width: "15%", fontWeight:'bolder', color:'#000000', fontSize:14 },
                            },
                            { title: "Meter Type", field: "meterType", 
                            width: null, cellStyle: { width: '15%', color:'#808080',fontSize:14 },
                            sorting:false 
                            },
                            {
                                title: "Application Type", field: "applicationType",
                                width: null, cellStyle: { width: '14%', color: '#808080' }
                            },
                            { title: "Meter Status", field: "meterStatus", 
                            width: null, cellStyle: { width: '12%', color:'#808080', fontSize:14 } 
                            },
                            { title: "Meter Receiver Type", field: "meterRecieverType", 
                            width: null, cellStyle: { width: '12%', color:'#808080', fontSize:14 } 
                            },
                            { title: "Latitude", field: "latitude", 
                            width: null, cellStyle: { width: '12%', color:'#808080', fontSize:14 } 
                            },
                            { title: "Longitude", field: "longitude", 
                            width: null, cellStyle: { width: '12%', color:'#808080', fontSize:14 } 
                            }
                        ]}
                        data={
                            this.state.importList.map((item, index) => {
                                return (
                                    {
                                        serialNumber: this.renderMeterSerialNo(item),
                                        meterType: this.renderMeterType(item),
                                        applicationType: item.application_type,
                                        meterStatus: item.meter_status,
                                        meterRecieverType: item.meter_receiver_type,
                                        latitude: item.gps_latitude,
                                        longitude: item.gps_longitude
                                    }
                                )
                            })
                        }
                        options={{
                            headerStyle: {
                                color: '#999998',
                                fontWeight: 'bolder',
                                fontSize: 15
                            },
                        }}
                    />
                    </div>
                    <div className="site-button-div" style={{marginTop: 30, marginBottom:50, marginLeft:17}}>
                        <button className="brand-button" onClick={ this.importMeterHandler }>Submit</button>
                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                    </div>
                    </>
                )
            }
        }
    }
}

export default MeterPage
