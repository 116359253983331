import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_INFO, INTERVAL, TYPE_FAIL } from "assets/constants/Constants";
import { METER_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import Select from 'react-select';

export class CreateMeterPage extends Component {
    constructor(props) {
        super(props)
    
        const editData = this.props.history.location.state;
        console.log("editData::",editData)
        const userDetail = JSON.parse(sessionStorage.getItem("userData"));
        this.state = {
            isLoading: true,
            meterTitle: editData === null ? null : editData.meter_title,
            meterId: editData === null ? null : editData._id,
            siteId: editData === null ? sessionStorage.getItem("siteId") : editData.site_id,
            meterSerialNo: editData === null ? "" : editData.serial_number,
            ratePerLiter: editData === null ? "" : editData.rate_per_liter,
            moneyAdded: editData === null ?  "" : editData.money_add,
            allowedTotalizer: editData === null ? "" :editData.allowed_totalizer,
            meterType: editData === null ? "" :{label:editData.meter_type, value:editData.meter_type},
            meterAMRChannel: editData === null ? "" : editData.amr_channel,
            meterStatus: editData === null ? "inactive"&&"": {label:editData.status, value:editData.status},
            meterCustomer: editData === null ? "" : editData.meter_assign_to_cust,
            statusReason:editData === null ? "" : editData.reason,
            userDetail: userDetail,
            reasonFlag:false,
            zone:  editData === null ? "" : {label:editData.zone, value: editData.zone},
            subZone:  editData === null ? "" : {label:editData.subZone,value:editData.subZone},
            location: editData === null ? "" : editData.location,
            meterApllicationType: editData === null ? "" : {label:editData.application_type,value:editData.application_type},
            siteList: [],
            meterList: [],
            subzonedisabaled:  editData && editData.subZone !== null ? false : true,
            applicationTypeDisabaled: editData && editData.application_type !== null ?  false : true,
            activeTabIndex: 0,
            meterTypeList: [
                { value: "flow", label: "Flow" },
                { value: "pressure", label: "Pressure" },
                { value: "level", label: "Level"},
                { value: "tempereture", label: "Tempereture"}
            ],
            meterApplicationTypeFlowList:  [
                { value: "forward totalizer", label: "Forward Totalizer" },
                { value: "reverse totalizer ", label: "Reverse Totalizer" },
                { value: "difference totalizer", label: "Difference Totalizer" }
            ],
            meterApplicationTypeLevelList: [
                { value: "level", label: "Level" },
                { value: "distance", label: "Distance" }
            ],
            meterApplicationTypePressureList: [
                { value: "absolute pressure", label: "Absolute Pressure" },
                { value: "gauge pressure ", label: "Gauge Pressure" },
                { value: "differential pressure", label: "Differential Pressure" }
            ],
            meterStatusList: [
                { value: "inactive", label: "Inactive" },
                { value: "active", label: "Active" },
                { value: "faulty", label: "Faulty" },
            ],
            error: {},
            unitList: [
                { value: "m3", label: "m3" },
                { value: "l", label: "l" },
                { value: "ga", label: "ga" },
                { value: "ft", label: "ft" }
            ],
            meterRecieverType: [
                { value: "ami", label: "AMI" },
                { value: "amr", label: "AMR" },
            ],
            meterReciever: editData === null ? "" : editData.meter_receiver_type,
            totaliserUnit: editData === null ? "" : editData.totaliser_unit,
            flowUnit: editData === null ? "" : editData.flow_unit,
            presetTotaliser: editData === null ? "" : editData.preset_totaliser,
            readRTC: editData === null ? "" : editData.read_rtc,
            setRTC: editData === null ? false : editData.set_rtc,
            sensorFactor: editData === null ? "" : editData.sensor_factor,
            flowRate: editData === null ? "" : editData.flow_rate,
            totaliser: editData === null ? "" : editData.totaliser,
            alarmStatus: editData === null ? "" : editData.alarm_status,
            hardwareVersion: editData === null ? "" : editData.hw_version,
            softwareVersion: editData === null ? "" : editData.sw_version,
            meterVersion: editData === null ? "" : editData.meter_version,
            logEnabled: editData === null ? false : editData.log_enabled,
            eraseEntireDataLog: editData === null ? false : editData.erase_entire_data_log,
            latitude: editData === null ? "" : editData.gps_latitude,
            longitude: editData === null ? "" : editData.gps_longitude,
            gpsAccuracy: editData === null ? "" : editData.gps_accuracy,
            routeList: [],
            assignedRoute: editData === null ? "" : editData.assign_route,
            routeOptions: [],
            meterOnAndOff: editData === null ? "ON" : editData.meter_on_off,
            isToggled: editData === null  ? true : editData.meter_on_off === "ON"  ? true : false, 
        }
    }
    
    async componentDidMount() {
        const zoneList = await this.getZoneList();
        this.setState({
            isLoading: false,
            zoneList: zoneList.data.result,
        });
    }
    getZoneList = async () => {
        return await axios.get(`${API_BASE_URL}/zone/subzone/${this.state.siteId}`);
    }

    renderMeterReciverTypeOptions = () => {
        if(this.state.meterTypeList.length > 0){
            return this.state.meterRecieverType.map((meterItem, index) => {
                let isSelected = false;
                if(meterItem.value === this.state.meterReciever){
                    isSelected = true;
                }
                return(
                    <option value={ meterItem.value } selected = { isSelected } key= { meterItem.value } className = "option-text-color"> { meterItem.label } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    serialNumChangeHandler = (event) => {
        this.setState({
            meterSerialNo: event.target.value
        });
    }

    meterTypeChangeHandler = (event) => {
        this.setState({
            meterType: event,
            applicationTypeDisabaled: false,
            meterApllicationType:""
        });
    }

    meterApplicationTypeChangeHandler = (event) => {
        this.setState({
            meterApllicationType: event
        })
    }

    meterApplicationOptionList = (meterType) => {
        
        if (this.state.meterType && this.state.meterType.value) {
            if (this.state.meterType.label === "Flow") {
                return this.state.meterApplicationTypeFlowList;
            } else if (this.state.meterType.label === "Pressure") {
                return this.state.meterApplicationTypePressureList;
            } else if (this.state.meterType.label === "Level") {
                return this.state.meterApplicationTypeLevelList;
            } else {
                return [];
            }
        } else {
            // Handle the case when meterType is null or undefined
            console.warn("meterType is null or undefined");
            return [];
        }
    }

    meterAMRChannelChangeHandler = (event) => {
        this.setState({
            meterAMRChannel: event.target.value,
        });
    }

    meterStatusChangeHandler = (event) => {
        this.setState({
            meterStatus: { label:event.label, value:event.value },
            reasonFlag: event.value === "faulty" ? true : false
        })
    }

    zonesList = (zoneList) => {
        return zoneList.map((item) => {
            return { label: item.zone_name, value: item.zone_name };
        });
    }

    subZonesList = (zoneList, zone) => {
        const subZones = zoneList.filter((item) => {
                return this.state.zone.value === item.zone_name; 
            }).flatMap((item) => {
                return item.sub_zone_name.map(subZone => ({
                    label: subZone,
                    value: subZone
                }));
            });
        return subZones; 
    }


    subZoneChangeHandler = (event) =>{
        this.setState({
            subZone:{ label:event.label, value:event.value },
        })
    }

    zoneChangeHandler = (event) =>{
        this.setState({
            zone:{ label:event.label, value:event.value },
            subZone:"",
            subzonedisabaled:false
        })
    }

    meterReciverTypeChangeHandler = (event) => {
        this.setState({
            meterReciever: event.target.value,
        });
    }

    locationChangeHandler = (event) => {
        this.setState({
            location: event.target.value
        })
    }

    statusReasonChangeHandler = (event) => {
        this.setState({
            statusReason: event.target.value
        });
    }

    latitudeChangeHandler = (event) => {
        this.setState({
            latitude : event.target.value
        });
    }

    longitudeChangeHandler = (event) => {
        this.setState({
            longitude: event.target.value
        });
    }

    checkIfMeterAlreadyPresent = async (event) => {
        event.preventDefault();
        let meterDetails = {
            meterId: null,
            siteId: sessionStorage.getItem("siteId"),
            meterType: "",
            meterAMRChannel: "",
            meterStatus: "inactive",
            meterCustomer: ""
        };

        const meterData = await axios(`${API_BASE_URL}/meter/serialNo/${event.target.value}`);
        if (meterData.status === 200) {
            if (meterData.data.result && meterData.data.result.length > 0) {
                if(meterData.data.result[0].receiver_type !== 'amr'){
                    meterDetails = {
                        meterId: meterData.data.result[0] === null ? null : meterData.data.result[0]._id,
                        siteId: meterData.data.result[0] === null ? sessionStorage.getItem("siteId") : meterData.data.result[0].site_id,
                        meterSerialNo: meterData.data.result[0] === null ? "" : meterData.data.result[0].serial_number,
                        meterType: meterData.data.result[0] === null ? null : meterData.data.result[0].meter_type,
                        meterAMRChannel: meterData.data.result[0] === null ? "" : meterData.data.result[0].amr_channel,
                        meterStatus: meterData.data.result[0] === null ? null : meterData.data.result[0].status,
                        meterCustomer: meterData.data.result[0] === null ? "" : meterData.data.result[0].meter_assign_to_cust,
                        totaliserUnit: meterData.data.result[0] === null ? "" : meterData.data.result[0].totaliser_unit,
                        flowUnit: meterData.data.result[0] === null ? "" : meterData.data.result[0].flow_unit,
                        presetTotaliser: meterData.data.result[0] === null ? "" : meterData.data.result[0].preset_totaliser,
                        readRTC: meterData.data.result[0] === null ? "" : meterData.data.result[0].read_rtc,
                        setRTC: meterData.data.result[0] === null ? false : meterData.data.result[0].set_rtc,
                        sensorFactor: meterData.data.result[0] === null ? "" : meterData.data.result[0].sensor_factor,
                        flowRate: meterData.data.result[0] === null ? "" : meterData.data.result[0].flow_rate,
                        totaliser: meterData.data.result[0] === null ? "" : meterData.data.result[0].totaliser,
                        alarmStatus: meterData.data.result[0] === null ? "" : meterData.data.result[0].alarm_status,
                        hardwareVersion: meterData.data.result[0] === null ? "" : meterData.data.result[0].hw_version,
                        softwareVersion: meterData.data.result[0] === null ? "" : meterData.data.result[0].sw_version,
                        meterVersion: meterData.data.result[0] === null ? "" : meterData.data.result[0].meter_version,
                        logEnabled: meterData.data.result[0] === null ? false : meterData.data.result[0].log_enabled,
                        eraseEntireDataLog: meterData.data.result[0] === null ? false : meterData.data.result[0].erase_entire_data_log,
                        latitude: meterData.data.result[0] === null ? "" : meterData.data.result[0].gps_latitude,
                        longitude: meterData.data.result[0] === null ? "" : meterData.data.result[0].gps_longitude,
                        gpsAccuracy: meterData.data.result[0] === null ? "" : meterData.data.result[0].gps_accuracy,
                        assignedRoute: meterData.data.result[0] === null ? "" : meterData.data.result[0].assign_route,
                    };
                    this.setState({
                        ...meterDetails
                    }, () => {
                        // this.renderMeterTypeOptions();
                        // this.renderMeterAmrChannelOptions();
                        // this.renderMeterStatusOptions();
                    });
                }
                else{
                    ShowToast('Meter Serial No : ' + meterData.data.result[0].serial_number + ' is already auto added as AMI meter.', TYPE_FAIL, INTERVAL, this.props, "");
                    this.setState({meterSerialNo:''})
                    return;
                }
            }
        }
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if(!this.state.meterSerialNo) {
            isValid = false;
            error.meterSerialNo = "Please enter meter serial number";
        }

        if(!this.state.meterType) {
            isValid = false;
            error.meterType = "Please select meter type";
        }
        

        if(!this.state.meterApllicationType) {
            isValid = false;
            error.meterApllicationType = "Please select application type";
        }

        if(!this.state.meterStatus){
            isValid = false;
            error.meterStatus = "please select meter status"
        }

        if(!this.state.statusReason && this.state.reasonFlag){
            isValid = false;
            error.statusReason = "Please enter reason";
        }
        if(!this.state.meterReciever){
            isValid = false;
            error.meterRecieverError = "Please select meter receiver type";
        }

        this.setState({
            error
        });

        return isValid;
    }

    createMeterHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            const requestData = {
                meterType: this.state.meterType.label,
                siteId: this.state.siteId,
                meterApllicationType: this.state.meterApllicationType.label,
                meterSerialNumber: this.state.meterSerialNo,
                zone: this.state.zone.label,
                subZone: this.state.subZone.label,
                location: this.state.location,
                meterStatus: this.state.meterStatus.label,
                meterCustomer: this.state.meterCustomer,
                totalUnit: this.state.totaliserUnit,
                flowUnit: this.state.flowUnit,
                presetTotaliser: this.state.presetTotaliser,
                readRtc: this.state.readRTC,
                setRtc: this.state.setRTC,
                sensorFactor: this.state.sensorFactor,
                flowRate: this.state.flowRate,
                totaliser: this.state.totaliser,
                alarmStatus: this.state.alarmStatus,
                hwVersion: this.state.hardwareVersion,
                swVersion: this.state.softwareVersion,
                meterVersion: this.state.meterVersion,
                logEnabled: this.state.logEnabled,
                eraseEntireDataLog: this.state.eraseEntireDataLog,
                gpsLatitude: this.state.latitude,
                gpsLongitude: this.state.longitude,
                gpsAccuracy: this.state.gpsAccuracy,
                assignedRoute: this.state.assignedRoute,
                userFirstName:this.state.userDetail.first_name,
                userLastName:this.state.userDetail.last_name,
                userEmailId:this.state.userDetail.email_id,
                userName:this.state.userDetail.username,
                userId:this.state.userDetail._id,
                meterOnAndOff: this.state.meterOnAndOff,
                reason : this.state.statusReason === "" ? (this.state.meterId === null ? `New ${this.state.meterType.value} meter created and meter is On` : (this.state.meterOnAndOff === "OFF" ? `${this.state.meterType.label} Meter is Turned OFF` : `${this.state.meterType.label} Meter data updated`)) : this.state.statusReason,
                meterRecieverType:this.state.meterReciever,
                ratePerLiter: this.state.ratePerLiter !== "" ?  this.state.ratePerLiter : 0,
                moneyAdded: this.state.moneyAdded !== "" ?  this.state.moneyAdded :0,
                allowedTotalizer: this.state.allowedTotalizer  !== "" ? this.state.allowedTotalizer : 0
            }

            let meterResponse = null;
            if (this.state.meterId === null) {
                meterResponse = await axios.post(`${API_BASE_URL}/meter`, requestData);
            }
            else {
                meterResponse = await axios.put(`${API_BASE_URL}/meter/${this.state.meterId}`, requestData);
            }
            if (meterResponse.status === 200) {
                const meterType = this.state.meterType;
                const meterTitle = this.state.meterTitle;
                this.setState({
                    meterType: [],
                    meterAMRChannel: "",
                    meterStatus: null,
                }, () => {
                    if (this.state.meterId === null) {
                        if(meterType.label === "Flow"){
                        ShowToast(`Please wait while ${meterType.value} meter is being created.`, TYPE_INFO, INTERVAL, this.props, "");
                        ShowToast(`${meterType.label} meter created Successfully`, TYPE_SUCCESS, INTERVAL, this.props, "/flow-meter-page");
                        }
                        else if(meterType.label === "Pressure"){
                            ShowToast(`Please wait while ${meterType.value} meter is being created.`, TYPE_INFO, INTERVAL, this.props, "");
                            ShowToast(`${meterType.label} meter created Successfully`, TYPE_SUCCESS, INTERVAL, this.props, "/pressure-meter-page");
                        }
                        else if(meterType.label === "Level"){
                            ShowToast(`Please wait while ${meterType.value} meter is being created.`, TYPE_INFO, INTERVAL, this.props, "");
                            ShowToast(`${meterType.label} meter created Successfully`, TYPE_SUCCESS, INTERVAL, this.props, "/level-meter-page");
                        }
                    }
                    else {
                        if(meterType.label === "Flow"){
                        ShowToast(`Please wait while ${meterTitle.toLowerCase()} meter is updated.`, TYPE_INFO, INTERVAL, this.props, "");
                        ShowToast(`${meterTitle} meter updated Successfully`, TYPE_SUCCESS, INTERVAL, this.props, "/flow-meter-page");
                        }
                        else if(meterType.label === "Pressure"){
                            ShowToast(`Please wait while ${meterTitle.toLowerCase()} meter is updated.`, TYPE_INFO, INTERVAL, this.props, "");
                            ShowToast(`${meterTitle} meter updated Successfully`, TYPE_SUCCESS, INTERVAL, this.props, "/pressure-meter-page");
                        }
                        else if(meterType.label === "Level"){
                            ShowToast(`Please wait while ${meterTitle.toLowerCase()} meter is updated.`, TYPE_INFO, INTERVAL, this.props, "");
                            ShowToast(`${meterTitle} meter updated Successfully`, TYPE_SUCCESS, INTERVAL, this.props, "/level-meter-page");
                        }
                    }
                    //this.props.history.push("/meter-page");
                });
            }
        }
    }

    getMeterTitle() {
        const meterTypeForTittle = this.state.meterTitle
        if (this.state.meterId === null || this.state.meterId === "") {
            return "Add New Meter";
        } else if (this.state.meterId !== null && meterTypeForTittle === "Flow") {
            return "Edit Flow Meter";
        } else if (this.state.meterId !== null && meterTypeForTittle === "Pressure") {
            return "Edit Pressure Meter";
        } else if (this.state.meterId !== null && meterTypeForTittle === "Level") {
            return "Edit Level Meter";
        } else {
            return "Unknown Meter";
        }
    }

    cancelHandler = (event) => {
        this.props.history.push("/meter-page");
    }

    handleToggleChange = () => {
        this.setState(prevState => ({
            isToggled: !prevState.isToggled,
            meterOnAndOff: !this.state.isToggled ? "ON" : "OFF",
        }))
    }
    ratePerLiterChangeHandler = (event) => {
        const ratePerLiter = event.target.value;
        const moneyAdded = this.state.moneyAdded;
        const parsedRatePerLiter = parseFloat(ratePerLiter);
        const parsedMoneyAdded = parseFloat(moneyAdded);
        const allowedTotalizer = !isNaN(parsedRatePerLiter) && parsedRatePerLiter !== 0 && !isNaN(parsedMoneyAdded) && parsedMoneyAdded !== 0 
            ? parsedMoneyAdded / parsedRatePerLiter 
            : 0; 
    
        this.setState({
            ratePerLiter: ratePerLiter,
            allowedTotalizer: parseInt(allowedTotalizer)
        });
    }
    
    moneyAddedChangeHandler = (event) => {
        const moneyAdded = event.target.value;
        const ratePerLiter = this.state.ratePerLiter;
        const parsedMoneyAdded = parseFloat(moneyAdded);
        const parsedRatePerLiter = parseFloat(ratePerLiter);
        const allowedTotalizer = !isNaN(parsedRatePerLiter) && parsedRatePerLiter !== 0 && !isNaN(parsedMoneyAdded) && parsedMoneyAdded !== 0 
            ? parsedMoneyAdded / parsedRatePerLiter 
            : 0; 
    
        this.setState({
            moneyAdded: moneyAdded,
            allowedTotalizer: parseInt(allowedTotalizer)
        });
    }

    render() {
        const { zoneList } = this.state;
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/meter-page">Meter Master</a></li>
                            {(() => {
                                const titleForBreadcrumb = this.state.meterTitle;

                                if (this.state.meterId === null || this.state.meterId === "") {
                                    return <li>Add New Meter</li>;
                                } else if (this.state.meterId !== null) {
                                    if (titleForBreadcrumb === "Flow") {
                                        return <li>Edit Flow Meter</li>;
                                    } else if (titleForBreadcrumb === "Pressure") {
                                        return <li>Edit Pressure Meter</li>;
                                    } else if (titleForBreadcrumb === "Level") {
                                        return <li>Edit Level Meter</li>;
                                    } else if (titleForBreadcrumb === "Temperature") {
                                        return <li>Edit Temperature Meter</li>;
                                    }
                                }
                                return null;
                            })()}
                        </ul>
                    </div>
                    <div className="add-meter-main-view">
                        <div className="mx-auto text-left border create-meter-div">
                            <form className="pt-1">
                                <div className="meter-icon-wrapper">
                                    <h4>{this.getMeterTitle()}</h4>
                                    {/* {
                                        this.state.meterId !== null && (
                                            <label class="toggle-switch">
                                                <input type="checkbox"  checked={this.state.isToggled }
                                                    onChange={this.handleToggleChange} />
                                                <span class="slider"></span>
                                            </label>
                                        )
                                    } */}
                                       
                                            <label className="toggle-switch">
                                                <input type="checkbox"  checked={this.state.isToggled }
                                                    onChange={this.handleToggleChange} />
                                                <span className="slider"></span>
                                            </label>
                                  
                                    <i className={`${METER_ICON} list-table-icon`}></i>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Serial Number <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.meterSerialNo } onChange={ this.serialNumChangeHandler } onBlur={this.checkIfMeterAlreadyPresent}  placeholder="Enter Serial Number" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterSerialNo}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Type <span className="text-danger">*</span></label>
                                        <div className="add-select-main-div">
                                          <Select
                                            placeholder={`Select meter type`}
                                            value={this.state.meterType}
                                            options={this.state.meterTypeList}
                                            onChange={this.meterTypeChangeHandler}
                                            isSearchable={true}
                                        />
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterType}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.meterType.label === "Flow" && (
                                        <div className="row">
                                            <div className="input-label-group col-md-4">
                                                <label className="form-label">Rate Per Liter <span className="text-danger">(Rs)</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input text-darker" value={this.state.ratePerLiter} onChange={this.ratePerLiterChangeHandler} placeholder="Enter rate" />
                                            </div>
                                            <div className="input-label-group col-md-4">
                                                <label className="form-label">Recharge Amount <span className="text-danger">(Rs)</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.moneyAdded} onChange={this.moneyAddedChangeHandler} placeholder="Enter money" />
                                            </div>
                                            <div className="input-label-group col-md-4">
                                                <label className="form-label">Rechargeable Liters <span className="text-danger">(Lit)</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.allowedTotalizer} disabled={true} placeholder="Allowed Totalizer" />
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Application Type <span className="text-danger">*</span></label>
                                        <div className="add-select-main-div">
                                          <Select
                                            placeholder={`Select Application type`}
                                            value={this.state.meterApllicationType}
                                            options={this.meterApplicationOptionList(this.state.meterType)}
                                            onChange={this.meterApplicationTypeChangeHandler}
                                            isSearchable={true}
                                            isDisabled={this.state.applicationTypeDisabaled}
                                        />
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterApllicationType}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Status <span className="text-danger">*</span></label>
                                        <div className="add-select-main-div">
                                          <Select
                                            placeholder={`Select Meter Status`}
                                            value={this.state.meterStatus}
                                            options={this.state.meterStatusList}
                                            onChange={this.meterStatusChangeHandler}
                                            isSearchable={true}
                                        />
                                        </div>
                                        <div className="text-danger error-msg">
                                        {this.state.error.meterStatus}
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Receiver Type <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={this.meterReciverTypeChangeHandler} placeholder="Select Meter Receiver Type" >
                                            <option selected value="0" disabled >Select Meter Receiver Type</option>
                                            {
                                                this.renderMeterReciverTypeOptions()
                                            }
                                        </select>
                                    <div className="text-danger error-msg">
                                        {this.state.error.meterRecieverError}
                                    </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Location</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.location} onChange={ this.locationChangeHandler }  placeholder="Enter Location" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.location}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Zone</label>
                                        <div className="add-select-main-div">
                                          <Select
                                            placeholder={`Select Zone`}
                                            value={this.state.zone}
                                            options={this.zonesList(zoneList)}
                                            onChange={this.zoneChangeHandler}
                                            isSearchable={true}
                                        />
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">sub zone</label>
                                        <div className="add-select-main-div">
                                          <Select
                                            placeholder={`Select Subzone`}
                                            isDisabled={this.state.subzonedisabaled}
                                            value={this.state.subZone}
                                            options={this.subZonesList(zoneList,this.state.zone)}
                                            onChange={this.subZoneChangeHandler }
                                            isSearchable={true}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">GPS Latitude</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.latitude } onChange={ this.latitudeChangeHandler } placeholder="Enter GPS Latitude" />
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">GPS Longitude</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.longitude } onChange={ this.longitudeChangeHandler } placeholder="Enter GPS Longitude" />
                                    </div>
                                </div>
                                {
                                    this.state.reasonFlag && (
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Reason <span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.statusReason } onChange={ this.statusReasonChangeHandler } placeholder="Enter reason" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.statusReason}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.createMeterHandler}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div>
            )
        }
    }
}

export default CreateMeterPage
