import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import { MAP_MARKER_ICON ,CLOSE_ICON_BIG} from "assets/constants/Icons";
import { PieChart, LineChartAllMeters, LineChartAllMetersBig, WrappedMapAllMeters, pieDataOne, pieDataTwo, pieDataThree, pieDataFour, lineDataOne, lineDataTwo, lineDataThree, lineDataFour, CustomMarker,markerSVG } from "lib/utils"
import axios from 'axios';
import { API_BASE_URL, TYPE_INFO,TYPE_FAIL, INTERVAL, TYPE_SUCCESS,GOOGLE_MAP_API_KEY } from 'assets/constants/Constants';
import ShowToast from 'components/ShowToast';
import { LtePlusMobiledataTwoTone } from '@mui/icons-material';
import Plotly from 'plotly.js';
import Modal from 'react-modal';
import moment from "moment";
import * as Utils from "lib/utils";
import Spinner from "components/Spinner";
import GraphLoader from "components/GraphLoader";
import createPlotlyComponent from 'react-plotly.js/factory';
import DatePicker from "react-datepicker";

const Plot = createPlotlyComponent(Plotly);


class mainHomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      selectGraphGeneratedValue: "",
      GraphGeneratedValueOptions: [
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Custom", value: "custom" },
      ],
      excelGenerateValue: "daily",
      flowMeterList: [],
      pressureMeterList: [],
      levelMeterList: [],
      metersActiveInactiveCountFlow: [],
      meterActiveAndInactiveCountPressure: [],
      meterActiveAndInactiveCountLevel: [],
      modalIsOpen: false,
      selectedData: null,
      selectedLabel: '',
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      dailyStartDate : moment().subtract(7, 'days').startOf('day').toDate(),
      dailyEndDate :moment().endOf('day').toDate(),
      weeklyStartDate : moment().subtract(7, 'weeks').startOf('day').toDate(),
      weeklyEndDate :moment().endOf('day').toDate(),
      monthlyStartDate : moment().subtract(7, 'months').startOf('day').toDate(),
      monthlyEndDate :moment().endOf('day').toDate(),
      maxEndDate: new Date(),
      flowGraphReadingsData: [],
      flowMeterActiveInactiveData: [],
      pressureMeterActiveInactiveData: [],
      levelMeterActiveInactiveData: [],
      allMetersGraphLoading: false,
    }
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    const metersActiveInactiveCountFlow = await this.getMetersActiveInactiveCountFlow();
    const meterActiveAndInactiveCountPressure = await this.getMetersActiveInactiveCountPressure()
    const meterActiveAndInactiveCountLevel = await this.getMetersActiveInactiveCountLevel()
    const flowMeterData = await this.getAllFlowMeters();
    const pressureMeterData = await this.getAllPressureMeters()
    const levelMeterData = await this.getAllLevelMeters()
    const flowGraphReadingsData = await this.getFlowMetersGraphDataList();
    const pressureGraphReadingsData = await this.getPressureMetersGraphDataList();
    const levelGraphReadingsData = await this.getLevelMetersGraphDataList();
    
    const transformedFlowGraphDataActive = flowGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.flow_meter_active_count : 0
      };
    });
    
    const transformedFlowGraphDataInactive = flowGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.flow_meter_inactive_count : 0
      };
    });

    const transformedFlowGraphDataFaulty = flowGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.flow_meter_faulty_count : 0
      };
    });

    ////2

    const transformedPressureGraphDataActive = pressureGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.pressure_meter_active_count : 0
      };
    });
    
    const transformedPressureGraphDataInactive = pressureGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.pressure_meter_inactive_count : 0
      };
    });

    const transformedPressureGraphDataFaulty = pressureGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.pressure_meter_faulty_count : 0
      };
    });

    ///3

    const transformedLevelGraphDataActive = levelGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.level_meter_active_count : 0
      };
    });

    const transformedLevelGraphDataInactive = levelGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.level_meter_inactive_count : 0
      };
    });

    const transformedLevelGraphDataFaulty = levelGraphReadingsData.data.result.map(item => {
      const date = moment(item.date);
      return {
        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
        y: item.readings ? item.readings.level_meter_faulty_count : 0
      };
    });

  
    const lineDataForFlow = [{
      x: transformedFlowGraphDataActive.map(d => d.x), 
      y: transformedFlowGraphDataActive.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#1f77b4' },
      line: { color: '#1f77b4' },
      borderRadius: '45%',
      name: "Active"
    },
    {
      x: transformedFlowGraphDataInactive.map(d => d.x), 
      y: transformedFlowGraphDataInactive.map(d => d.y), 
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'red' },
      line: { color: 'red' },
      borderRadius: '45%',
      name: "Inactive"
    },
    {
      x: transformedFlowGraphDataFaulty.map(d => d.x),
      y: transformedFlowGraphDataFaulty.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'orange' },
      line: { color: 'orange' },
      borderRadius: '45%',
      name: "Faulty"
    }];


    const lineDataForPressure = [{
      x: transformedPressureGraphDataActive.map(d => d.x), 
      y: transformedPressureGraphDataActive.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#1f77b4' },
      line: { color: '#1f77b4' },
      borderRadius: '45%',
      name: "Active"
    },
    {
      x: transformedPressureGraphDataInactive.map(d => d.x), 
      y: transformedPressureGraphDataInactive.map(d => d.y), 
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'red' },
      line: { color: 'red' },
      borderRadius: '45%',
      name: "Inactive"
    },
    {
      x: transformedPressureGraphDataFaulty.map(d => d.x),
      y: transformedPressureGraphDataFaulty.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'orange' },
      line: { color: 'orange' },
      borderRadius: '45%',
      name: "Faulty"
    }];

    const lineDataForLevel = [{
      x: transformedLevelGraphDataActive.map(d => d.x), 
      y: transformedLevelGraphDataActive.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#1f77b4' },
      line: { color: '#1f77b4' },
      borderRadius: '45%',
      name: "Active"
    },
    {
      x: transformedLevelGraphDataInactive.map(d => d.x), 
      y: transformedLevelGraphDataInactive.map(d => d.y), 
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'red' },
      line: { color: 'red' },
      borderRadius: '45%',
      name: "Inactive"
    },
    {
      x: transformedLevelGraphDataFaulty.map(d => d.x),
      y: transformedLevelGraphDataFaulty.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'orange' },
      line: { color: 'orange' },
      borderRadius: '45%',
      name: "Faulty"
    }];

    this.setState({
      flowMeterList: flowMeterData.data.result,
      pressureMeterList: pressureMeterData.data.result,
      levelMeterList: levelMeterData.data.result,
      metersActiveInactiveCountFlow: metersActiveInactiveCountFlow.data.result[0],
      meterActiveAndInactiveCountPressure: meterActiveAndInactiveCountPressure.data.result[0],
      meterActiveAndInactiveCountLevel:  meterActiveAndInactiveCountLevel.data.result[0],
      flowGraphReadingsData: flowGraphReadingsData.data.redings,
      flowMeterActiveInactiveData: lineDataForFlow,
      pressureMeterActiveInactiveData: lineDataForPressure,
      levelMeterActiveInactiveData: lineDataForLevel,
      isLoading: false
    });
    clearInterval(this.toggleClassInterval);
    clearInterval(this.readingInterval);
    this.toggleClassInterval = null;
    this.readingInterval = null;
  }

  async componentWillUpdate() {
    window.scrollTo(0, 0);
  }

  getAllFlowMeters = async () => {
    return await axios(`${API_BASE_URL}/flow/meter/${sessionStorage.getItem("siteId")}`);
  }
  getAllPressureMeters = async () => {
    return await axios(`${API_BASE_URL}/pressure/meter/${sessionStorage.getItem("siteId")}`);
  }
  getAllLevelMeters = async () => {
    return await axios(`${API_BASE_URL}/level/meter/${sessionStorage.getItem("siteId")}`);
  }

  getMetersActiveInactiveCountFlow = async () => {
    return await axios(`${API_BASE_URL}/meter/activeInactive/counts/flow/${sessionStorage.getItem("siteId")}`);
  }

  getMetersActiveInactiveCountPressure = async () => {
    return await axios(`${API_BASE_URL}/meter/activeInactive/counts/pressure/${sessionStorage.getItem("siteId")}`);
  }

  getMetersActiveInactiveCountLevel = async () =>{
    return await axios(`${API_BASE_URL}/meter/activeInactive/counts/level/${sessionStorage.getItem("siteId")}`);
  }

  getFlowMetersGraphDataList = async () =>{
    return await axios(`${API_BASE_URL}/flow/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue}`)
  }

  getPressureMetersGraphDataList = async () =>{
    return await axios(`${API_BASE_URL}/pressure/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue}`)
  }

  getLevelMetersGraphDataList = async () =>{
    return await axios(`${API_BASE_URL}/level/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue}`)
  }

  flowMeterPageRender = (flowMeterList) => {
    if (flowMeterList.length > 0) {
      this.props.history.push("/flow-meter-page");
    }
    else {
      ShowToast("Flow meters not found", TYPE_INFO, INTERVAL, this.props, "");
    }
  }
  pressureMeterPageRender = (pressureMeterList) => {
    if (pressureMeterList.length > 0) {
      this.props.history.push("/pressure-meter-page");
    }
    else {
      ShowToast("Pressure meters not found", TYPE_INFO, INTERVAL, this.props, "");
    }
  }

  levelMeterPageRender = (levelMeterList) => {
    if (levelMeterList.length > 0) {
      this.props.history.push("/level-meter-page");
    }
    else {
      ShowToast("Level meters not found", TYPE_INFO, INTERVAL, this.props, "");
    }
  }

  flowMeterReadingsPageRender = () => {
    this.props.history.push("/flow-readings-page");
  }

  pressureMeterReadingsPageRender = () => {
    this.props.history.push("/pressure-readings-page");
  }

  levelMeterReadingsPageRender = () => {
    this.props.history.push("/level-readings-page");
  }

  handleGraphClick = (data,label) => {
    this.setState({ selectedData: data, modalIsOpen: true, selectedLabel: label });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, selectedData: null,selectedLabel: '' });
  };

  ChangeHandler =( event) => {
    this.setState({ selectGraphGeneratedValue: { label: event.label, value: event.value }, })
  }

  getAllMetersGraphReportReadings = async () => {
    // if (this.isFormExcelValid()) {
        try {
           
            let interval = 100000;
            this.setState({
              allMetersGraphLoading: true
            })

            if (this.toggleClassInterval && this.toggleClassInterval) {
              Utils.showLoading("Fetching all meters graph data...");
                clearInterval(this.toggleClassInterval);
                clearInterval(this.readingInterval);
                this.toggleClassInterval = null;
                this.readingInterval = null;
            }
            else {
                interval = 0;
            }

            setTimeout(async () => {
              Utils.showLoading("Fetching all meters graph data...");
                const readingsFlowGraphData = await axios(`${API_BASE_URL}/flow/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.selectGraphGeneratedValue.value}`);
                const readingsPressureGraphData = await axios(`${API_BASE_URL}/pressure/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.selectGraphGeneratedValue.value}`);
                const readingsLevelGraphData = await axios(`${API_BASE_URL}/level/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.selectGraphGeneratedValue.value}`);
                Utils.hideLoading();
                if (readingsFlowGraphData.data.result.length > 0) {
                    ShowToast('Graphs generated successfully', TYPE_SUCCESS, INTERVAL, null, "");
                  
                    const transformedFlowGraphDataActive = readingsFlowGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.flow_meter_active_count : 0
                      };
                    });
                    
                    const transformedFlowGraphDataInactive = readingsFlowGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.flow_meter_inactive_count : 0
                      };
                    });
                
                    const transformedFlowGraphDataFaulty = readingsFlowGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.flow_meter_faulty_count : 0
                      };
                    });
                
                    ////2
                
                    const transformedPressureGraphDataActive = readingsPressureGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.pressure_meter_active_count : 0
                      };
                    });
                    
                    const transformedPressureGraphDataInactive = readingsPressureGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.pressure_meter_inactive_count : 0
                      };
                    });
                
                    const transformedPressureGraphDataFaulty = readingsPressureGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.pressure_meter_faulty_count : 0
                      };
                    });
                
                    ///3
                
                    const transformedLevelGraphDataActive = readingsLevelGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.level_meter_active_count : 0
                      };
                    });
                
                    const transformedLevelGraphDataInactive = readingsLevelGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.level_meter_inactive_count : 0
                      };
                    });
                
                    const transformedLevelGraphDataFaulty = readingsLevelGraphData.data.result.map(item => {
                      const date = moment(item.date);
                      return {
                        x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                        y: item.readings ? item.readings.level_meter_faulty_count : 0
                      };
                    });
                
                  
                    const lineDataForFlow = [{
                      x: transformedFlowGraphDataActive.map(d => d.x), 
                      y: transformedFlowGraphDataActive.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: '#1f77b4' },
                      line: { color: '#1f77b4' },
                      borderRadius: '45%',
                      name: "Active"
                    },
                    {
                      x: transformedFlowGraphDataInactive.map(d => d.x),
                      y: transformedFlowGraphDataInactive.map(d => d.y), 
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'red' },
                      line: { color: 'red' },
                      borderRadius: '45%',
                      name: "Inactive"
                    },
                    {
                      x: transformedFlowGraphDataFaulty.map(d => d.x),
                      y: transformedFlowGraphDataFaulty.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'orange' },
                      line: { color: 'orange' },
                      borderRadius: '45%',
                      name: "Faulty"
                    }];

                    const lineDataForPressure = [{
                      x: transformedPressureGraphDataActive.map(d => d.x), 
                      y: transformedPressureGraphDataActive.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: '#1f77b4' },
                      line: { color: '#1f77b4' },
                      borderRadius: '45%',
                      name: "Active"
                    },
                    {
                      x: transformedPressureGraphDataInactive.map(d => d.x), 
                      y: transformedPressureGraphDataInactive.map(d => d.y), 
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'red' },
                      line: { color: 'red' },
                      borderRadius: '45%',
                      name: "Inactive"
                    },
                    {
                      x: transformedPressureGraphDataFaulty.map(d => d.x),
                      y: transformedPressureGraphDataFaulty.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'orange' },
                      line: { color: 'orange' },
                      borderRadius: '45%',
                      name: "Faulty"
                    }];
                
                    const lineDataForLevel = [{
                      x: transformedLevelGraphDataActive.map(d => d.x), 
                      y: transformedLevelGraphDataActive.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: '#1f77b4' },
                      line: { color: '#1f77b4' },
                      borderRadius: '45%',
                      name: "Active"
                    },
                    {
                      x: transformedLevelGraphDataInactive.map(d => d.x), 
                      y: transformedLevelGraphDataInactive.map(d => d.y), 
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'red' },
                      line: { color: 'red' },
                      borderRadius: '45%',
                      name: "Inactive"
                    },
                    {
                      x: transformedLevelGraphDataFaulty.map(d => d.x),
                      y: transformedLevelGraphDataFaulty.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'orange' },
                      line: { color: 'orange' },
                      borderRadius: '45%',
                      name: "Faulty"
                    }];

                    this.setState({
                      flowMeterActiveInactiveData: lineDataForFlow,
                      pressureMeterActiveInactiveData: lineDataForPressure,
                      levelMeterActiveInactiveData: lineDataForLevel,
                      allMetersGraphLoading: false
                    }, () => {
                        console.log("flowExcelReadings::", this.state.flowMeterActiveInactiveData)
                    });
                }
                else {
                  this.setState({
                    allMetersGraphLoading: false
                  })
                    ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
                }
            }, interval);
        } catch (error) {
            ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, "");
            Utils.hideLoading();
        }
    // }
}

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date
    })
  }

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date
    })
  }

  clearSelectedData = async () =>{
    this.setState({
      selectGraphGeneratedValue: ""
    })
  }

  render() {
    const { metersActiveInactiveCountFlow,meterActiveAndInactiveCountPressure,meterActiveAndInactiveCountLevel,screenSize } = this.state
    const { modalIsOpen, selectedData,selectedLabel,flowMeterActiveInactiveData, pressureMeterActiveInactiveData, levelMeterActiveInactiveData } = this.state;
    const FlowactiveCount = metersActiveInactiveCountFlow ? metersActiveInactiveCountFlow.flow_meter_active_count ? metersActiveInactiveCountFlow.flow_meter_active_count : 0 : 0;
    const FlowinactiveCount = metersActiveInactiveCountFlow ? metersActiveInactiveCountFlow.flow_meter_inactive_count ? metersActiveInactiveCountFlow.flow_meter_inactive_count : 0 : 0;
    const FlowfaultyCount = metersActiveInactiveCountFlow ? metersActiveInactiveCountFlow.flow_meter_faulty_count ? metersActiveInactiveCountFlow.flow_meter_faulty_count : 0 : 0;
    const PressureactiveCount = meterActiveAndInactiveCountPressure ? meterActiveAndInactiveCountPressure.pressure_meter_active_count ?  meterActiveAndInactiveCountPressure.pressure_meter_active_count : 0 : 0;
    const PressureinactiveCount = meterActiveAndInactiveCountPressure ?  meterActiveAndInactiveCountPressure.pressure_meter_inactive_count ?  meterActiveAndInactiveCountPressure.pressure_meter_inactive_count: 0 : 0;
    const PressurefaultyCount = meterActiveAndInactiveCountPressure ? meterActiveAndInactiveCountPressure.pressure_meter_faulty_count ? meterActiveAndInactiveCountPressure.pressure_meter_faulty_count  :0 :0;
    const LevelactiveCount = meterActiveAndInactiveCountLevel ? meterActiveAndInactiveCountLevel.level_meter_active_count ?  meterActiveAndInactiveCountLevel.level_meter_active_count : 0 : 0;
    const LevelinactiveCount = meterActiveAndInactiveCountLevel ?  meterActiveAndInactiveCountLevel.level_meter_inactive_count ?  meterActiveAndInactiveCountLevel.level_meter_inactive_count: 0 : 0;
    const LevelfaultyCount = meterActiveAndInactiveCountLevel ? meterActiveAndInactiveCountLevel.level_meter_faulty_count ? meterActiveAndInactiveCountLevel.level_meter_faulty_count  :0 :0;
    const FlowpieData = [
      {
        values: [FlowactiveCount, FlowinactiveCount, FlowfaultyCount],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];
    const pressurepieData = [
      {
        values: [PressureactiveCount, PressureinactiveCount, PressurefaultyCount],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];
    const LevelpieData = [
      {
        values: [LevelactiveCount, LevelinactiveCount, LevelfaultyCount],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];
    const TemperaturepieData = [
      {
        values: [0, 0, 0],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];

    const layout = {
      height: 210,
      width: 215,
      margin: { t: 2, b: 1, l: 5, r: 5 },
      showlegend: true,
      paper_bgcolor: 'transparent',
    };
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        alignItems: "center",
        justifyContent: "center",
        transform: 'translate(-50%, -50%)',
        width: '55%', 
        height: '65%', 
        overflow: 'auto', 
      },
    };
    if (this.state.isLoading) {
      return (
          <Spinner />
      );
  }
  else {
  
    return (
      <div>
        <div style={{ paddingLeft: "12%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "0%", paddingTop: "2%" }}>
          <Card sx={{ maxWidth: 220 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.4)", backgroundColor: "rgb(255, 248, 248)" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/flow-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px" }}>
                Flow
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "100", color: "GrayText" }}>
                A flow water meter is a device used to measure the volume or flow rate of water passing through a specific point in a plumbing system
        </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={() => this.flowMeterPageRender(this.state.flowMeterList)} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button onClick={() => this.flowMeterReadingsPageRender()} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 220 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", backgroundColor: "#FEF7D9" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/pressure-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px" }}>
                Pressure
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "100", color: "GrayText" }}>
                A pressure water meter is an instrument designed to measure the pressure of a fluid at a specific location within a piping system
        </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={() => this.pressureMeterPageRender(this.state.pressureMeterList)} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button onClick={() => this.pressureMeterReadingsPageRender()} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 220 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", backgroundColor: "rgb(246,253,246" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/level-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px" }}>
                Level
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "200", color: "GrayText" }}>
                A level water meter used to measure the height or level of liquid within a specific container in a storage system.
        </Typography>
            </CardContent>
            <CardActions >
              <Button onClick={() => this.levelMeterPageRender(this.state.levelMeterList)} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button onClick={() => this.levelMeterReadingsPageRender()} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 220 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", backgroundColor: "rgb(250, 250, 250)" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/temprature-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px", paddingRight: "25px" }}>
                Temperature
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "100", color: "GrayText" }}>
                A temperature water meter used to measure the heat or temperature of a substance at a specific point in a heating system
        </Typography>
            </CardContent>
            <CardActions>
              <Button style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
        </div>
        <div style={{ height: "50px", backgroundColor: "lightgrey", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "25px auto", width: "100%", fontWeight: "500" }}><span>ACTIVE METERS </span></div>
        <div>
          <div style={{ marginLeft: "140px", display: "flex", justifyContent: "space-between", marginRight: "100PX", paddingBottom: "70vm", paddingTop: "70vm" }}>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Flow</div>
              <div className="small-chart-content">
                {metersActiveInactiveCountFlow ? (
                  <div className="plotly-small-chart">
                    <Plot data={FlowpieData} layout={layout} />
                  </div>
                ) : (
                  <div className="plotly-small-chart-no-data">NO DATA</div>
                )}
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Pressure</div>
              <div className="small-chart-content">
                {meterActiveAndInactiveCountPressure ? (
                  <div className="plotly-small-chart">
                  <Plot data={pressurepieData} layout={layout} />
                </div>
                ):(
                <div className="plotly-small-chart-no-data">NO DATA</div>
                )}
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Level</div>
              <div className="small-chart-content">
              {meterActiveAndInactiveCountLevel ? (
                <div className="plotly-small-chart">
                    <Plot data={LevelpieData} layout={layout} />
              </div>
                ):(
                  <div className="plotly-small-chart-no-data">NO DATA</div>
                )}
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Temperature</div>
              <div className="small-chart-content">
                {/* <div className="plotly-small-chart">
                  <Plot data={TemperaturepieData} layout={layout} />
                </div> */}
                  <div className="plotly-small-chart-no-data">NO DATA</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "50px", backgroundColor: "lightgrey", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "25px auto", width: "100%", fontWeight: "500" }}><span>METER READINGS TRENDING GRAPH</span></div>
        {/* <div style={{ paddingLeft: "5%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "5%", paddingTop: "1%" }}> */}
        <div className="main-content">
          <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
            <div className="row">
              <div className="addUser">
                <label className="form-label">Select Date To Generate  Graph  <span className="text-danger">*</span></label>
                <Select
                  className="site-select-dropdown"
                  style={{ color: "black" }}
                  value={this.state.selectGraphGeneratedValue}
                  placeholder="Select graph view"
                  onChange={this.ChangeHandler}
                  options={this.state.GraphGeneratedValueOptions}
                />
              </div>
            </div>
            {
              this.state.selectGraphGeneratedValue.value === "daily" && (
                <div className="row">
                  <div className="addUser">
                    <label className="form-label">From Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.dailyStartDate}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                  <div className="addUser">
                    <label className="form-label">To Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.dailyEndDate}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              this.state.selectGraphGeneratedValue.value === "weekly" && (
                <div className="row">
                  <div className="addUser">
                    <label className="form-label">From Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.weeklyStartDate}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                  <div className="addUser">
                    <label className="form-label">To Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.weeklyEndDate}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              this.state.selectGraphGeneratedValue.value === "monthly" && (
                <div className="row">
                  <div className="addUser">
                    <label className="form-label">From Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.monthlyStartDate}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                  <div className="addUser">
                    <label className="form-label">To Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.monthlyEndDate}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              this.state.selectGraphGeneratedValue.value === "custom" && (
                <div className="row">
                  <div className="addUser">
                    <label className="form-label">From Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                  <div className="addUser">
                    <label className="form-label">To Date <span className="text-danger">*</span></label>
                    <div className="calender-div">
                      <span className="icon fa-calendar" />
                      <DatePicker className="form-control brand-font p-3"
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy"
                        maxDate={this.state.maxEndDate}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            <div className="addButton">
              <button id="button" className="brand-button report-button" onClick={this.getAllMetersGraphReportReadings}>Get Report</button>
              <button
                id="download-excel-button"
                className="download-table-xls-button brandxls-button download-button"
                onClick={this.clearSelectedData}>clear
                </button>
            </div>
          </div>
        </div>
          <div style={{ paddingLeft: "10%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "2%", paddingTop: "4%" }}>
          <div className="small-chart-individual compliance-small-chart">
            <div className="small-chart-label">Flow</div>
            <div className="small-chart-content">
              <div className="plotly-small-chart">
                {this.state.allMetersGraphLoading ? (
                  <div className="graph-spinner-wrapper">
                    <GraphLoader />
                  </div>
                ) : metersActiveInactiveCountFlow ? (
                  <div onClick={() => this.handleGraphClick(flowMeterActiveInactiveData, "FLOW")}>
                    <LineChartAllMeters data={flowMeterActiveInactiveData} />
                  </div>
                ) : (
                  <div style={{ justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
                    NO DATA
                  </div>
                )}
              </div>
            </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Pressure</div>
              <div className="small-chart-content">
              <div className="plotly-small-chart">
                {this.state.allMetersGraphLoading ? (
                  <div className="graph-spinner-wrapper">
                    <GraphLoader />
                  </div>
                ) : meterActiveAndInactiveCountPressure? (
                  <div onClick={() => this.handleGraphClick(pressureMeterActiveInactiveData, "PRESSURE")}>
                    <LineChartAllMeters data={pressureMeterActiveInactiveData} />
                  </div>
                ) : (
                  <div style={{ justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
                    NO DATA
                  </div>
                )}
              </div>
            </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Level</div>
              <div className="small-chart-content">
              <div className="plotly-small-chart">
                {this.state.allMetersGraphLoading ? (
                  <div className="graph-spinner-wrapper">
                    <GraphLoader />
                  </div>
                ) :meterActiveAndInactiveCountLevel? (
                  <div onClick={() => this.handleGraphClick(levelMeterActiveInactiveData, "LEVEL")}>
                    <LineChartAllMeters data={levelMeterActiveInactiveData} />
                  </div>
                ) : (
                  <div style={{ justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
                    NO DATA
                  </div>
                )}
              </div>
            </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Temperature</div>
              <div className="small-chart-content">
              <div className="plotly-small-chart">
                  {/* <div onClick={() => this.handleGraphClick(lineDataFour,"TEMPERATURE")}>
                    <LineChartAllMeters data={lineDataFour} />
                  </div> */}
                  <div style={{justifyContent:"center", alignItems:"center", marginTop:"100px"}}>NO DATA</div>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={modalIsOpen} onRequestClose={this.closeModal} style={customStyles} >
            <i onClick={this.closeModal} style={{ float: "right",color: 'blue', backgroundColor:"white" }} className={CLOSE_ICON_BIG}></i>
            {selectedData && (
              <div style={{ width: '100%', height: '100%' }}>
                <h4 style={{textAlign: "center", justifyContent: "center", alignItems:"center",fontWeight : "bold", color: 'blue'}}>{selectedLabel}</h4>
                <LineChartAllMetersBig data={selectedData} />
              </div>
            )}
          </Modal>
        {/* </div> */}
        <div style={{ height: "50px", backgroundColor: "lightgrey", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "25px auto", width: "100%", fontWeight: "500" }}><span>ALL METER MAP VIEW</span></div>
        <div style={{ paddingLeft: "10%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "2%", paddingTop: "0%" }}>
          <i style={{ paddingLeft: "50px",fontSize: "25px", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-flow  `}> Flow</i>
          <i style={{ paddingLeft: "10px",fontSize: "25px", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-pressure  `}> Pressure</i>
          <i style={{ paddingLeft: "10px",fontSize: "25px", fontFamily: "sans-serif", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-level  `}> Level</i>
          <i style={{ paddingLeft: "10px", fontSize: "25px", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-tempereture  `}> Temperature</i>
        </div>
        <div>
          <div style={{ height: "100%", paddingLeft: "150px", display: "flex", justifyContent: "space-between", paddingRight: "100PX", paddingBottom: "50px" }}>
            <WrappedMapAllMeters />
          </div>
        </div>
      </div>
    );
  }
}
}
export default mainHomePage;
