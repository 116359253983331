import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { REFRESH_ICON, EXCEL_DWN_ICON, GRAPH_ICON, MAP_MARKER_ICON } from "assets/constants/Icons";
import Spinner from "components/Spinner";
import GraphLoader from "components/GraphLoader";
import MaterialTable from "material-table";
import Select from 'react-select';
import moment from "moment";
import * as Utils from "lib/utils";
import ShowToast from 'components/ShowToast';
import DatePicker from "react-datepicker";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LineChart, lineDataOne, WrappedMap } from "lib/utils"
import { validateIsoDateFormat, defaultFormatDateTime, validateBitStatusAlarms, defaultFormatDate,setFlowUnit151,setTotalizerUnit151,getAlarmType153RWM, getAlarmTypeFor151BO,setTotalizerUnit153,setFlowUnit153, validationFor151, validationFor153   } from "lib/utils"

export class FlowMeterReadingPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            meterFlowReadings: [],
            zone: "",
            subZone: "",
            flowExcelReadings: [],
            isSyncVisible: true,
            isDropDown: false,
            subzonedisabaled: true,
            cancelButtondisabaled: true,
            excelGenerateValue: "",
            graphGenerateValue: "daily",
            startDate: moment().startOf('day').toDate(),
            endDate: moment().endOf('day').toDate(),
            dailyStartDate : moment().subtract(7, 'days').startOf('day').toDate(),
            dailyEndDate :moment().endOf('day').toDate(),
            weeklyStartDate : moment().subtract(7, 'weeks').startOf('day').toDate(),
            weeklyEndDate :moment().endOf('day').toDate(),
            monthlyStartDate : moment().subtract(7, 'months').startOf('day').toDate(),
            monthlyEndDate :moment().endOf('day').toDate(),
            maxEndDate: new Date(),
            flowAllMeterData: [],
            flowGraphLoading: false,
            excelAndGraphGenerateOptionList: [
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Monthly", value: "monthly" },
                { label: "Custom", value: "custom" }
            ],
            selectTotalizerUnitOptions: [
                { label: "M3", value: "m3" },
                { label: "Lit", value: "lit" },
            ],
            selectedValueM3: false,
            selectedValueForLit: false,
            selectTotalizerUnit : {label: "M3", value: "m3"},
            error: {},
            zoneAndSubzoneList: [],
            liveSyncIconClass: "enable-icon",
            flowGraphReadings: [],
            toggleLiveSyncIconClass: true,
            siteId: sessionStorage.getItem('siteId')
        }
        this.mainContentRef = React.createRef();
        this.liveSyncIconClassToggleInterval = null;
        this.meterReadingsGetInterval = null;
        this.toggleClassInterval = null;
        this.readingInterval = null;
    }


    arrangeFlowReadingList = (array) => {
        const flowMeterListArray = [...this.state.meterFlowReadings];
        if (Array.isArray(array)) {
            array.forEach((item) => {
                const index = flowMeterListArray.findIndex(findeItem => findeItem.meter_serial_no === item.meter_serial_no);
                if (index === -1) {
                    flowMeterListArray.push(item)
                }
                else {
                    flowMeterListArray[index].meter_reading = item.meter_reading;
                    flowMeterListArray[index].meter_status = item.meter_status;
                    flowMeterListArray[index].receive_date_time = item.receive_date_time;
                    flowMeterListArray[index].alarm_type = item.alarm_type;
                    flowMeterListArray[index].created_date_time = item.created_date_time;
                    flowMeterListArray[index].updated_date_time = item.updated_date_time;
                }
            });
            return flowMeterListArray;
        }
        else {
            return array;
        }
    }

    async componentDidMount() {
        const meterFlowReadings = await this.getMeterFlowReadings();
        const zoneAndSubzoneList = await this.getZoneAndSubzoneList();
        const flowGraphReadingsData = await this.getFlowRedaingsGraphDataList()
        const flowAllMeterData = await this.getAllFlowMeters();
        const updatedFlowList = this.arrangeFlowReadingList(meterFlowReadings.data.result)
        this.liveSyncIconClassToggleInterval = setInterval(() => {
            this.setState({
                toggleLiveSyncIconClass: !this.state.toggleLiveSyncIconClass,
                liveSyncIconClass: this.state.toggleLiveSyncIconClass ? "enable-icon" : "disable-icon"
            });
        },60000);

        this.meterReadingsGetInterval = setInterval(async () => {
            const meterFlowReadings = await this.getMeterFlowReadings();
            const updatedFlowList = this.arrangeFlowReadingList(meterFlowReadings.data.result)
            this.setState({
                meterFlowReadings: updatedFlowList
            });
        },60000);

        const transformedFlowGraphData = flowGraphReadingsData.data.result.map(item => {
            const date = moment(item.date); 
            return {
                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                y: item.readings 
            };
        });

        const lineData = [{
            x: transformedFlowGraphData.map(d => d.x),
            y: transformedFlowGraphData.map(d => d.y), 
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: '#1f77b4' },
            line: { color: '#1f77b4' },
            borderRadius: '45%',
        }];

        this.setState({
            meterFlowReadings: updatedFlowList,
            flowGraphReadings: lineData,
            zoneAndSubzoneList: zoneAndSubzoneList.data.result,
            flowAllMeterData: flowAllMeterData.data.result,
            isLoading: false
        });

        clearInterval(this.toggleClassInterval);
        clearInterval(this.readingInterval);
        this.toggleClassInterval = null;
        this.readingInterval = null;
    }

    componentWillUnmount() {
        clearInterval(this.liveSyncIconClassToggleInterval);
        clearInterval(this.meterReadingsGetInterval);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("123")
        const { selectTotalizerUnit, selectedValueM3, selectedValueForLit } = this.state;
        const filteredFlowMeterReadings = this.filterMeterReadings();

        if (selectTotalizerUnit.value === "m3" && !selectedValueM3) {
            this.filteredM3TotalizerValue(filteredFlowMeterReadings);
            this.setState({ selectedValueM3: true });
        }

        if (selectTotalizerUnit.value === "lit" && !selectedValueForLit) {
            this.filteredLitTotalizerValue(filteredFlowMeterReadings);
            this.setState({ selectedValueForLit: true });
        }
    }

    getMeterFlowReadings = async () => {
        return await axios.get(`${API_BASE_URL}/flow/readings/${sessionStorage.getItem('siteId')}`);
    }

    getZoneAndSubzoneList = async () => {
        return await axios.get(`${API_BASE_URL}/zone/subzone/${sessionStorage.getItem('siteId')}`);
    }

    getFlowRedaingsGraphDataList = async () => {
        return await axios(`${API_BASE_URL}/flow/graph/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.graphGenerateValue}`)
    }

    getAllFlowMeters = async () => {
        return await axios(`${API_BASE_URL}/flow/meter/${sessionStorage.getItem("siteId")}`);
    }  


    filterMeterReadings = () => {
        const {selectTotalizerUnit, meterFlowReadings, zone, subZone } = this.state;
          console.log("selectTotalizerUnit::",selectTotalizerUnit)
        if (zone || subZone || selectTotalizerUnit) {
            return meterFlowReadings.filter(reading => {
                const matchesZone = zone ? reading.meterData.zone === zone.value : true;
                const matchesSubZone = subZone ? reading.meterData.subZone === subZone.value : true;
                const matchesTotalizerUnit = selectTotalizerUnit ? validationFor151(reading.totalizer_unit) === selectTotalizerUnit.value : true
                console.log("matchesTotalizerUnit::",matchesTotalizerUnit)
               
                return matchesZone && matchesSubZone && matchesTotalizerUnit;
            });
        }
        return meterFlowReadings;
    }

    zonesList = (zoneList) => {
        return zoneList.map((item) => {
            return { label: item.zone_name, value: item.zone_name };
        });
    }

    subZonesList = (zoneList, zone) => {
        const subZones = zoneList.filter((item) => {
            return this.state.zone.value === item.zone_name;
        }).flatMap((item) => {
            return item.sub_zone_name.map(subZone => ({
                label: subZone,
                value: subZone
            }));
        });
        return subZones;
    }

    subZoneChangeHandler = (event) => {
        this.setState({
            subZone: { label: event.label, value: event.value },
            cancelButtondisabaled: false
        })
    }

    zoneChangeHandler = (event) => {
        this.setState({
            zone: { label: event.label, value: event.value },
            subZone: "",
            subzonedisabaled: false,
            cancelButtondisabaled: false
        })
    }

    selectTotalizerUnitChangeHandler = (event) => {
        this.setState({
            selectTotalizerUnit: { label: event.label, value: event.value },
            cancelButtondisabaled: false
        })
    }


    excelGenerateChangeHandler = (event) => {
        this.setState({
            excelGenerateValue: { label: event.label, value: event.value },
            flowExcelReadings: ""
        })
        var excelDownloadBtn = document.getElementById("download-excel-button");
        excelDownloadBtn.classList.remove("mystyle");
    }

    graphGenerateChangeHandler = (event) => {
        this.setState({
            graphGenerateValue: { label: event.label, value: event.value },
        })
    }

    handleStartDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleEndDateChange = (date) => {
        this.setState({
            endDate: date
        })
    }

    isFormExcelValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.excelGenerateValue) {
            isValid = false;
            error.excelGenerateValue = "Please select date";
        }

        this.setState({
            error
        });

        return isValid;
    }

    isFormGraphValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.graphGenerateValue) {
            isValid = false;
            error.graphGenerateValue = "Please select date";
        }

        this.setState({
            error
        });

        return isValid;
    }

    getFlowReportReadings = async (filteredFlowMeterReadings) => {
        if (this.isFormExcelValid()) {
            try {
                this.setState({
                    flowExcelReadings: [],
                    isSyncVisible: false,
                    flowGraphLoading: true
                });
                let interval = 100000;

                if (this.toggleClassInterval && this.toggleClassInterval) {
                    Utils.showLoading("Fetching all flow meters report data...");
                    clearInterval(this.toggleClassInterval);
                    clearInterval(this.readingInterval);
                    this.toggleClassInterval = null;
                    this.readingInterval = null;
                }
                else {
                    interval = 0;
                }
                const data = {
                    filteredFlowMeterReadings:[...filteredFlowMeterReadings]
                }
                setTimeout(async () => {
                    Utils.showLoading("Fetching all flow meters report data...");
                    let readingFlowData
                    if(this.state.selectTotalizerUnit.label === "M3"){
                        readingFlowData = await axios.post(`${API_BASE_URL}/flow/excel/readings/m3/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`,data);
                    }
                    else if(this.state.selectTotalizerUnit.label === "Lit") {
                        readingFlowData = await axios.post(`${API_BASE_URL}/flow/excel/readings/lit/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`, data);
                    }
                    else{
                        readingFlowData = await axios(`${API_BASE_URL}/flow/excel/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`);
                    }

                    let readingsFlowGraphData
                    if(this.state.selectTotalizerUnit.label === "M3"){
                         readingsFlowGraphData = await axios.post(`${API_BASE_URL}/flow/graph/readings/m3/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`, data)
                    }
                    else if(this.state.selectTotalizerUnit.label === "Lit"){
                        readingsFlowGraphData = await axios.post(`${API_BASE_URL}/flow/graph/readings/lit/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`, data);
                    }
                    else{
                        readingsFlowGraphData = await axios(`${API_BASE_URL}/flow/graph/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue.value}`);
                    }

                    Utils.hideLoading();
                    if (readingFlowData.data.result.length > 0 && readingsFlowGraphData.data.result.length > 0  ) {
                        ShowToast('Report created successfully', TYPE_SUCCESS, INTERVAL, null, "");
                        var excelDownloadBtn = document.getElementById("download-excel-button");
                        if (excelDownloadBtn) {
                            excelDownloadBtn.classList.add("mystyle");
                        }
                        console.log("readingsFlowGraphData::",readingsFlowGraphData)
                        const transformedData = readingsFlowGraphData.data.result.map(item => {
                            const date = moment(item.date); 
                            return {
                                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                                y: item.readings 
                            };
                        });

                        const lineData = [{
                            x: transformedData.map(d => d.x), 
                            y: transformedData.map(d => d.y), 
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: '#1f77b4' },
                            line: { color: '#1f77b4' },
                            borderRadius: '45%',
                        }];
                        this.setState({
                            flowExcelReadings: readingFlowData.data.result,
                            flowGraphReadings: lineData,
                            flowGraphLoading: false
                        }, () => {
                            console.log("flowExcelReadings::", this.state.flowExcelReadings)
                        });
                    }
                    else {
                        ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
                        this.filteredM3TotalizerValue(filteredFlowMeterReadings);
                        this.setState({
                            flowGraphLoading: false,
                            excelGenerateValue: "",
                            selectTotalizerUnit : {label: "M3", value: "m3"},
                        })
                    }
                }, interval);
            } catch (error) {
                ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, "");
                Utils.hideLoading();
            }
        }
    }

    exportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Zone",
                "Subzone",
                "Location",
                "Flowrate",
                "Totalizer",
                "yesterdays Consumption",
                "Meter Status",
                "Meter Date And Time",
                "Network Date And Time",
            ];

            const headerDataMapping = {
                "Serial Number": "serialNumber",
                "Zone": "zone",
                "Subzone": "subZone",
                "Location": "location",
                "Flowrate": "flowRate",
                "Totalizer": "totalizer",
                "yesterdays Consumption": "yesterdaysConsumptionValue",
                "Meter Status": "status",
                "Meter Date And Time": "startTimeStamp",
                "Network Date And Time": "networkTimeStamp",
            };

            const dateFormatArray = ["networkTimeStamp","startTimeStamp"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.flowExcelReadings.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `flow-meter-readings-report-${this.state.excelGenerateValue.value === "custom" ? `From ${defaultFormatDate(this.state.startDate)} To ${defaultFormatDate(this.state.endDate)}` : this.state.excelGenerateValue.value} ${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
            this.setState({ excelGenerateValue: "" })
            var excelDownloadBtn = document.getElementById("download-excel-button");
            excelDownloadBtn.classList.remove("mystyle");
        } catch (error) {
            console.error(error);
        }
    };

    handleMenuOpen = () => {
        this.setState({ isDropDown: true });
    };

    handleMenuClose = () => {
        this.setState({ isDropDown: false });
    };

    scrollToTop = () => {
        this.mainContentRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    generateGraphForDailyForAllMetres = async () => {
        this.setState({flowGraphLoading: true})
        const flowGraphReadingsData = await this.getFlowRedaingsGraphDataList()

        const transformedFlowGraphData = flowGraphReadingsData.data.result.map(item => {
            const date = moment(item.date); 
            return {
                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                y: item.readings 
            };
        });
  
        const lineData = [{
            x: transformedFlowGraphData.map(d => d.x), 
            y: transformedFlowGraphData.map(d => d.y), 
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: '#1f77b4' },
            line: { color: '#1f77b4' },
            borderRadius: '45%',
        }];

        this.setState({
            flowGraphReadings: lineData,
            flowGraphLoading: false
        });
    }

    cancelHandler = (event) => {
        this.setState({
            zone: "",
            subZone: "",
            selectTotalizerUnit: {label: "M3", value: "m3"},
            subzonedisabaled: true,
            cancelButtondisabaled: true,
            excelGenerateValue: ""
        })
        var excelDownloadBtn = document.getElementById("download-excel-button");
        excelDownloadBtn.classList.remove("mystyle");
        // this.generateGraphForDailyForAllMetres()
    }

    showMeterReadingHandler = (item) => {
        if (item) {
            const rowItem = {
                serial_number: item.meter_serial_no,
                ...item
            }
            this.props.history.push({
                pathname: "/meter-reading",
                state: { rowItem, pageName: "Flow Readings", pathName: "flow-readings-page" }
            });
        }
        else {
            this.props.history.push({
                pathname: "/meter-reading",
                state: null
            });
        }
    }

    filteredM3TotalizerValue = async (filteredFlowMeterReadings) => {
        console.log("filteredFlowMeterReadings::",filteredFlowMeterReadings)
        this.setState({flowGraphLoading: true, selectTotalizerUnit: {label: "M3", value: "m3"}})
        const data = {
            filteredFlowMeterReadings:[...filteredFlowMeterReadings]
        }
        let flowGraphReadingsData = await axios.post(`${API_BASE_URL}/flow/m3/graph/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.graphGenerateValue}`, data);
        const transformedFlowGraphData = flowGraphReadingsData.data.result.map(item => {
            const date = moment(item.date); 
            return {
                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                y: item.readings 
            };
        });


        const lineData = [{
            x: transformedFlowGraphData.map(d => d.x),
            y: transformedFlowGraphData.map(d => d.y),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: '#1f77b4' },
            line: { color: '#1f77b4' },
            borderRadius: '45%',
        }];

        this.setState({
            flowGraphReadings: lineData,
            flowGraphLoading: false,
            selectedValueForLit: false
        })
    }

    filteredLitTotalizerValue = async (filteredFlowMeterReadings) => {
        this.setState({flowGraphLoading: true})
        const data = {
            filteredFlowMeterReadings:[...filteredFlowMeterReadings]
        }
        console.log("data::",data.filteredFlowMeterReadings.length)
        if(data.filteredFlowMeterReadings.length > 0) {
        let flowGraphReadingsData = await axios.post(`${API_BASE_URL}/flow/lit/graph/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.graphGenerateValue}`, data);
        console.log("flowGraphReadingsData-lit::",flowGraphReadingsData)
        const transformedFlowGraphData = flowGraphReadingsData.data.result.map(item => {
            const date = moment(item.date); 
            return {
                x: date.local().format('YYYY-MM-DD HH:mm:ss'),
                y: item.readings 
            };
        });

        const lineData = [{
            x: transformedFlowGraphData.map(d => d.x),
            y: transformedFlowGraphData.map(d => d.y),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: '#1f77b4' },
            line: { color: '#1f77b4' },
            borderRadius: '45%',
        }];

        this.setState({
            flowGraphReadings: lineData,
            flowGraphLoading: false,
            selectedValueM3: false
        })
    }
    else{
        ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
        this.setState({
            flowGraphLoading: false,
            selectedValueM3: false,
            selectTotalizerUnit: {label: "M3", value: "m3"}
        })
    }
    }

    render() {
        const { zoneAndSubzoneList,selectTotalizerUnit } = this.state;
        if (this.state.isLoading) {
            return (
                <Spinner />
            );
        }
        else {
            const filteredFlowMeterReadings = this.filterMeterReadings();
            const totalYesterdaysComspution = filteredFlowMeterReadings.reduce((acc, element) => {
                const value = element.yesterdayComsputionData !== null ? parseFloat(element.yesterdayComsputionData.yesterdays_totalizer_calculation_value) : 0;
                return acc + value;
            }, 0).toFixed(2);

            const totalTodaysComspution = filteredFlowMeterReadings.reduce((acc, element) => {
                const value = element.yesterdayComsputionData !== null ?  defaultFormatDate(element.created_date_time) === defaultFormatDate(this.state.maxEndDate) ? ((element.forward_totalizer || 0.00) - (element.yesterdayComsputionData.today_totalizer_reading || 0.00 )) : 0 : 0;
                return acc + value;
            }, 0).toFixed(2);
            
            console.log(totalTodaysComspution);
            
            const data = [
                ...filteredFlowMeterReadings.map((element, index) => ({
                    // sn: index + 1,
                    zone: element.meterData.zone,
                    subzone: element.meterData.subZone,
                    location: element.meterData.location,
                    meter_serial_no: element.meter_serial_no,
                    yesterdays_comspution:  element.meterData.application_type === "Forward Totalizer" && element.yesterdayComsputionData !== null ?  element.device_type === "151BO" ? setTotalizerUnit151(element.yesterdayComsputionData.yesterdays_totalizer_calculation_value.toFixed(2) ,element.totalizer_unit): setTotalizerUnit153(element.yesterdayComsputionData.yesterdays_totalizer_calculation_value.toFixed(2) ,element.totalizer_unit) : "NA",
                    todays_comspution:  defaultFormatDate(element.created_date_time) === defaultFormatDate(this.state.maxEndDate) ? element.meterData.application_type === "Forward Totalizer" && element.yesterdayComsputionData !== null ?  element.device_type === "151BO" ? setTotalizerUnit151((element.forward_totalizer - element.yesterdayComsputionData.today_totalizer_reading).toFixed(2),element.totalizer_unit) : setTotalizerUnit153((element.forward_totalizer - element.yesterdayComsputionData.today_totalizer_reading).toFixed(2),element.totalizer_unit)  : element.meterData.application_type === "Reverse Totalizer" ? (element.reverse_totalizer - element.yesterdayComsputionData.today_totalizer_reading).toFixed(2) : element.meterData.application_type === "Diffrence Totalizer" ? (element.totalizer_diffrence - element.yesterdayComsputionData.today_totalizer_reading).toFixed(2) : "NA": element.device_type === "151BO" ? setTotalizerUnit151((0).toFixed(2),element.totalizer_unit) || 0.00: setTotalizerUnit153((0).toFixed(2),element.totalizer_unit)  || 0.00 ,
                    totalizer: element.meterData.application_type === "Forward Totalizer" ? (element.device_type === "151BO" ? (element.forward_totalizer !== undefined ? setTotalizerUnit151(element.forward_totalizer, element.totalizer_unit) : "NA") : (element.forward_totalizer !== undefined ? setTotalizerUnit153(element.forward_totalizer, element.totalizer_unit) : "NA")) : element.meterData.application_type === "Reverse Totalizer" ? (element.device_type === "151BO" ? (element.reverse_totalizer !== undefined ? setTotalizerUnit151(element.reverse_totalizer, element.totalizer_unit) : "NA") : (element.reverse_totalizer !== undefined ? setTotalizerUnit153(element.reverse_totalizer, element.totalizer_unit) : "NA")) : element.meterData.application_type === "Difference Totalizer" ? (element.totalizer_difference !== undefined ? element.totalizer_difference : "NA") : "NA",
                    flowrate: element ? (element.device_type === "151BO" ? (element.flow_rate !== undefined ? setFlowUnit151(element.flow_rate, element.flow_unit) : "NA") : (element.flow_rate !== undefined ? setFlowUnit153(element.flow_rate, element.flow_unit) : "NA")) : "NA",
                    receive_date_time: validateIsoDateFormat(element.created_date_time) ? defaultFormatDateTime(element.created_date_time) : element.created_date_time,
                    status: element.alarm_type ? element.device_type === "151BO" ? getAlarmTypeFor151BO(element.alarm_type) : element.device_type === "153RWM" ? getAlarmType153RWM(element.alarm_type) : "NA":"NA",
                })),
                // Add a footer row for totals
            ];
            const totalRow = {
                // sn: "Total",
                zone: "Total",
                subzone: "",
                location: "",
                meter_serial_no: "",
                yesterdays_comspution: this.state.selectTotalizerUnit.value === "m3" ? totalYesterdaysComspution + " " + "m3" :  this.state.selectTotalizerUnit.value === "lit" ? totalYesterdaysComspution + " " + "lit" : totalYesterdaysComspution ,
                todays_comspution: this.state.selectTotalizerUnit.value === "m3" ? totalTodaysComspution + " " + "m3" :  this.state.selectTotalizerUnit.value === "lit" ? totalTodaysComspution + " " + "lit" : totalTodaysComspution ,
                totalizer: "",
                flowrate: "",
                receive_date_time: "",
                status: ""
            };

            // Add the total row to the data
            const CombinedFlowRedaingsData = [...data, totalRow];
            return (
                <div ref={this.mainContentRef}>
                    <div className="main-content">
                        <div className="live-sync-icon-and-text-container">
                            <div className="flow-image" >
                                <img src="/images/flow-meter.png" alt="Flow Meter" />
                            </div>
                            <div className="flow-heading" >FLOW READINGS</div>
                            <div className="live-sync-info">
                                <i className={`${REFRESH_ICON} ${this.state.liveSyncIconClass} rotate`}></i>
                                <label className={`${this.state.liveSyncIconClass}`}>Syncing...</label>
                            </div>
                        </div>
                        <div className="live-sync-icon-after-text-container filter-changes" style={{ paddingBottom: this.state.isDropDown === true ? "13%" : "2%" }}>
                            {/* {
                              ( this.state.siteId === "6790c8327610391f17e2b606" || this.state.siteId === "67de78e71d61d4bbb130b7d5" ) && (
                                    <div className="zone-select-container">
                                        <Select
                                            className="site-select-dropdown"
                                            value={this.state.selectTotalizerUnit}
                                            placeholder="Select Totalizer Unit"
                                            options={this.state.selectTotalizerUnitOptions}
                                            onChange={this.selectTotalizerUnitChangeHandler}
                                            onMenuOpen={this.handleMenuOpen}
                                            onMenuClose={this.handleMenuClose}
                                            menuPlacement="bottom"
                                        />
                                    </div>
                                )} */}
                                    <div className="zone-select-container">
                                        <Select
                                            className="site-select-dropdown"
                                            value={this.state.selectTotalizerUnit}
                                            placeholder="Select Totalizer Unit"
                                            options={this.state.selectTotalizerUnitOptions}
                                            onChange={this.selectTotalizerUnitChangeHandler}
                                            onMenuOpen={this.handleMenuOpen}
                                            onMenuClose={this.handleMenuClose}
                                            menuPlacement="bottom"
                                        />
                                    </div>
                              
                            <div className="zone-select-container">
                                <Select
                                    className="site-select-dropdown"
                                    value={this.state.zone}
                                    placeholder="Select Zone"
                                    options={this.zonesList(zoneAndSubzoneList)}
                                    onChange={this.zoneChangeHandler}
                                    onMenuOpen={this.handleMenuOpen}
                                    onMenuClose={this.handleMenuClose}
                                    menuPlacement="bottom"
                                />
                            </div>
                            <div className="subzone-select-container">
                                <Select
                                    className="site-select-dropdown"
                                    value={this.state.subZone}
                                    placeholder="Select Subzone"
                                    options={this.subZonesList(zoneAndSubzoneList, this.state.zone)}
                                    onChange={this.subZoneChangeHandler}
                                    isDisabled={this.state.subzonedisabaled}
                                    onMenuOpen={this.handleMenuOpen}
                                    onMenuClose={this.handleMenuClose}
                                />
                            </div>
                            <button className="brand-button report-button" disabled={this.state.cancelButtondisabaled} onClick={this.cancelHandler}>CLEAR</button>
                        </div>
                        <div className="border list-table-div" style={{ marginBottom: 50 }}>
                            {(filteredFlowMeterReadings.length > 0) ? (
                                <MaterialTable
                                    columns={[
                                        // { title: "Sn", field: "sn", cellStyle: { color: "#0070C0", fontSize: 14 } },
                                        { title: "Zone", field: "zone", cellStyle: { color: "#808080", fontSize: 14 }, render: rowData => (
                                            rowData.zone === "Total" ? <span style={{ color: "#0070C0" }}>{rowData.zone}</span> : rowData.zone
                                        )},
                                        { title: "Subzone", field: "subzone", cellStyle: { color: "#808080", fontSize: 14 } },
                                        { title: "Location/Tag", field: "location", cellStyle: { color: "#808080", fontSize: 14 } },
                                        { title: "Serial Number", field: "meter_serial_no", cellStyle: { color: "#000000", fontSize: 14 } },
                                        { title: "Yesterdays Consumption", field: "yesterdays_comspution", cellStyle: { color: "#000000", fontSize: 14 } },
                                        { title: "Todays Consumption", field: "todays_comspution", cellStyle: { color: "#000000", fontSize: 14 } },
                                        { title: "Totalizer", field: "totalizer", cellStyle: { color: "#808080", fontSize: 14 } },
                                        { title: "Flowrate", field: "flowrate", cellStyle: { color: "#808080", fontSize: 14 } },
                                        { title: "Status", field: "status", cellStyle: { color: "#808080", fontSize: 14 } },
                                        { title: "Network Time Stamp", field: "receive_date_time", cellStyle: { color: "#808080", fontSize: 14 } },
                                    ]}
                                    data={CombinedFlowRedaingsData}
                                    onRowClick={(event, rowData) => {
                                        this.showMeterReadingHandler(rowData)
                                    }}
                                    options={{
                                        rowStyle: (rowData, index) => ({
                                            backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                            fontWeight: rowData.sn === "Total" ? "bold" : "normal",
                                        }),
                                        headerStyle: {
                                            color: "#0070C0",
                                            fontWeight: "bold",
                                            fontSize: 15,
                                        },
                                        columnsButton: true,
                                        filtering: true,
                                        search: true,
                                        pageSize: CombinedFlowRedaingsData.length,
                                        pageSizeOptions: [25, 50, 75, 100],
                                        exportButton: true,
                                        exportAllData: true,
                                        exportFileName: "Reading Flow Data"
                                    }}
                                />
                            ) : (
                                <div className="text-center pt-2">
                                    <label>Records not found!</label>
                                </div>
                            )}
                        </div>
                        <div className="report-section" ><i className={`${EXCEL_DWN_ICON} icon-space`}></i><span>READINGS REPORT AND GRAPH GENERATE</span></div>
                        <div>
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">Select Date To Generate Excel And Graph  <span className="text-danger">*</span></label>
                                        <Select
                                            className="site-select-dropdown"
                                            value={this.state.excelGenerateValue}
                                            placeholder="Select Date"
                                            options={this.state.excelAndGraphGenerateOptionList}
                                            onChange={this.excelGenerateChangeHandler}
                                            menuPlacement="bottom"
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.excelGenerateValue}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.excelGenerateValue.value === "daily" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.dailyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                  {
                                    this.state.excelGenerateValue.value === "weekly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.weeklyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                  {
                                    this.state.excelGenerateValue.value === "monthly" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyStartDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.monthlyEndDate}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.excelGenerateValue.value === "custom" && (
                                        <div className="row">
                                            <div className="addUser">
                                                <label className="form-label">From Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleStartDateChange}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="addUser">
                                                <label className="form-label">To Date <span className="text-danger">*</span></label>
                                                <div className="calender-div">
                                                    <span className="icon fa-calendar" />
                                                    <DatePicker className="form-control brand-font p-3"
                                                        selected={this.state.endDate}
                                                        onChange={this.handleEndDateChange}
                                                        timeIntervals={1}
                                                        timeCaption="time"
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={this.state.maxEndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="addButton">
                                    <button id="button" className="brand-button report-button" onClick={()=>this.getFlowReportReadings(filteredFlowMeterReadings)}>Get Report</button>
                                    <button
                                        id="download-excel-button"
                                        className="download-table-xls-button brandxls-button download-button"
                                        onClick={this.exportToXLSX}>Download as XLS
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="report-section" ><i className={`${GRAPH_ICON} icon-space`}></i><span> {`${this.state.excelGenerateValue.value === undefined ? "DAILY" : this.state.excelGenerateValue.value.toUpperCase()  } GRAPH VIEW`}</span></div>
                        <div className="live-sync-icon-after-text-container">
                            {this.state.flowGraphLoading ? (
                                <div className="line-chart-graph-height">
                                    <GraphLoader />
                                </div>
                            ) : (
                                <div className="line-chart">
                                    <LineChart data={this.state.flowGraphReadings} unit={this.state.selectTotalizerUnit.value} />
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="report-section"><i className={`${MAP_MARKER_ICON} icon-space`}></i><span>METERS MAP VIEW</span></div>
                            <div>
                                <WrappedMap meterMapReadings={this.state.flowAllMeterData} />
                            </div>
                        </div>
                        <div className="sticky-icon" onClick={this.scrollToTop}>↑</div>
                    </div>
                </div>
            );
        }
    }
}

export default FlowMeterReadingPage;